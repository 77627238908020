/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.NotFound {
  @include tablet {
    padding-block-start: 0;
    margin-block-start: 0;
  }

  @include mobile {
    margin-inline: 16px;
  }

  &-Wrapper {
    display: grid;
    //grid-template-columns: 1.5fr 1fr;
    grid-template-columns: calc(61% - 24px) 39%;
    grid-column-gap: 24px;
    grid-template-rows: min-content min-content;
    padding-block-start: 24px;

    @include wide-desktop {
      //grid-template-columns: 1fr 483px;
      grid-template-columns: calc(100% - 507px) 483px;
    }

    @include desktop {
      grid-column-gap: 26px;
      grid-template-areas: '. actions';
      padding-block-end: 72px;

      .ProductActions {
        grid-area: actions;
      }
    }

    @include mobile {
      grid-template-columns: 100%;
      padding: 0 0 20px !important;
    }

    > .RelatedProducts {
      display: none;

      @include desktop {
        display: block;
      }
    }
  }

  &-Title,
  &-sku,
  &-Image {
    filter: blur(1.2px);
  }

  &-Image {
    max-height: calc(100vh/2);

    @include desktop {
      max-height: calc(761px - 70px - 152px);
    }

    @include mobile {
      margin-bottom: 16px;
    }

    .Image {
      @include desktop {
        height: 100%;
        padding-bottom: 0;
      }

      img {
        @include desktop {
          position: static;
        }
      }
    }
  }

  &-Title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 15px;

    .amgiftcard & {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.2px;
      margin-bottom: 30px;
      text-align: center;

      @include desktop {
        font-size: 20px;
        line-height: 28px;
        text-align: left;
        margin-bottom: 3px;
      }
    }
  }

  &-sku {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.0008em;
    margin-bottom: 13px;
  }

  &-note {
    font-weight: 300;
    padding: 32px 0 0;
    font-size: 24px;
    border-top: 1px solid #e4e4e4;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  &-Categories {
    border-top: 1px solid $grey;
    padding: 16px 16px 0;

    @include mobile {
      margin: 0 -16px 12px;
      padding-top: 20px;
    }

    &Title {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
      margin-bottom: 13px;
    }

    &List {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    &Item {
      background: $greyF0F0F0;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.02em;
      padding: 4px 8px;

      &:hover {
        background: $black;
        color: $white;
        text-decoration: none !important;
      }
    }
  }
}