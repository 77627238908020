/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-height: calc(var(--footer-content-height) + var(--footer-copyright-height));
    --footer-content-height: 220px;
    --footer-copyright-height: 50px;
    --footer-nav-height: 48px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));
}

.Footer {
    $column-count: 7;

    &.Checkout {
        background: $white;
        display: flex;
        flex-direction: column;
    }

    &-CopyrightContentWrapper {
        background-color: var(--secondary-base-color);
    }

    &-CopyrightContent {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: var(--footer-copyright-height);

        @include desktop {
            padding-block-end: 48px;
        }
    }

    &-Copyright {
        font-size: 12px;
        text-align: center;
        color: var(--secondary-dark-color);
        padding-inline: 16px;

        a {
            color: inherit;
            font-weight: 400;
            font-size: 12px;

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-bg);
        background-image: var(--footer-fbkg);
        background-repeat: no-repeat;

        @include mobile {
            background-color: $black;
        }

        @include desktop {
            background-size: 100% auto;
        }
    }

    &-Columns {
        display: flex;
        height: 100%;
        justify-content: flex-start;

        @include mobile {
            flex-direction: column;
            align-items: center;
            padding: 0 16px 36px;
        }

        @include desktop {
            padding-inline: 10px;
            padding-block-start: 52px;
            padding-block-end: 52px;
            border-top: 1px solid var(--footer-fc);
        }

        @include ultra-narrow-desktop {
            padding-inline: 32px;
        }
    }

    &-TopMobile {
        padding-inline: 14px;
        padding-block-start: 40px;
        padding-block-end: 28px;
    }

    &-Top {
        @include mobile {
            background: #F9F9F9;
        }

        @include desktop {
            display: flex;
            height: 100%;
            justify-content: space-between;
            padding-inline: 10px;
            padding-block-start: 31px;
            padding-block-end: 31px;
        }

        @include ultra-narrow-desktop {
            padding-inline: 32px;
        }

        .slick-slider,
        .swiper-container,
        .swiper {
            @include desktop {
                width: 100%;
            }
        }

        .slick-slider {
            @include desktop {
                display: flex;
                justify-content: space-between;
            }
        }

        .swiper-wrapper {
            @include desktop {
                transform: none;
                justify-content: space-between;
            }
        }
    }

    &-TopColumn {
        display: flex;
        align-items: center;

        @include mobile {
            width: 100%;
            min-height: 48px;
            display: flex !important;
            justify-content: center;
        }

        @include desktop {
            &:not(:last-of-type) {
                padding-inline-end: 20px;
            }
        }

        &.swiper-slide {
            @include desktop {
                flex-shrink: 1;
                width: auto;
            }
        }

        svg,
        img {
            margin-right: 16px;

            .Footer-inverse & {
                @include desktop {
                    filter: invert(100%);
                }
            }
        }

        h5 {
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;

            @include desktop {
                color: var(--footer-fc);
                font-size: 14px;
            }

            @include ultra-narrow-desktop {
                font-size: 12px;
            }
        }
    }

    &-Middle {
        display: flex;
        height: 100%;
        justify-content: space-between;

        @include mobile {
            margin-block-end: 50px;
            flex-direction: column;
        }

        @include desktop {
            padding-inline: 10px;
            padding-block-start: 25px;
            padding-block-end: 25px;
        }

        @include ultra-narrow-desktop {
            padding-inline: 32px;
        }
    }

    &-Bottom {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;

        @include mobile {
            flex-direction: column-reverse;
        }

        @include desktop {
            border-top: 1px solid var(--footer-fc);
            padding-inline: 10px;
            padding-block-start: 19px;
            padding-block-end: 19px;
        }

        @include ultra-narrow-desktop {
            padding-inline: 32px;
        }

        .Checkout & {
            border-top: 0 none;
        }
    }

    &-BottomColumn,
    &-MiddleColumn {
        display: flex;
        align-items: center;
    }

    &-MiddleColumn {
        @include mobile {
            justify-content: center;
            width: 100%;
            padding-block-start: 34px;
            padding-block-end: 34px;
            border-top: 1px solid $white;
            border-bottom: 1px solid $white;
            margin-bottom: 40px;
        }

        .Checkout & {
            flex-direction: column;
            gap: 14px;
            padding: 38px 52px;
            margin-bottom: 0;

            &.desktop {
                order: 1;
                padding: 38px 35px;

                @include desktop {
                    padding: 40px 0;
                }

                @include mobile {
                    display: block !important;
                }
            }
        }
    }

    &-MiddleColumnLeft {
        width: 50%;

        .Checkout & {
            @include mobile {
                width: 100%;
            }

            @include ultra-narrow-desktop {
                width: 100%;
            }
        }
    }

    &-BottomColumn {
        flex-direction: column;
        align-items: center;
        line-height: 24px;

        @include mobile {
            font-size: 12px;
        }

        @include desktop {
            align-items: flex-start;
        }

        .Checkout & {
            font-size: 14px;

            @include desktop {
                flex-direction: row;
                gap: 12px;
            }

            @include ultra-narrow-desktop {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &-BottomColumnRight {
        .Checkout & {
            display: none;
        }
    }

    &-BottomContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include desktop {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .Checkout & {
            @include ultra-narrow-desktop {
                flex-direction: column;
            }
        }

        .ContentWrapper {
            padding: 0;
            margin: 0;
        }
    }

    &-Links {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 14px;

        @include desktop {
            flex-direction: row;
            gap: 84px;
        }
    }

    &-Copyrights {
        color: var(--footer-fc);

        @include mobile {
            order: 1;
            margin-bottom: 60px;
            color: $white;
            font-weight: 500;
        }

        @include ultra-narrow-desktop {
            font-size: 12px;
        }

        .Checkout & {
            color: $black;
            order: 0;
            margin-bottom: 12px;
            font-weight: 400;

            @include desktop {
                margin-bottom: 0;
            }
        }
    }

    &-BottomLinks {
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
        }

        .Checkout & {
            flex-direction: row;
            gap: 26px;
            margin-bottom: 0;
            display: flex;
        }

        a {
            color: var(--footer-fc);
            @include mobile {
                margin-bottom: 5px;
                color: $white;
                font-size: 12px;
            }

            @include ultra-narrow-desktop {
                font-size: 12px;
            }

            &:not(:last-child) {
                @include desktop {
                    padding-right: 26px;
                    position: relative;

                    &:after {
                        content: "";
                        background: $black;
                        width: 4px;
                        height: 4px;
                        position: absolute;
                        top: 6px;
                        right: 11px;
                        display: block;
                    }
                }
            }

            .Checkout & {
                color: $black;
                font-size: 14px;
                text-align: center;
                padding: 0;

                &:after {
                    display: none;
                }

                + a {
                    &:before {
                        content: "";
                        width: 4px;
                        height: 4px;
                        background: $black;
                        border-radius: 50%;
                        display: block;
                        position: absolute;
                        left: -15px;
                        top: 10px;
                    }
                }
            }
        }
    }

    &-Payments {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;

        svg,
        img {
            width: auto !important;
        }

        .Checkout & {
            flex-wrap: wrap;
            justify-content: center;
            gap: 14px;

            svg,
            img {
                width: auto !important;
                padding: 0;
            }
        }
    }

    &-Link {
        color: var(--footer-fc);
        @include desktop {
            margin-left: 35px;
        }

        @include ultra-narrow-desktop {
            font-size: 12px;
            margin-left: 24px;
            white-space: nowrap;
        }

        &:empty {
            display: none;
        }

        &:hover {
            color: var(--footer-fc);
        }

        a {
            @include mobile {
                color: $white;
            }
        }

        .StoreSwitcher {
            margin: 0;

            &-Title {
                width: 0;
                height: 0;
                overflow: hidden;
            }

            &-StoreList {
                background: none;
                border: 0 none;
                display: flex;
                position: static;
            }
        }

        .StoreItems-Item {
            background: none !important;
            color: var(--footer-fc);
            font-size: 14px;
            padding: 0;

            @include mobile {
                color: $white;
            }

            &:not(:last-child) {
                border-right: 1px solid var(--footer-fc);
                padding-right: 8px;
                margin-right: 8px;

                @include mobile {
                    border-color: $white;
                }
            }

            &.active {
                font-weight: 700;

                &:after {
                    display: none;
                }
            }
        }

        .Checkout & {
            display: flex;
            gap: 12px;
            margin: 0;
        }
    }

    &-LinkStore {
        img,
        svg {
            vertical-align: top;
            margin-right: 12px;
        }
    }

    &-LinkBorderRight {
        border-right: 1px solid $black;
        padding-right: 8px;
        margin-right: 8px;

        @include mobile {
            border-color: $white;
        }
    }

    &-Columns-bkg {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: 25px;
        left: 50%;

        @include desktop {
            display: block;
        }

        > img {
            position: absolute;
        }

        img {
            .Footer-inverse & {
                filter: invert(85%);
            }
        }
    }

    &-Column {
        z-index: 1;

        @include mobile {
            width: 100%;
        }

        &:not(:last-of-type) {

            @include desktop {
                padding-inline-end: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
                width: calc(100% / #{ $column-count - 1 });
            }

            @include ultra-narrow-desktop {
                padding-inline-end: 10px;
            }
        }

        .ExpandableContent {
            border-top: 0 none;
        }

        .ExpandableContent-Heading,
        .ExpandableContent-SubHeading {
            color: $white;
        }

        .ExpandableContent-Content {
            display: flex;
            flex-direction: column;
        }

        .MinusIcon,
        .AddIcon {
            fill: $white;

            &:before,
            &:after {
                border-color: $white;
            }
        }

        &_isNewsletter {
            @include mobile {
                margin-top: 48px;
            }
        }
    }

    &-ColumnTitle {
        color: var(--footer-fc);
        margin: 0;
        margin-block-end: 15px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;

        @include mobile {
            color: $white;
            display: none !important;
        }

        @include desktop {
            font-size: 18px;
            line-height: 24px;
            margin-block-end: 20px;
        }

        @include ultra-narrow-desktop {
            font-size: 12px;
        }

        .Footer-Column_isNewsletter & {
            display: none;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }
    }

    &-ColumnItem {
        color: var(--footer-fc);
        margin-block-end: 12px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;

        @include mobile {
            color: $white;
        }

        @include ultra-narrow-desktop {
            font-size: 12px;
        }

        &:last-of-type {
            @include desktop {
                margin: 0;
            }
        }

        &:hover {
            color: var(--footer-fc);
            text-decoration: underline;
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                width: 16px!important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                margin-left: auto;
                width: 358px;
            }

            @include ultra-narrow-desktop {
                width: 32%;
                max-width: 250px;
            }
        }
    }

    &-Logo {
        margin-bottom: 38px;

        @include desktop {
            margin-bottom: 15px;
        }

        svg {
            width: 100%;
        }

        .Checkout & {
            margin-bottom: 18px;

            &.mobile {
                display: none;
            }
        }
    }

    &-LinkArrow {
        @include button;

        border: 1px solid var(--footer-fc);
        background: $white;
        color: $black;
        display: flex;
        height: 46px !important;
        font-weight: 400;
        line-height: 22px;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 12px;
        padding: 0 62px 0 12px !important;

        @include mobile {
            border-color: $white;
            background: $black;
            color: $white;
            font-weight: 700;
        }

        &:before {
            @include desktop {
                content: "";
                width: 16px;
                border-bottom: 1px solid $black;
                position: absolute;
                right: 23px;
                top: 21px;
            }
        }

        &:after {
            content: "";
            width: 5px;
            height: 5px;
            border-style: solid;
            border-width: 1px;
            border-color: transparent $black $black transparent;
            transform: rotate(-45deg);
            position: absolute;
            right: 23px;
            top: 18px;

            @include mobile {
                border-color: transparent $white $white transparent;
            }
        }

        &:hover {
            @include desktop {
                background: $black;
                color: $white;

                &:before {
                    border-color: $white;
                }

                &:after {
                    border-color: transparent $white $white transparent;
                }
            }
        }

        &:not([disabled]):hover {
            @include desktop {
                background: $black;
                color: $white;
            }
        }
    }

    &-LinkArrow-Lightmobile {
        @include mobile {
            background: $white;
            color: $black;

            &:after {
                border-color: transparent $black $black transparent;
            }
        }
    }

    &-LinkArrow-icon {
        padding-left: 72px !important;
        position: relative;
        min-height: 40px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &:after {
            top: 50%;
            margin-top: -4px;
        }

        img,
        svg {
            position: absolute;
            left: 28px;
            top: 50%;
            transform: translateY(-50%);
        }

        strong {
            font-size: 14px;
        }
    }

    &-Link-icon {
        padding-right: 10px !important;

        &:after {
            display: none !important;
        }
    }

    &-LinkArrow-Call {
        svg {
            width: 18px;
            height: 18px;
            display: inline-block !important;
            vertical-align: middle;
            margin-right: 11px;
        }

        &:hover {
            path {
                fill: $white;
            }
        }
    }

    &-SocialLinks {
        display: flex;
        align-items: center;

        @include mobile {
            margin-bottom: 48px;
        }
    }

    &-SocialLink {
        color: var(--footer-fc);
        display: flex;

        &:not(:last-child) {
            margin-right: 25px;
        }

        img {
            @include mobile {
                filter: invert(100%);
            }

            .Footer-inverse & {
                filter: invert(100%);
            }
        }
    }

    &-inverse {
        img {
            &.image-normal {
                display: none;
            }
        }
    }
}
