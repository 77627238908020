.sisley {
  padding: 3.125rem 14px 0;

  @include desktop {
    padding: 4.6875rem 0 0;
  }

  section {
    max-width: 120rem;
    margin: 0 auto;
  }

  .page-title {
    font-family: $font-blackerDisplay;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 2.625rem;
    margin-bottom: 4.375rem;
    text-align: center;

    @include desktop {
      font-size: 5.7rem;
      line-height: 5.925rem;
    }
  }

  img {
    height: auto !important;

    @include desktop {
      width: auto !important;
      max-width: 100%;
      vertical-align: top;
    }
  }

  .image {
    + .content {
      margin-top: 50px;
    }
  }

  .content {
    @include desktop {
      max-width: 874px;
      padding-right: 40px;
      padding-left: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .columns {
    @include desktop {
      max-width: 128rem;
      padding-left: 4rem;
      padding-right: 4rem;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 20px;
    }
  }

  .columns,
  .content {
    h2 {
      font-weight: 400;
      font-family: $font-blackerDisplay;
      font-size: 2.6rem;
      line-height: 3.4rem;
      margin: 0 0 2.4rem;

      @include desktop {
        font-size: 2.8rem;
        line-height: 3.8rem;
      }
    }

    h3 {
      margin: 0 0 2.1875rem;
    }

    p {
      font-size: 1.3rem;
      line-height: 2.3125rem;
      margin: 0 0 2.4rem;

      @include desktop {
        font-size: 1.7rem;
      }
    }
  }

  .section1 {
    @include desktop {
      padding-bottom: 4.6875rem;
    }

    .content {
      @include mobile {
        max-width: 83%;
        margin-left: auto;
        margin-right: auto;
      }

      h2 {
        font-family: $font-muli;
        font-size: 1.7rem;
        line-height: 2.4rem;

        @include desktop {
          font-size: 2.7rem;
          line-height: 3.4rem;
        }
      }

      h2,
      p {
        text-align: center;
      }
    }
  }

  .section2 {
    background: $color-grey-F6F4F1;
    padding: 4.375rem 1.125rem 0;
    position: relative;

    @include desktop {
      padding: 8.375rem 0 3.375rem;
    }

    &:before {
      @include desktop {
        content: "";
        background: $color-grey-F6F4F1;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        display: block;
        width: 10000px;
        transform: translateX(-5000px);
      }
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 1px;
    }

    .content {
      h2 {
        margin-bottom: 4.125rem;

        @include desktop {
          text-align: center;
        }
      }

      img {
        margin-bottom: 5.125rem;
      }
    }
  }

  .section3 {
    margin-top: 5.125rem;

    @include desktop {
      max-width: 100%;
    }

    .content {
      @include desktop {
        max-width: 128rem;
      }

      h2 {
        margin-bottom: 5.125rem;
        padding: 0 2.8125rem;
        text-align: left;

        @include desktop {
          padding-left: 0;
          padding-right: 50%;
        }
      }
    }

    p {
      line-height: 2.3125rem;
      padding: 0 2.8125rem;
      margin-bottom: 5.125rem;
      text-align: left;

      @include desktop {
        padding: 0;
      }
    }

    .image {
      @include tablet {
        height: 0;
        padding-bottom: 50%;
        position: relative;
      }

      @include narrow-desktop {
        height: 0;
        padding-bottom: 50%;
        position: relative;
      }

      @include wide-desktop {
        min-height: 972px;
      }

      img {
        @include tablet {
          position: absolute;
          width: 100% !important;
          height: 100%;
          object-position: 50% 50%;
          object-fit: cover;
        }

        @include narrow-desktop {
          position: absolute;
          width: 100% !important;
          height: 100%;
          object-position: 50% 50%;
          object-fit: cover;
        }

        @include wide-desktop {
          left: 50%;
          max-width: none;
          position: absolute;
          transform: translateX(-50%);
        }
      }
    }
  }

  .section4 {
    @include desktop {
      max-width: 100%;
    }

    .content {
      padding: 3.6875rem 2.8125rem 0;

      @include desktop {
        max-width: 128rem;
        padding-right: 4rem;
        padding-left: 4rem;
      }

      h2 {
        margin-bottom: 8.125rem;
      }
    }

    .columns {
      padding: 0 2.8125rem;

      @include desktop {
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }

    p {
      line-height: 2.3125rem;
      margin-bottom: 5.125rem;
    }
  }

  .section5 {
    @include desktop {
      padding-bottom: 4.6875rem;
    }

    .content {
      padding: 3.125rem 2.8125rem 0;

      @include desktop {
        padding-right: 4rem;
        padding-left: 4rem;
      }

      h2 {
        margin-bottom: 3.125rem;
        text-align: left;

        @include desktop {
          text-align: center;
        }
      }

      img {
        margin-bottom: 3.625rem;
      }
    }
  }

  .section6 {
    @include desktop {
      max-width: 100%;
      padding-bottom: 4.6875rem;
    }

    .columns,
    .content {
      padding: 0 2.8125rem;

      @include desktop {
        max-width: 128rem;
        padding-right: 4rem;
        padding-left: 4rem;
      }
    }

    h2 {
      margin-bottom: 5rem;
      text-align: left;
    }

    p {
      line-height: 2.3125rem;
      margin: 0 0 3.125rem;
    }

    .image {
      @include tablet {
        height: 0;
        padding-bottom: 50%;
        position: relative;
      }

      @include narrow-desktop {
        height: 0;
        padding-bottom: 50%;
        position: relative;
      }

      @include wide-desktop {
        min-height: 972px;
      }

      img {
        @include tablet {
          position: absolute;
          width: 100% !important;
          height: 100%;
          object-position: 50% 50%;
          object-fit: cover;
        }

        @include narrow-desktop {
          position: absolute;
          width: 100% !important;
          height: 100%;
          object-position: 50% 50%;
          object-fit: cover;
        }

        @include wide-desktop {
          left: 50%;
          max-width: none;
          position: absolute;
          transform: translateX(-50%);
        }
      }
    }
  }

  .section7 {
    @include desktop {
      padding-bottom: 4.6875rem;
    }

    .content {
      padding: 3.125rem 2.8125rem 0;

      @include desktop {
        padding-left: 4rem;
        padding-right: 4rem;
      }

      h2 {
        margin-bottom: 3.125rem;
        text-align: left;

        @include desktop {
          text-align: center;
        }
      }

      img {
        margin-bottom: 3.125rem;
      }
    }
  }

  .section8 {
    @include desktop {
      max-width: 100%;
      padding-bottom: 4.6875rem;
    }

    .columns {
      padding: 3.125rem 2.8125rem 0;

      @include desktop {
        grid-template-columns: repeat(3, 1fr);
        padding-right: 4rem;
        padding-left: 4rem;
      }

      h2 {
        margin-bottom: 3.125rem;
        text-align: left;
      }

      img {
        margin-bottom: 1.625rem;
        height: auto !important;
      }

      p {
        line-height: 2.3125rem;
        margin: 0 0 5.125rem;
      }
    }
  }

  .section9 {
    @include desktop {
      padding-bottom: 4.6875rem;
    }

    .columns {
      padding: 3.125rem 2.8125rem 0;

      @include desktop {
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: 40.566667% auto;
      }

      h2 {
        margin-bottom: 3.125rem;
        text-align: left;

        @include desktop {
          max-width: 578px;
          padding-right: 4rem;
          padding-left: 4rem;
          margin-right: auto;
          margin-left: auto;
        }
      }

      p {
        @include desktop {
          max-width: 578px;
          padding-right: 4rem;
          padding-left: 4rem;
          margin-right: auto;
          margin-left: auto;
        }
      }

      img {
        margin-bottom: 3.125rem;
      }
    }
  }

  .section10 {
    background: $color-grey-F0EBE4;
    padding: 4.375rem 1.125rem 0;

    @include desktop {
      padding: 8.375rem 0 2.375rem;
    }

    &:before {
      @include desktop {
        content: "";
        background: $color-grey-F0EBE4;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        display: block;
        width: 10000px;
        transform: translateX(-5000px);
      }
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 1px;
    }

    .content {
      h2 {
        margin-bottom: 4.125rem;

        @include desktop {
          text-align: center;
        }
      }

      img {
        margin-bottom: 5.125rem;
      }
    }
  }

  .section11 {
    background: $color-grey-f6f6f6;
    padding: 4.375rem 1.125rem 2.145rem;

    @include desktop {
      padding: 8.375rem 0 2.375rem;
      max-width: 100%;
    }

    &:before {
      @include desktop {
        content: "";
        background: $color-grey-f6f6f6;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        display: block;
        width: 10000px;
        transform: translateX(-5000px);
      }
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 1px;
    }

    .content {
      @include desktop {
        max-width: 128rem;
      }

      h3 {
        margin-bottom: 3.125rem;
        text-align: center;
      }
    }

    .visual_image {
      position: relative;

      .visual_content {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-size: 2rem;
        color: $white;
        z-index: 1;

        @include desktop {
          font-size: 3.5rem;
        }

        h4 {
          color: $white;
          font-size: 2.875rem;
          font-weight: 700;
          line-height: 48px;

          @include desktop {
            font-size: 5.75rem;
            line-height: normal;
          }
        }

        span {
          padding: 0;
          line-height: 1;
        }
      }

      &:after {
        content: "";
        height: 100%;
        left: 0px;
        position: absolute;
        top: 0px;
        width: 100%;
        background: transparent;
        transition: background 0.5s ease-in-out 0s;
      }

      &:hover {
        &:after {
          background: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .columns {
      font-size: 1.475rem;
      font-weight: 300;

      @include desktop {
        grid-template-columns: repeat(2, 1fr);
      }

      a {
        font-size: 1.475rem;
        line-height: 1.4;

        @include desktop {
          display: inline-block;
        }

        &:hover {
          text-decoration: none;
        }
      }

      span {
        padding-top: 0.8125rem;
        display: block;

        @include desktop {
          text-align: left;
          padding-top: 1.8125rem;
        }
      }
    }

    .column {
      margin-bottom: 3.1875rem;

      @include desktop {
        text-align: center;
      }
    }
  }
}