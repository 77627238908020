/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.CategoryFilterOverlay {

&-SuggestedTerms {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
        width: 100%;

    @include mobile {
        padding: 0 10px;
    }

    @include desktop {
        margin-bottom: 20px;
            flex: 0 0 100%;
    }

    &_noResult {
        justify-content: center;
    }
}

&-SuggestedTerm {
    margin: 0 6px;
    }
}

.CategoryPage {
    &-LeftCol{
         &_noResult {
            margin-top: 0 !important;
         }
    }
    &_isSearchPage {
        .CategoryPagination {
            @include mobile {
                display: flex;
            }
        }
    }
}