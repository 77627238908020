/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;

    @include mobile {
        min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
        margin-block-end: 0;
        margin-block-start: 0;
    }

    h1 {
        font-size: 144px;
        font-weight: 400;
        color: var(--primary-base-color);
        line-height: 1;

        @include mobile {
            font-size: 168px;
        }
    }

    &-Title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        text-align: center;
        margin: 0 0 18px;

        @include desktop {
            font-weight: 600;
        }
    }

    &-Subtitle {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        text-align: center;
        max-width: 873px;
        margin: 0 auto 39px;
    }

    &-Button {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }
    }

    &-Top {
        margin-top: 30px;

        @include desktop {
            margin-top: 54px;
        }
    }

    &-Bottom_title_container {
        justify-content: center;
        margin-bottom: 18px;
    }

    &-Middle {
        margin-bottom: 30px;

        @include desktop {
            margin-bottom: 65px;
        }
    }

    &-LinksWrapper {
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
        padding: 15px 0;
        overflow-x: auto;

        @include desktop {
            padding: 42px 0;
        }

        @include mobile {
            &::-webkit-scrollbar-track {
                border-radius: 5px;
                background-color: $color-grey-E5;
            }

            &::-webkit-scrollbar {
                height: 2px;
                background-color: $color-grey-E5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: $black;
            }
        }
    }

    &-Links {
        display: flex;
        align-items: center;

        @include desktop {
            justify-content: center;
        }
    }

    &-Link {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.04em;
        padding: 0 11px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}
