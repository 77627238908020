/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --posts-details-title-size: 28px;
}

.PostsDetails {
    &-Wrapper {
        padding-bottom: 30px;

        @include mobile {
            padding-top: 5px;
        }
    }

    &-MainImage {
        @include mobile {
            margin: 0 -14px;
        }

        .Image_ratio_square {
            height: auto;
            padding-bottom: 0;
            vertical-align: top;

            img {
                position: static;
                vertical-align: top;
            }
        }

        .Image {
            @include mobile {
                position: static;
            }
        }

        .Image-Content {
            position: relative;
        }
    }

    &-Sidebar {
        flex-basis: 30%;
        margin-top: 2rem;
        @include mobile {
            display: none;
        }
    }

    &-Title {
        font-family: $font-BlackerProDisplay;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.004em;
        margin: 0 0 8px;

        @include ultra-wide-desktop {
            font-size: var(--posts-details-title-size);
            line-height: 40px;
            margin-bottom: 22px;
        }
    }

    &-Description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;

        h2 {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin: 0;

            @include desktop {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.2px;
                margin: 0 0 8px;
            }
        }

        h3 {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin: 0;

            @include desktop {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.2px;
            }
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin: 0 0 16px;

            @include desktop {
                margin-bottom: 20px;
            }

            &:last-child {
                margin: 0;
            }

            a {
                text-decoration: underline;
            }
        }

        + .PostsDetails-Description {
            margin-top: 16px;

            @include desktop {
                margin-top: 20px;
            }
        }
    }

    &-Content {
        .PostsDetails-MainImage + & {
            background: $white;
            margin: -64px -8px 0;
            padding: 10px;

            @media (min-width: 571px) {
                margin: -14% 8.4% 0;
                padding: 38px 40px;
            }
        }
    }

    &-Date,
    &-Author {
        display: block;
        text-align: right;
    }

    &-ContentPlaceholder {
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-DatePlaceholder {
        &::after {
            content: 'aaaa/aa/aa';
        }
    }

    &-Iframe {
        margin: 0 auto 1.2rem;
        padding: 56.25% 0 0 0;
        position: relative;

        iframe {
            height: 100% !important;
            left: 0;
            position: absolute;
            top: 0;
            width: 100% !important;
        }
    }

    &-Columns {
        display: flex;
        flex-direction: column-reverse;
        gap: 25px;

        @include desktop {
            flex-direction: row;
            gap: 100px;
            align-items: center;
        }

        @include ultra-narrow-desktop {
            flex-direction: column-reverse;
            gap: 25px;
        }

        .PostsDetails-Column {
            flex-basis: 50%;
        }
    }

    &-Columns_type1 {
        display: flex;
        flex-direction: column;
        gap: 11px;

        @include desktop {
            flex-direction: row;
            align-items: center;
        }

        @include tablet {
            flex-direction: column;
            gap: 25px;
        }

        .PostsDetails-Column {
            @include desktop {
                padding: 6%;

                &:first-child {
                    flex-basis: 50%;
                    flex-shrink: 0;
                    max-width: 622px;

                    &:before {
                        content: "";
                        background: $greyF8F8F8;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        right: 163px;
                        top: 0;
                    }
                }
            }

            &:not(:first-child) {
                @include tablet {
                    padding: 0;
                }
            }

            img {
                vertical-align: top;
            }

            .PostsDetails-Title {

            }
        }
    }

    &-Columns_type2 {
        display: flex;
        flex-direction: column;
        gap: 17px;
        margin-top: 9px;

        @include desktop {
            flex-direction: row;
            align-items: center;
            gap: 100px;
        }

        @include tablet {
            margin-top: 88px;
        }

        .PostsDetails-Column {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;

            @include desktop {
                &:last-child {
                    flex-basis: 58%;
                    flex-shrink: 0;
                }
            }

            h2 {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.003em;
                margin: 0 0 8px;
                text-align: center;

                @include desktop {
                    margin-bottom: 20px;
                    text-align: left;
                }
            }
        }
    }
}
