/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../../src/style/abstract/variables';
@import '../../../../../../../../src/style/abstract/media';
@import '../../../../../../../../src/style/abstract/button';
@import '../../../../../../../../src/style/abstract/loader';
@import '../../../../../../../../src/style/abstract/icons';
@import '../../../../../../../../src/style/abstract/parts';

:root {
  --blog-post-card-title-weight: 600;
  --blog-post-card-title-size: 18px;
  --blog-post-card-description-height: 100px;
  --blog-post-card-border: 1px solid lightgray;
}

.Events {
  &BlogPostCard {
    &-ImageWrapper {
      display: block;
    }

    &-Image {
      &.Image {
        @include mobile {
          padding-bottom: 0;
          height: auto;
          vertical-align: top;

          img {
            position: static;
            vertical-align: top;
          }
        }
      }
    }

    &-Title {
      font-weight: 500;
      font-size: 12px;
      line-height: 26px;
      letter-spacing: -0.2px;
      padding: 10px 0 6px;

      a {
        font-size: 18px;
      }
    }

    &-DescriptionWrapper {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
      margin-bottom: 6px;

      p {
        margin-bottom: 0;
      }
    }

    &-Date {
      color: $color-grey-A1;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
    }
  }

  &_LatestPost {
    margin-bottom: 16px;

    @include desktop {
      margin-bottom: 74px;
    }

    .EventsBlogPostCard {
      padding: 0;
      margin: 0;
      border: 0 none;

      &-ContentWrapper {
        @include ultra-narrow-desktop {
          text-align: right;
        }
      }

      &-ImageWrapper {
        margin: 0 -14px;

        @include desktop {
          margin: 0;
        }

        @include ultra-narrow-desktop {
          text-align: right;
          position: static;
        }

        .Image {
          @include desktop {
            padding-bottom: 0;
            height: auto;
            vertical-align: top;

            img {
              position: static;
              vertical-align: top;
              width: auto !important;
              max-width: 100%;
            }
          }

          @include ultra-narrow-desktop {
            position: static;

            img {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              object-fit: cover;
              font-family: 'object-fit: cover;';
              width: 0;
              min-width: 100%;
              min-height: 100%;
            }
          }
        }
      }

      &-Details {
        background: $white;
        width: 100%;
        padding: 36px 36px 43px;
        margin-top: -30px;

        @include desktop {
          bottom: 9.5%;
          top: 7.9%;
          left: auto !important;
          right: 0;
          padding: 15px 63px;
          max-width: 607px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transform: none;
          position: absolute;
          margin-top: 0;
        }

        @include narrow-desktop {
          top: 25px;
          bottom: 25px;
        }

        @include ultra-narrow-desktop {
          position: relative;
          right: 0;
          top: auto;
          bottom: auto;
          margin-top: 36px;
          margin-left: 114px;
          margin-bottom: 36px;
          padding: 15px 26px 15px 40px;
          display: inline-block;
          vertical-align: top;
          width: auto;
          max-width: 100%;
        }
      }

      &-Title {
        font-family: $font-BlackerProDisplay;
        font-weight: 700;
        font-size: 44px;
        line-height: 48px;
        letter-spacing: -0.004em;
        padding: 0;
        margin-bottom: 31px;

        @include desktop {
          font-weight: 500;
        }

        a {
          font-size: 44px;

          &:hover {
            text-decoration: none;
          }
        }
      }

      &-DescriptionWrapper {
        color: $grey727272;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        display: block;
        margin-bottom: 39px;

        p {
          color: $grey727272;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.0008em;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .Events_LatestPost_link {
      &.Button {
        width: auto;
        align-self: flex-start;
      }
    }
  }
}