/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: #0a0903;
    --breadcrumbs-color: #888;
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    &.breadcrumbs {
        padding: 15px 0;

        @include desktop {
            padding: 0 0 20px;
        }
    }

    &-List {
        list-style: none;
        padding: 16px 0;
    }

    nav {
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: $color-grey-E5;
        }

        &::-webkit-scrollbar {
            height: 2px;
            background-color: $color-grey-E5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $black;
        }
    }

    ul.Breadcrumbs {
        display: flex;
    }
}
