/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.VideoPopup {
    --popup-min-width: none;
    --popup-content-padding: 0;

    @include mobile {
        --popup-content-padding: 0;
    }

    &-VideoPlayer {
        width: 100vw;
        height: calc(100vh - 40px);
        background: black;

        @include desktop {
            width: calc(100vw - 130px);
            height: 0;
            padding-block-start: 56.3%; // 16x9 proportion
            max-width: calc(670px / .563); // 16*9 proportion
        }
    }

    &-PlayerContent {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;

        .vimeo {
            height: 100%;
            width: 100%;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-YouTube,
    &-YouTubeContainer {
        height: 100%;
        width: 100%;
    }

    .Popup-Header {
        background: $black;
        min-height: 58px;

        @include desktop {
            display: none;
        }
    }

    .Popup-Heading {
        display: none;
    }

    &.Popup {
        .Popup-CloseBtn {
            top: 0;
            right: 0;

            .CloseIcon {
                fill: $white;
            }
        }
    }
}
