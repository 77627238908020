/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.CmsNewsletter {
  &-columns {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;

    @include desktop {
      display: flex;
      align-items: center;
      max-width: 982px;
    }
  }

  &-left {
    @include desktop {
      flex: 1;
    }
  }

  &-right {
    padding: 24px 16px;

    @include desktop {
      flex: 1;
      padding: 48px 32px;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.2px;
    padding: 0 0 16px;
    margin: 0;
    position: relative;

    .CmsNewsletter-columns & {
      @include mobile {
        text-align: left !important;
      }
    }
  }

  &-description {
    font-weight: 400;
    font-size: 14px;
    margin: 0 0 28px;
  }

  .RenderWhenVisible,
  .CmsBlock-Wrapper {
    @include desktop {
      height: 100%;
    }
  }

  .Button {
    background: $black;
    color: $white;
    margin: 0;
    float: right;
    min-width: 208px;
    width: auto;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.04em;
    margin-top: 28px;

    &:hover {
      background: $white;
      color: $black;
      border-radius: 0;
      color: $black;
    }

    &_isLoading {
      opacity: 0.5;
    }
  }

  .FieldForm {
    &-Fieldset {
      &_isLoading {
        opacity: .7;
        pointer-events: none;
      }

      .Field {
        width: 100%;
        margin-block-start: 0;

        input {
          padding: 14px 16px;
          min-height: 48px;

          &::placeholder {
            color: $grey;
            opacity: 1;
          }
        }

        &:not(.Field_hasError) {
          input {
            border-color: $black;
          }
        }
      }

      &:after {
        content: "";
        clear: both;
        display: block;
      }

      input[type='checkbox'] {
        display: none;
      }

      .checkradio {
        display: inline-flex;

        &__box {
          width: 14px;
          height: 14px;
          border: 1px solid rgba(0, 0, 0, 0.25);
          flex-shrink: 0;
          margin-right: 11px;
        }

        &__input:checked + .checkradio__box {
          border-width: 4px;
          border-color: $black;
        }

        &__content {
          font-size: 12px;
          line-height: 16px;

          a {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }

    &-Fields {
      margin-block-end: 28px;

      &:after {
        content: "";
        clear: both;
        display: block;
      }
    }

    .Field-Label {
      font-weight: 600;

      &_isRequired {
        &:after{
          content: "*";
        }
      }
    }
  }

  &.vertical {
    .CmsNewsletter {
      &-title {
        font-size: 24px;
        line-height: 32px;
        position: relative;
        padding: 0;
        margin: 0 0 16px;
        text-align: center;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          border-bottom: 1px solid $black;
        }

        span {
          background: $white;
          padding: 0 16px;
        }
      }

      &-description {
        text-align: center;
        max-width: 980px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .FieldForm {
      max-width: 523px;
      margin: 0 auto;
      padding: 24px 16px;

      @include desktop {
        padding: 48px 32px;
      }

      &:not(:first-child) {
        padding-top: 0;
      }
    }
  }
}