/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --newsletter-subscription-input-background: $black;
    --newsletter-subscription-placeholder-color: $white;
    --newsletter-subscription-button-background: $black;
}

.NewsletterSubscription {
    @include desktop {
        display: flex;
    }

    .RenderWhenVisible {
        @include desktop {
            flex: 1;
        }
    }

    .CmsBlock-Wrapper {
        @include desktop {
            height: 100%;
        }
    }

    .FieldForm {
        padding: 24px 16px;

        @include desktop {
            flex: 1;
            padding: 48px 32px;
        }

        &-Fieldset {
            &_isLoading {
                opacity: .7;
                pointer-events: none;
            }

            .Field {
                width: 100%;
                margin-block-start: 0;

                input {
                    padding: 14px 16px;
                    min-height: 48px;

                    &::placeholder {
                        color: $grey;
                        opacity: 1;
                    }
                }

                &:not(.Field_hasError) {
                    input {
                        border-color: $black;
                    }
                }
            }
            
            &:after {
                content: "";
                clear: both;
                display: block;
            }

            input[type='checkbox'] {
                display: none;
            }

            .checkradio {
                display: inline-flex;

                &__box {
                    width: 14px;
                    height: 14px;
                    border: 1px solid rgba(0, 0, 0, 0.25);
                    flex-shrink: 0;
                    margin-right: 11px;
                }

                &__input:checked + .checkradio__box {
                    border-width: 4px;
                    border-color: $black;
                }

                &__content {
                    font-size: 12px;
                    line-height: 16px;

                    a {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
        }

        &-Fields {
            margin-block-end: 28px;

            &:after {
                content: "";
                clear: both;
                display: block;
            }
        }

        .Field-Label {
            font-weight: 600;

            &_isRequired {
                &:after{
                    content: "*";
                }
            }
        }
    }

    .Form {
        &_isInvalid {
            margin-block-end: 18px;
        }
    }

    &-PopupButton {
        &.Button_isHollow {
            background: $black;
            color: $white;
            height: 46px !important;
            margin-bottom: 12px;
            font-weight: 400;
            padding: 0 12px;

            @include mobile {
                border-color: $white;
                max-width: 200px;
                margin: 0 auto;
            }

            @include desktop {
                justify-content: flex-start;
            }

            .Footer-inverse & {
                background-color: $foux_new;
                border-color: $foux_new;
            }

            &:before {
                @include desktop {
                    content: "";
                    width: 16px;
                    border-bottom: 1px solid $white;
                    position: absolute;
                    right: 23px;
                    top: 21px;
                }
            }

            &:after {
                @include desktop {
                    content: "";
                    width: 5px;
                    height: 5px;
                    border-style: solid;
                    border-width: 1px;
                    border-color: transparent $white $white transparent;
                    transform: rotate(-45deg);
                    position: absolute;
                    right: 23px;
                    top: 18px;
                }
            }

            &:hover {
                &:before {
                    border-color: $black;
                }

                &:after {
                    border-color: transparent $black $black transparent;
                }
            }

            &:not([disabled]):hover {
                padding: 0 12px;
                .Footer-inverse & {
                    background-color: $white;
                    border-color: $white;
                }
            }
        }
    }

    .Button {
        background: $black;
        color: $white;
        margin: 0;
        float: right;
        min-width: 208px;
        width: auto;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.04em;
        margin-top: 28px;

        &:hover {
            background: $white;
            color: $black;
            border-radius: 0;
            color: $black;
        }
    }

    &_left_section {
        overflow: hidden;

        @include desktop {
            height: 100%;
        }

        @include mobile {
            padding-bottom: 100%;
        }

        img {
            vertical-align: top;
            object-fit: cover;
            max-width: 200%;
            width: auto !important;

            @include mobile {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                font-family: 'object-fit: cover;';
                width: 0;
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    &-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        padding: 0 0 32px;

        @include desktop {
            padding-bottom: 64px;
        }
    }

    &-Sticky {
        &Container {
            background: $black;
            position: fixed;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 165px;
            width: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &Button {
            color: $white;
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            transform: rotate(-90deg);
            height: 42px;
            width: 165px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-shrink: 0;
            padding-right: 16px;
        }

        &Close {
            position: absolute;
            left: 9px;
            right: 9px;
            bottom: 16px;
            cursor: pointer;

            .CloseIcon {
                fill: $white;
                vertical-align: top;
            }
        }
    }
}

.NewsletterPopup {
    .Popup {
        &-Content {
            width: 90%;
            max-width: 982px;
            padding: 0;
            border-radius: 0;

            @include mobile {
                width: 100%;
                min-width: 0;
                max-width: 360px;
                max-height: 100%;
                overflow-y: auto;
            }
        }

        &-Heading {
            display: none;
        }

        &-Header {
            position: static;
            display: block !important;
            padding: 0;

            @include mobile {
                padding-bottom: 100%;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        &-CloseBtn {
            position: absolute;
            z-index: 5;

            @include mobile {
                top: auto !important;
                right: 16px !important;
                bottom: -50px;
            }

            @include desktop {
                top: 50px !important;
                right: 32px !important;
            }

            .CloseIcon {
                width: 24px !important;
                height: 24px !important;
                vertical-align: top;
            }
        }
    }

    &.Popup_isVisible {
        @include mobile {
            top: 0;
            height: 100vh;
            align-items: center;
        }
    }
}
