/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';

.News {
  &-Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 37px;

    @include mobile-small {
      grid-template-columns: 1fr;
    }

    @include desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    &:before {
      @include desktop {
        content: "HC Insider";
        font-family: $font-BlackerProDisplay;
        color: $black;
        opacity: 0.03;
        position: absolute;
        font-size: 145px;
        line-height: 1;
        letter-spacing: 4px;
        top: 27%;
        left: -60px;
        z-index: -1;
      }

      @include tablet {
        top: 35%;
      }

      @media (min-width: 1600px) {
        left: -7%;
      }

      @media (min-width: 1800px) {
        left: -180px;
      }
    }

    nav {
      grid-column: 1/-1;
    }
  }

  .CategoryListing-Description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 25px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &-Title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.003em;
    text-align: center;
    margin: 35px 0 12px;

    @include desktop {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.2px;
      margin-bottom: 27px;
    }
  }

  &_page {
    &_top_slider {
      margin: 0 -14px;

      @include desktop {
        margin: 0;
      }

      &.slick-slider {
        .slick-arrow {
          display: none !important;
        }

        .slick-track {
          transform: none !important;
          width: 100% !important;

          &:before,
          &:after {
            display: none;
          }
        }

        .slick-slide {
          float: none;
          width: 100% !important;
        }
      }

      .SliderWidget-Figure {
        @include ultra-narrow-desktop {
          text-align: right;
        }
      }

      .SliderWidget-FigureImage {
        @include mobile {
          z-index: -1;
        }

        @include desktop {
          padding-bottom: 0;
          height: auto;
          vertical-align: top;

          img {
            position: static;
            vertical-align: top;
            width: auto !important;
          }
        }

        @include ultra-narrow-desktop {
          position: static;

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            width: 0;
            min-width: 100%;
            min-height: 100%;
          }
        }
      }

      .SliderWidget-Figcaption {
        background: $white;
        width: 100%;
        padding: 36px 36px 43px;

        @include mobile {
          position: static;
          transform: none;
          width: auto;
          margin: -30px 14px 0;
        }

        @include desktop {
          bottom: 9.5%;
          top: 7.9%;
          left: auto !important;
          right: 0;
          padding: 15px 63px;
          max-width: 607px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transform: none;
          position: absolute;
        }

        @include narrow-desktop {
          top: 25px;
          bottom: 25px;
        }

        @include ultra-narrow-desktop {
          position: relative;
          right: 0;
          top: auto;
          bottom: auto;
          margin-top: 36px;
          margin-left: 114px;
          margin-bottom: 36px;
          padding: 15px 26px 15px 40px;
          display: inline-block;
          vertical-align: top;
          width: auto;
          max-width: 100%;
        }

        h2 {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.0008em;
          margin: 0;
        }

        h3 {
          font-family: $font-BlackerProDisplay;
          font-weight: 700;
          font-size: 44px;
          line-height: 48px;
          letter-spacing: -0.004em;
          margin: 0 0 20px;

          @include desktop {
            font-size: 44px;
            margin-bottom: 16px;
          }
        }

        h4 {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.0008em;
          margin: 0 0 17px;
          top: 0;

          @include desktop {
            margin-bottom: 24px;
          }
        }

        p {
          color: $color-grey-A1;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          margin: 0;
        }

        a {
          background: $black;
          color: $white;
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.04em;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-height: 48px;
          margin-top: 20px;
          padding: 0 16px;

          @include desktop {
            display: inline-flex;
            padding: 0 16px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &_store_services_slider_title,
    &_title_slider_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.003em;
      margin: 0 0 12px;
      text-align: center;

      @include desktop {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        margin-top: 35px;
        margin-bottom: 12px;
      }
    }

    &_title_slider {
      &.slick-slider {
        margin: 0 -14px 35px;

        @include desktop {
          margin: 0;
        }

        .slick-arrow {
          display: none !important;
        }

        .slick-track {
          transform: none !important;
          width: 100% !important;
          display: flex;
          gap: 14px;
          flex-wrap: wrap;

          @include desktop {
            justify-content: space-between;
          }

          &:before,
          &:after {
            display: none;
          }
        }

        .slick-slide {
          float: none;
          width: calc(50% - 7px) !important;

          @include desktop {
            width: calc(25% - 11px) !important;
          }

          &:first-child {
            width: 100% !important;

            @include desktop {
              width: calc(50% - 9px) !important;
            }
          }
        }
      }
    }

    &_store_services_slider {
      &.slick-slider {
        margin: 0 0 15px;

        .slick-arrow {
          display: none !important;
        }

        .slick-track {
          transform: none !important;
          width: 100% !important;
          display: flex;
          gap: 37px;
          flex-wrap: wrap;

          @include desktop {
            gap: 17px;
            justify-content: space-between;
          }

          &:before,
          &:after {
            display: none;
          }
        }

        .slick-slide {
          float: none;
          width: 100% !important;

          @include desktop {
            width: calc(33.3333% - 12px) !important;
          }
        }
      }

      .SliderWidget-Figcaption {
        position: static;
        transform: none;

        h2 {
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.2px;
          margin: 24px 0 6px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.0008em;
          margin: 0;
        }

        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-top: 12px;
          text-decoration: underline;
          display: inline-block;

          @include desktop {
            display: none;
          }
        }
      }
    }
  }

  .CmsBlock-Wrapper {
    > p {
      margin: 0;
    }
  }

  .CategoryPagination {
    @include mobile {
      display: flex;
    }
  }
}