/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.GuestOrderReturn {
    &-Heading {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        margin-bottom: 8px;

        @include desktop {
            margin-bottom: 23px;
            text-align: center;
        }
    }

    &-goBack {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &-SubHeading {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 30px;

        @include desktop {
            margin-bottom: 50px;
            text-align: center;
        }
    }

    &-ColumnWrapper {
        // display: flex;
        margin-bottom: 2.4rem;

        @include mobile {
            display: block;
            padding: 1.2rem 0;
        }
    }

    &-Column {
        flex: 1;

        &_isContent {
            margin-right: 1.8rem;

            @include mobile {
                margin-right: 0;
            }
        }
    }

   .ContactForm {
       @include mobile {
           margin-top: 15px;
       }
   }

    &GuestReturnSuccess {
        width: 100%;
        max-width: 980px;
        margin: 0 auto;

        &-content {
            color: $color-grey-A1;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin-bottom: 27px;

            @include desktop {
                margin-bottom: 32px;
            }

            &-bkg {
                background: $icegrey;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.2px;
                padding: 12px 16px;
                margin: 0 -14px 19px;

                @include desktop {
                    margin: 0 0 14px;
                }
            }
        }

        &-Actions {
            @include desktop {
                display: flex;
                justify-content: flex-end;
            }

            .Button {
                background: $black;
                color: $white;
            }
        }
    }

    &ProgressBar {
        display: inline-block;
        margin: 0 0 21px;
        overflow-y: hidden;
        overflow-x: auto;
        max-width: 100%;
        padding-top: 6px;

        // Firefox support
        scrollbar-width: none !important;
        scrollbar-color: none !important;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
        }

        @include desktop {
            display: block;
            margin: 0 auto 50px;
            width: 100%;
            max-width: 980px;
        }

        &.step1 {
            @include desktop {
                margin-top: 47px;
                max-width: 725px;
            }
        }

        &-container {
            border-top: 2px solid $grey;
            display: flex;
            gap: 30px;

            @include desktop {
                justify-content: space-between;
                gap: 126px;
            }
        }
        &-col {
            border-top: 2px solid $grey;
            width: 94px;
            flex-shrink: 0;
            padding-top: 30px;
            margin-top: -2px;

            &:before {
                content: "";
                width: 10px;
                height: 10px;
                background: $white;
                border: 1px solid $black;
                border-radius: 50%;
                position: absolute;
                top: -6px;
                left: 21px;
            }

            &.active {
                &:before {
                    background: $black;
                }

                &:not(:first-child) {
                    &:after {
                        content: "";
                        width: 500px;
                        height: 2px;
                        background: $black;
                        border-radius: 50%;
                        position: absolute;
                        top: -2px;
                        right: calc(100% - 21px);
                    }
                }
            }

            &:first-child {
                &:before {
                    left: 0;
                }
            }

            &:last-child {
                &:before {
                    left: auto;
                    right: 0;
                }
            }
        }

        &-content {
            h3 {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.0008em;
                margin: 0 0 8px;
            }

            p {
                color: $color-grey-A1;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: -0.0008em;
                margin-bottom: 0;
            }
        }
    }

    &ProductSelection {
        width: 100%;
        max-width: 980px;
        margin: 0 auto;
    }

    &GuestReturnForm {
        width: 100%;
        max-width: 725px;
        margin: 0 auto;

        &-cols {
            display: flex;
            justify-content: space-between;
            gap: 6px;
            flex-direction: column;

            @include desktop {
                flex-direction: row;
                gap: 26px;
            }
        }

        &-col {
            @include desktop {
                width: calc(50% - 13px);
                flex-shrink: 0;
            }
        }

        &-content {
            background: $greyF8F8F8;
            padding: 14px 16px;
            margin: 0 -14px;

            @include desktop {
                margin: 0;
                padding: 27px 26px;
            }

            p {
                margin: 0 0 48px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    margin: 0 5px;
                }
            }
        }
    }
}
