/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.CountdownTimer {
  line-height: normal;
  text-align: center;


  &_Kylie {
    padding: 20px 0;

    .CountdownTimer-counter-label {
      font-weight: 400;
    }
  }

  &-counter {

    &-wrapper {
      display: inline-block;
      padding: 0 10px;
      vertical-align: top;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-Image {
      width: auto;
      max-width: 100%;
      margin-right: 15px;
    }
  }
}