/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.HeaderSlogan {
    .Header > & {
        background: #000;
        color: $white;
        padding-left: 32px;
        padding-right: 32px;
        max-width: 100%;

        @include desktop {
            padding-left: 56px;
            padding-right: 56px;
        }
    }

    &__slogan {
        padding: 5px 0;
        text-align: center;
        font-size: 12px;
        line-height: 14px;

        @include desktop {
            display: none;
        }
        p {
            margin: 0;
            line-height: 14px;
            font-size: 12px;
        }
    }

    &__slogan_admin {
        background: black;
        color: white;
        overflow: hidden;
        width: 100%;
        z-index: 100;

        .hideOnScroll & {
            @include mobile {
                transform: translateY(-400px);
            }
        }

        + .Header {
            .hideOnScroll & {
                @include mobile {
                    transform: translateY(-400px);
                }
            }
        }

        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;

            @include mobile {
                flex-direction: column;
            }
        }

        &-counter-container {
            display: flex;
            align-items: flex-end;
            padding: 0 20px;

            @include mobile {
                margin: 10px 0 15px;
            }
        }

        &-counter-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 70px;
        }

        &-counter-dots {
            height: 40px;
            line-height: 40px;
        }

        &-counter-count {
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: white;
            color: black;
            font-weight: 600;
            border-radius: 3px;
            overflow: hidden;
            letter-spacing: 1px;
        }

        .Button {
            background: $white;
            border-color: $white;
            color: $black;

            &:not([disabled]):hover {
                background: $black;
                border-color: $white;
                color: $white;
            }
        }

        .RenderWhenVisible + .RenderWhenVisible {
            padding: 10px 0 0;

            @include desktop {
                padding: 0 20px;
            }
        }
    }
}

.Header {
    &__slogan_admin {
        &-prefix {
            @include mobile {
                text-align: center;
            }
        }
    }
}
