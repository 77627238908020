/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

:root {
  --left: 50%;
}

.ModiFacePopup {
  &.Popup {
    background: rgba(30, 30, 30, 0.87);
  }

  &-Top {
    padding: 15px;
  }

  .Popup-Content {
    background: $black050708;
    border-radius: 12px;
    max-width: 460px;
    min-width: 360px;
    padding: 0px;
    width: 360px;
  }

  .Popup-Heading {
    display: none;
  }

  .Popup-Header {
    position: static;
    display: block !important;
    padding: 0;
  }

  .Popup-CloseBtn {
    position: absolute;
    z-index: 5;
    top: -10px;
    right: 0 !important;

    path {
      fill: $white;
    }
  }

  &-Action {
    .Button {
      display: flex;
      height: 42px;
      font-size: 16px;

      &.ModiFacePopup-LiveMode {
        background-color: $pink;
        border-color: $pink;
        color: $white;

        &:not([disabled]):hover {
          background-color: $pink;
          border-color: $pink;
          color: $white;
        }
      }

      &:not([disabled]):hover {
        background-color: $black;
        border-color: $black;
        color: $white;
        height: 42px;
      }
    }
  }

  &-Label {
    height: 0;

    &_isActive {
      height: auto;
      z-index: 2;
      text-align: left;
      position: absolute;
      top: 10px;
      left: 10px;
      width: calc(100% - 20px);
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      text-overflow: ellipsis;
    }
  }

  &-Canvas {
    &_isActive {
      // width: 360px;
      z-index: 5;
      height: 640px;

      .ModiFacePopup-Close {
        display: block;
        position: absolute;
        cursor: pointer;
        width: 10%;
        margin: 6px;
        right: 0;
        z-index: 101;

        canvas {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .ModiFacePopup-Compare {

        display: block;
        position: absolute;
        width: 2px;
        height: 100%;
        left: var(--left);
        background: black;
        z-index: 101;

        &-Drag {
          position: absolute;
          left: var(--left);
          bottom: 30%;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: black;
          transform: translateX(-50%);
          cursor: pointer;
        }
      }

            .ModiFacePopup {
              &-AddToCart {
                bottom: 90px;
                padding: 0;
              }
      
              &-CTA {
                bottom: 7px;
              }

              &-AddToCart {
                img {
                  height: 36px;
                  width: 36px;
                  float: right;
                  // right: 16px;
                }
              }
      
              &-AddToCart, 
              &-CTA,
              &-CTAQuad {
                display: block;
                position: absolute;
                cursor: pointer;
                width: 10%;
                right: 5px;
                z-index: 101;
                background: transparent;
                border: none;

                &:hover {
                  background: transparent !important;
                  border: none;
                }

                &Quad {
                  bottom: 50px;
                }
              }
            }
    }
  }

  &-Canvase {
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  &-Close,
  &-AddToCart,
  &-CTA,
  &-Compare,
  &-CTAQuad {
    display: none;
  }

  &-Field {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 16px;


    .Field {
      margin-top: 0;

      input {
        width: 100%;
      }
    }
  }

  .swatch-background {
    box-shadow: 0 1px 1px -2px transparent;
    clear: both;
    margin-bottom: 10px;
    max-width: -webkit-fill-available;
    bottom: 0;
    position: absolute;

    &-container {
      overflow-x: scroll;
      text-align: center;
      padding: 5px 0 5px 0;
      &::-webkit-scrollbar {
        background-color: #f0f8ff00;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255,255,255,.75);
        border-radius: 16px;
      }
      &::-webkit-scrollbar-track-piece {
        width: 0px; //for horizontal scrollbar
        height: 0px; //for vertical scrollbar
      }
    }
  }

  .swatch-container {
    width: max-content;
    margin: 0 auto;
  }

  &-CompareContainerQuarter {
    &_isActive {
      background-color: #000;
      height: 640px;
      overflow: hidden;
    }
  }

  &-ContainerQuarter {
    position: absolute;
    top: 0;
    height: 50%;
    overflow: hidden;
    width: 50%;
    background-color: #1d1d1b;
    border-left: 1px solid #484848;

    &:nth-child(1) {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 0;
      left: 50%;
    }

    &:nth-child(3) {
      top: 50%;
      left: 0;
    }

    &:nth-child(4) {
      top: 50%;
      left: 50%;
      border-top: 1px solid #484848;
    }
      canvas {
        height: 100%;
    }
  }

  .swatch {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    opacity: 1;
    vertical-align: top;
    cursor: pointer;
    transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    &.Checked {
      border: 3px solid white;
      padding: 5px;
      &::before {
        font-family: sans-serif;
        content: "✔";
        color: white;
        display: inline-block;
        width: 1em;
      }
    }
  }

  .instructions {
    .title {
      color: $white;
      text-align: center;
      padding-top: 20px;
      font-size: 22px;
      line-height: 26px;
    }

    .steps {
      padding: 20px 0 40px;
      display: flex;
      flex-wrap: wrap;
    }

    .step {
      min-width: 50%;
      flex-basis: 50%;
      margin: 20px 0;

      .image {
        text-align: center;

        img {
          max-width: 100%;
          max-height: 100%;
          width: auto !important;
        }
      }

      .text {
        color: $white;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
      }

      &.single {
        min-width: 100%;

        .image img {
          max-width: 70%;
        }

        .text {
          padding: 10px;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    &.makeup {
      .steps {
        padding: 40px 0 60px;
      }
    }
  }

  .Loader {
    background-color: rgba(255, 255, 255, 0.7);

    // @include desktop {
    //   left: -20px;
    //   right: -20px;
    //   top: -20px;
    //   bottom: -20px;
    // }
  }
    &-Button {
      bottom: 15%;
      width: 100%;
      position: absolute;
    }
}