/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.TrackOrderResult {
  &-Heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.2px;
    margin: 0 0 4px;
    text-align: center;

    @include desktop {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &-Description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.0008em;
    text-align: center;
    margin: 0 0 29px;

    @include desktop {
      margin-bottom: 21px;
    }

    strong {
      color: #EC008C;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      display: block;
      letter-spacing: -0.2px;
      margin-bottom: 4px;
    }
  }

  &HorizontalProgressBar {
    display: flex;
    flex-direction: column;
    gap: 36px;
    max-width: 210px;
    margin: 0 auto 26px;

    @include desktop {
      flex-direction: row;
      gap: 84px;
      max-width: 440px;
      margin-bottom: 42px;
    }

    &:after,
    &:before {
      content: "";
      background: $greyD0D0D0;
      width: 1px;
      height: 100%;
      position: absolute;
      left: 37px;
      top: 0;
      z-index: -1;

      @include desktop {
        width: calc(100% - 74px);
        height: 1px;
        left: 37px;
        top: 37px;
      }
    }

    &:after {
      background-color: $black;
      height: 0;

      @include desktop {
        height: 1px;
        width: 0;
      }
    }

        &.step {
    
          // &_processing_new,
          &_pending,
          &_processing  {
            .TrackOrderResultHorizontalProgressBar-block {
              &:first-child {
                .TrackOrderResultHorizontalProgressBar-blockImage {
                  border: 2px solid $foux;
    
                  svg {
                    path {
                      stroke: $foux;
                    }
                  }
                }
    
                .TrackOrderResultHorizontalProgressBar-blockTitle label {
                  color: $black;
                }
              }
            }
          }
        }

    &.step {
      &_processing_new,
      &_confirmed,
      &_partially_confirmed
      // &_processing 
      {
      &:after {
        height: 50%;

        @include desktop {
          height: 1px;
          width: calc(50% - 37px);
        }
      }

      .TrackOrderResultHorizontalProgressBar-block {
        &:nth-child(2),
        &:first-child {
          .TrackOrderResultHorizontalProgressBar-blockImage {
            border: 2px solid $foux;

            svg {
              path {
                stroke: $foux;
              }
            }
          }

          .TrackOrderResultHorizontalProgressBar-blockTitle label {
            color: $black;
          }
        }

        &:first-child {
          .TrackOrderResultHorizontalProgressBar-blockImage {
            border: 1px solid $black;

            svg {
              path {
                stroke: $black;
              }
            }
          }
        }
      }
    }
  }

        &.step {
    
          &_partially_complete,
          &_complete {
            &:after {
              height: 100%;
    
              @include desktop {
                height: 1px;
                width: calc(100% - 74px);
              }
            }
    
            .TrackOrderResultHorizontalProgressBar-block {
    
              .TrackOrderResultHorizontalProgressBar-blockImage {
                border-color: $black;
    
                svg {
                  path {
                    stroke: $black;
                  }
                }
              }
    
              .TrackOrderResultHorizontalProgressBar-blockTitle label {
                color: $black;
              }
    
    
              &:last-child {
                .TrackOrderResultHorizontalProgressBar-blockImage {
                  border: 2px solid $foux;
    
                  svg {
                    path {
                      stroke: $foux;
                    }
                  }
                }
              }
            }
          }
        }

    &.step_processing_partially {
      &:after {
        height: 100%;

        @include desktop {
          height: 1px;
          width: calc(100% - 74px);
        }
      }

      .TrackOrderResultHorizontalProgressBar-block {

          .TrackOrderResultHorizontalProgressBar-blockImage {
            border-color: $black;

            svg {
              path {
                stroke: $black;
              }
            }
          }

          .TrackOrderResultHorizontalProgressBar-blockTitle label {
            color: $black;
          }


        &:last-child {
          .TrackOrderResultHorizontalProgressBar-blockImage {
            border: 1px solid $greyD0D0D0;

            &:before {
              content: "";
              width: 72px;
              height: 72px;
              border-radius: 50%;
              border-style: solid;
              border-width: 2px;
              border-color: $foux transparent transparent $foux;
              position: absolute;
              left: -2px;
              top: -2px;
              transform: rotate(-45deg);
            }

            &:after {
              content: "";
              width: 72px;
              height: 72px;
              border-radius: 50%;
              border-style: solid;
              border-width: 2px;
              border-color: $foux transparent transparent $foux;
              position: absolute;
              left: -2px;
              top: -2px;
              transform: rotate(-135deg);
            }

            svg {
              path {
                stroke: $foux;
              }
            }
          }
        }
      }
    }

    &-block {
      display: flex;
      align-items: center;
      gap: 28px;

      @include desktop {
        flex-direction: column;
      }

      &Image {
        background: $white;
        border: 1px solid $greyD0D0D0;
        border-radius: 50%;
        width: 74px;
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          path {
            stroke: $color-grey-A1;
          }
        }
      }

      &Title {
        color: $color-grey-A1;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.0008em;

        @include desktop {
          text-align: center;
        }

        label {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 4px;
        }
      }
    }
  }

  &VerticalProgressBar {
    display: flex;
    flex-direction: column;
    gap: 28px;

    &:after,
    &:before {
      content: "";
      background: $black;
      width: 2px;
      height: calc(100% - 26px);
      position: absolute;
      left: 7px;
      top: 0;
    }

        &.step {
    
          &_processing_new,
          &_pending {
            .TrackOrderResultVerticalProgressBar-block {
              &:first-child {
                .TrackOrderResultVerticalProgressBar-blockImage {
                  background-color: $black;
                }
    
                .TrackOrderResultVerticalProgressBar-blockTitle label {
                  color: $black;
                }
              }
            }
          }
        }

    &.step_processing {
      .TrackOrderResultVerticalProgressBar-block {
        &:nth-child(2),
        &:first-child {
          .TrackOrderResultVerticalProgressBar-blockImage {
            background-color: $black;
          }

          .TrackOrderResultVerticalProgressBar-blockTitle label {
            color: $black;
          }
        }
      }
    }

        &.step {
    
          &_partially_complete,
          &_complete {
            .TrackOrderResultVerticalProgressBar-block {
    
              .TrackOrderResultVerticalProgressBar-blockImage {
                background-color: $black;
              }
    
              .TrackOrderResultVerticalProgressBar-blockTitle label {
                color: $black;
              }
            }
          }
        }

    &.step_complete_partially {
      .TrackOrderResultVerticalProgressBar-block {

        .TrackOrderResultVerticalProgressBar-blockImage {
          background-color: $black;
        }

        .TrackOrderResultVerticalProgressBar-blockTitle label {
          color: $black;
        }
      }
    }

    &-container {
      background: $greyF8F8F8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 24px;
      margin: 0 -14px;
      padding: 32px 20px;

      @include desktop {
        flex-direction: row;
        gap: 40px;
        max-width: 982px;
        width: 100%;
        padding: 55px 20px;
        margin: 0 auto;
      }
    }

    &-block {
      display: flex;
      align-items: flex-start;
      gap: 40px;
      min-height: 40px;

      &Image {
        background: $white;
        border: 1px solid $black;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        position: relative;
        z-index: 1;
      }

      &Title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.0008em;
        }

        span {
          margin-bottom: 8px;
          display: block;
          line-height: 1;

          &:empty {
            margin-bottom: 0;
          }
        }
      }
    }

    &-orderTitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.0008em;
      margin-bottom: 16px;
      text-align: center;

      @include desktop {
        text-align: left;
      }
    }

    &DetailsList {
      border-bottom: 1px solid $greyD0D0D0;
      text-align: center;

      @include desktop {
        border-bottom: 0 none;
        border-top: 1px solid $greyD0D0D0;
        padding-top: 20px;
        text-align: left;
      }

      &-item {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin-bottom: 8px;

        .Button {
          margin-top: 16px;
          margin-bottom: 16px;
          width: auto;
        }
      }
    }
  }
}

.TrackOrderPopup {
    @include mobile {
        --popup-content-padding: 5px;
    }
    &-Details {        
        border-collapse: collapse;
        width: 100%;
        border: 1px solid #000;
        --secondary-dark-color: #c5c5c5;
        th {
            
            padding-top: 12px;
            padding-left: 6px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #000;
            color: #fff;
        }
        tr:nth-child(even) {
                background-color: #0f0d0d;
            }
        th,td {
            border: 1px solid #ddd;
            padding: 8px;
        }
    }

    &-DetailsTop {
        text-align: center;
    }
    &-DetailsList{
        display: flex;
        justify-content: space-between;
    }

    &-Field{
        @include mobile {
            font-size: 10px;
            padding: 0;
        }
        padding: 0 20px;
        list-style-type: disclosure-closed;
        text-align: left;
        &:before {
            content: none;
        }
    }
  padding: 0 10px;
  @include mobile {
    padding: 0;
    --header-total-height: 60px;
  }
  &-Icon {
    padding-left: 5px;
  }
  .Image {
    cursor: pointer;
    width: 40px;
    padding: 5%;
    margin-bottom: 10px;
  }
  label {
    cursor: pointer;
    font-weight: 600;
    bottom: 3px;
  }

  button {
    display: flex;
    align-items: center;
  }
  table td, table th {
      text-align: center;
  }
}
