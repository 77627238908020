:root {
  --cms-block-bg-image: none;
  --cms-block-bg: none;
  --cms-block-fc: #000000;
}

.beauty-cms_block {
  &-1 {
    &_top_link {
      display: flex;
      justify-content: flex-end;
      margin-bottom: -24px;

      @include desktop {
        margin-bottom: -26px;
      }

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        position: relative;
        z-index: 1;
      }
    }

    .BrandWidget-Main {
      margin-bottom: 40px;

      h1,
      h2 {
        @include mobile {
          text-align: left;
          margin-left: 8px;
        }
      }
    }
  }

  &-2 {
    @include mobile {
      margin-left: -14px;
      margin-right: -14px;
    }

    @include desktop {
      display: flex;
      justify-content: space-between;
    }

    &_col {
      position: relative;
      margin-bottom: 36px;

      @include desktop {
        padding-bottom: 74px;
        width: calc(50% - 8px);
      }
    }

    &_image {
      img {
        vertical-align: top;

        @include mobile {
          width: 100% !important;
        }
      }
    }

    &_wholeLink {
      position: static;

      a {
        @include mobile {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }

    &_content {
      padding: 12px 12px 16px;
      text-align: center;
      background: $white;

      @include desktop {
        padding: 22px 22px 13px;
      }
      &-wrapper {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 0;

        @include desktop {
          left: 45px;
          right: 45px;
        }
      }

      h2 {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: 0 0 4px;

        @include desktop {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.003em;
          margin-bottom: 8px;
        }
      }

      h3 {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin: 0 auto 4px;
        max-width: 471px;

        @include desktop {
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
          margin-bottom: 7px;
        }

        &.mobile {
          margin-bottom: 0;
        }
      }

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        display: inline-block;
      }
    }
  }

  &-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 14px;

    @include desktop {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 16px;
    }

    &-wrapper {
      background: var(--cms-block-bg);

      .bg_mode_active & {
        background: none;
      }
    }

    .block-static-block & {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &_col {
      position: relative;
      margin-bottom: 27px;

      @include desktop {
        margin-bottom: 38px;
      }
    }

    &_image {
      img {
        vertical-align: top;
      }
    }

    &_wholeLink {
      position: static;

      a {
        @include desktop {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }

    &_content {
      &-wrapper {
        padding: 12px 0 0;

        @include desktop {
          padding-top: 16px;
        }
      }

      h2 {
        color: var(--cms-block-fc);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin: 0 0 4px;
        text-align: left !important;

        @include desktop {
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.2px;
          margin-bottom: 8px;
        }
      }

      h3 {
        color: var(--cms-block-fc);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin: 0 0 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include mobile {
          font-size: 12px !important;
        }

        @include desktop {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
          margin-bottom: 7px;
        }

        &.mobile {
          margin-bottom: 0;
        }
      }

      a {
        color: var(--cms-block-fc);
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        display: inline-block;

        &:hover {
          color: var(--cms-block-fc);
        }
      }
    }
  }

  &-4-slider {
    .SliderWidget-FigureImage {
      img {
        vertical-align: top;
      }
    }

    .SliderWidget-Figcaption {
      position: static;
      transform: none;
      padding: 12px 0 0;

      @include desktop {
        padding-top: 16px;
      }

      h2 {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin: 0 0 4px;
        text-align: left !important;

        @include desktop {
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.2px;
          margin-bottom: 8px;
        }
      }

      h3 {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin: 0 0 11px;

        @include mobile {
          font-size: 12px !important;
        }

        @include desktop {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
          margin-bottom: 7px;
        }

        &.mobile {
          margin-bottom: 0;
        }
      }

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        display: inline-block;

        @include desktop {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          opacity: 0;
        }
      }

      p {
        margin: 0;
        position: static;
      }
    }

    .slick-slide {
      width: 100% !important;
      margin-bottom: 27px;

      @include desktop {
        margin-bottom: 38px;
      }
    }

    .slick-track {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 14px;

      @include desktop {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 16px;
      }

      &:before,
      &:after {
        display: none;
      }
    }
  }

  &-5 {
    @include mobile {
      margin-left: -14px;
      margin-right: -14px;
    }

    &_nomargin {
      @include mobile {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &_fashion {
      .ContentWrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      .beauty-cms_block-5_content-wrapper {
        left: 48px;
        right: 48px;

        @include mobile {
          left: 14px;
          right: 14px;
        }

        &_fashion {
          @include desktop {
            left: 50%;
          }
        }
      }

      .beauty-cms_block-5_col {
        margin-bottom: 19px;

        @include desktop {
          margin-bottom: 60px;
        }
      }
    }

    &_col {
      position: relative;
      margin-bottom: 17px;

      @include desktop {
        margin-bottom: 40px;
      }
    }

    &_image {
      img {
        vertical-align: top;
      }
    }

    &_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 17px;
      text-align: center;

      &-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      h2 {
        color: $white;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        letter-spacing: -0.003em;
        margin: 0 0 8px;

        @include desktop {
          margin-bottom: 12px;
        }
      }

      h3 {
        color: $white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        letter-spacing: -0.2px;
        margin: 0 0 16px;
        max-width: 348px;

        @include mobile {
          font-size: 16px !important;
        }

        @include desktop {
          margin-bottom: 7px;
        }

        &.mobile {
          margin-bottom: 0;
        }
      }

      h4 {
        color: $white;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 8px;
        letter-spacing: -0.2px;
      }

      a {
        color: $white;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        display: inline-block;
      }

      &_nopadding {
        padding: 0;
      }

      &_left {
        align-items: flex-start;
      }

      &_fashion {
        h2 {
          font-family: $font-blackerDisplay;
          letter-spacing: 0.25px;
          margin: 0 0 12px;

          @include desktop {
            font-size: 40px;
            line-height: 48px;
          }
        }

        h3 {
          font-weight: 400;
          font-size: 14px !important;
          line-height: 22px;
          letter-spacing: -0.0008em;
          margin: 0 0 20px;
        }
      }

      &_dark {
        a,
        h4,
        h3,
        h2 {
          color: $black;
        }
      }
    }
  }

  &-6 {
    @include mobile {
      margin-left: -14px !important;
      margin-right: -14px !important;
      margin-bottom: 13px;
    }

    @include desktop {
      margin-left: -8px !important;
      margin-right: -8px !important;
      display: flex;
      gap: 16px;
      margin-bottom: 36px;
    }

    &-wrapper {
      background-color: var(--cms-block-bg);
      background-image: var(--cms-block-bg-image);
      background-repeat: no-repeat;

      .bg_mode_active & {
        background: none;
      }

      @include desktop {
        background-size: 100% auto;
      }
    }

    &_title_container {
      padding: 12px 0 28px;
      flex-direction: column-reverse !important;


      @include desktop {
        justify-content: center;
        align-items: center;
        padding: 41px 0 24px;
        flex-direction: row-reverse !important;
      }
    }

    &_title {
      .beauty-cms_block-6_link + & {
        @include mobile {
          margin: 0 0 15px;
        }
      }

      .beauty-cms_block-6_link + & {
        @include desktop {
          margin-left: auto;
        }
      }
    }

    &_link {
      @include desktop {
        margin-left: auto;
      }
    }

    .SliderWidget-Figure,
    .post-holder,
    &_col {
      position: relative;
      margin-bottom: 28px;
      padding: 0 8px;

      @include mobile-small {
        padding: 0;
      }

      @include desktop {
        padding-bottom: 52px;
        margin-bottom: 79px;
      }
    }

    &_image {
      img {
        vertical-align: top;
      }
    }

    &_wholeLink {
      position: static;

      a {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;

        @include desktop {
          left: 8px;
          right: 8px;
        }
      }
    }

    &_content {
      padding: 20px 16px;
      text-align: center;

      @include desktop {
        background: $white;
        padding: 8px 10px;
      }

      &-wrapper {
        @include desktop {
          position: absolute;
          left: 16px;
          right: 16px;
          bottom: 0;
        }
      }

      h2 {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin: 0 0 4px;

        @include mobile {
          color: var(--cms-block-fc);
        }
      }

      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0 auto;
        max-width: 471px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 44px;

        @include mobile {
          color: var(--cms-block-fc);
        }

        p {
          line-height: 22px;

          @include mobile {
            color: var(--cms-block-fc);
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.mobile {
          margin-bottom: 0;
        }
      }

      h4 {
        display: none;
      }

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        display: inline-block;
      }
    }

    &.slick-slider .slick-dots,
    .cms_swiper_dots {
      @include desktop {
        bottom: 40px;
      }
    }

    .cms_swiper_dot {
      border-color: $xryso;
    }

    .SliderWidget-Figcaption {
      position: static;
      transform: none;
    }

    &.slick-slider {
      display: block;
    }
  }

  &-8 {
    margin-bottom: 21px;

    @include mobile {
      overflow: hidden;
    }

    @include desktop {
      margin-bottom: 47px;
    }

    .VideoWidget {
      &-Wrapper {
        @include desktop {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        &:before {
          content: "";
          background: $icegrey;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;

          @include desktop {
            left: 48px;
            right: 48px;
          }
        }
      }

      &-VideoContainer {
        @include mobile {
          position: absolute;
          left: 156px;
          bottom: 0;
          width: calc( 100% - 156px ) !important;
          justify-content: flex-end;
          max-height: 100%;
        }

        @include desktop {
          flex-shrink: 0;
          margin: 0;
        }

        @include tablet {
          max-width: 70%;
        }

        @include ultra-narrow-desktop {
          max-width: 70%;
        }

        .VideoWidget-PlayIcon {
          @include mobile {
            display: none;
          }
        }

        .VideoWidget-Image-Link {
          @include mobile {
            width: auto;
          }
        }
      }

      &-Thumbnail {
        img {
          @include desktop {
            object-fit: contain;
          }
        }
      }

      &-content {
        padding: 26px 0 32px;
        max-width: 210px;

        @include desktop {
          max-width: 100%;
          padding: 25px 2%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
        }

        @include wide-desktop {
          padding-inline: 8%;
        }
      }

      &-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        margin: 0 0 12px;
        text-align: left;

        @include desktop {
          max-width: 336px;
        }
      }

      &-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin-bottom: 12px;

        @include desktop {
          max-width: 336px;
        }
      }

      &-cta {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        margin-bottom: 20px;
        display: inline-block;

        @include desktop {
          margin-bottom: 0;
        }
      }

      &-playMobile {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        display: inline-block;
        padding-right: 32px;

        .VideoWidget-PlayIcon {
          width: 16px;
          height: 16px;
          border-width: 1px;
          top: 50%;
          left: auto;
          right: 0;
          margin: -8px 0 0;

          &:before {
            width: 12px;
            height: 8px;
            border-width: 4px 6px;
            left: 5px;
            top: 3px;
          }
        }
      }
    }
  }

  &-8-reverse {
    .VideoWidget {
      &-Wrapper {
        @include desktop {
          flex-direction: row;
        }

        &:before {
          background: $black;
        }
      }

      &-description,
      &-cta,
      &-playMobile,
      &-title {
        color: $white;
      }

      &-description,
      &-title {
        @include desktop {
          max-width: 278px;
        }
      }

      &-PlayIcon {
        border-color: $white;

        &:before {
          border-left-color: $white;
        }
      }
    }
  }

  &-9 {
    @include mobile {
      margin-left: -14px !important;
      margin-right: -14px !important;
      margin-bottom: 37px;
    }

    @include desktop {
      margin-bottom: 89px;
      padding-top: 203px;
    }

    &-wrapper {
      background: var(--cms-block-bg);

      .bg_mode_active & {
        background: none;
      }
    }

    &:before {
      @include mobile {
        content: "";
        background: $greyF0F0F0;
        top: 0;
        bottom: 16px;
        left: 0;
        right: 19px;
        position: absolute;
      }
    }

    &_desktop-height {
      @include desktop {
        position: relative;
        padding-bottom: 36%;
      }
    }

    .ExpandableHorizontal_wrapper {
      @include desktop {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    &_title_container {
      padding: 12px 0 28px;

      @include desktop {
        justify-content: center;
        align-items: center;
        padding: 41px 0 24px;
      }
    }

    &_title {
      padding: 0 32px 17px;
      font-family: $font-blackerProDisMd;
      font-size: 44px;
      line-height: 48px;
      letter-spacing: -0.004em;

      @include desktop {
        font-size: 9.7vw;
        line-height: 259px;
        opacity: 0.03;
        position: absolute;
        left: -32px;
        right: -32px;
        top: 0;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;

        .block-static-block[style] & {
          color: var(--cms-block-fc);
          opacity: 0.14;
        }
      }

      @media (min-width: 1700px) {
        font-size: 180px;
        left: -91px;
        right: -91px;
      }

      &_Link {
        font-size: 44px;

        @include desktop {
          font-size: 9.7vw;
        }

        @media (min-width: 1700px) {
          font-size: 180px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &_link {
      @include desktop {
        margin-left: auto;
      }
    }

    .SliderWidget-Figure,
    &_col {
      position: relative;
      margin-bottom: 28px;
      padding: 0 8px;

      @include mobile-small {
        padding: 0;
      }

      @include desktop {
        padding-bottom: 52px;
        margin-bottom: 79px;
      }
    }

    &_image {
      img {
        vertical-align: top;
      }

      .ExpandableHorizontal & {
        @include desktop {
          height: 100%;

          img {
            height: 100% !important;
            object-fit: cover;
          }
        }
      }
    }

    &_wholeLink {
      position: static;
      height: 0;

      a {
        @include mobile {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }

    &_content {
      position: absolute;
      left: 32px;
      right: 32px;
      bottom: 32px;
      color: $white;

      @include desktop {
        left: 54px;
        right: 54px;
        bottom: 39px;
      }

      .ExpandableHorizontal & {
        @include desktop {
          display: none;
        }
      }

      .ExpandableHorizontal_isContentExpanded & {
        @include desktop {
          display: block;
          animation: visibilityAnimation 0s 500ms forwards;
          visibility: hidden;
        }

        @include tablet {
          animation: visibilityAnimation 0s 1000ms forwards;
        }
      }

      h2 {
        color: $white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        letter-spacing: -0.2px;
        margin: 0;
        text-align: left !important;
      }

      h3 {
        color: $white;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0;

        &.mobile {
          margin-bottom: 0;
        }
      }

      p {
        color: $white;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.25px;
        margin: 0 0 16px;
      }

      a {
        color: $white;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-decoration: underline;
        display: inline-block;
      }
    }

    &_bottom {
      margin-top: 32px;
      padding-bottom: 26px;

      &_cols {
        display: flex;
        gap: 16px;
      }

      &_col {
        flex: 1;
      }

      &_title {
        font-family: $font-blackerDisplay;
        font-weight: 500;
        font-size: 72px;
        line-height: 80px;
        letter-spacing: -0.004em;
      }

      &_text {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: 0 0 12px;
      }

      &_link {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
      }
    }

    &.slick-slider .slick-dots,
    .cms_swiper_dots {
      @include desktop {
        bottom: 40px;
      }
    }

    .slick-dots {
      position: static;
      margin-top: 24px;
    }

    .scandi_horizontal_accordian {
      vertical-align: top;
    }
  }

  &-11 {
    @include mobile {
      margin-left: -14px;
      margin-right: -14px;
    }

    @include desktop {
      display: flex;
      justify-content: space-between;
    }

    &_col {
      position: relative;
      margin-bottom: 24px;

      @include desktop {
        width: calc(50% - 8px);
      }
    }

    &_image {
      img {
        vertical-align: top;
      }
    }

    &_wholeLink {
      position: static;

      a {
        @include mobile {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }

    &_content {
      &-wrapper {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 35px;

        @include desktop {
          left: 56px;
          right: 56px;
          bottom: 30px;
        }
      }

      h2 {
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 11px;
        text-align: left !important;

        @include desktop {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.003em;
          margin-bottom: 8px;
        }
      }

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        display: inline-block;
      }

      &_light {
        a,
        h2 {
          color: $white;
        }
      }
    }
  }

  &-12 {
    @include desktop {
      margin-left: -8px !important;
      margin-right: -8px !important;
    }

    &_subtitle {
      font-family: $font-blackerProDisMd;
      font-style: italic;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.2px;
      margin: 0 0 8px;

      @include desktop {
        text-align: center;
      }
    }

    &_title {
      font-family: $font-blackerProDisMd;
      font-style: italic;
      font-size: 44px;
      line-height: 48px;
      letter-spacing: -0.004em;
      padding: 8px 0 24px;

      @include desktop {
        text-align: center;
      }
    }

    &_col {
      margin-bottom: 16px;
      padding: 0 8px;
      vertical-align: top;

      @include mobile-small {
        padding: 0;
      }

      @include desktop {
        margin-bottom: 54px;
      }
    }

    &.slick-slider .slick-dots,
    &-pagination.cms_swiper_dots {
      position: static;
      padding-bottom: 26px;

      @include desktop {
        display: none !important;
      }

      .cms_swiper_dot {
        border-color: $xryso;
      }
    }
  }

  &-10 {
    &_title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.003em;
      margin-bottom: 20px;

      @include mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
      }

      @include desktop {
        text-align: center;
      }

      span {
        @include desktop {
          background: $white;
          padding: 0 16px;
        }
      }

      &:before {
        @include desktop {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          border-bottom: 1px solid $black;
        }
      }
    }

    &_cols {
      display: flex;
      flex-direction: column;

      @include desktop {
        flex-direction: row-reverse;
        gap: 72px;
        justify-content: space-between;
      }

      @include tablet {
        flex-direction: column;
        gap: 0;
      }
    }

    &_banner {
      border: 1px solid $black;
      width: 231px;
      min-height: 326px;
      padding: 33px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: flex-start;
      justify-content: center;
      text-align: center;

      .block-static-block[style] & {
        border-color: var(--cms-block-bg);
        background-color: var(--cms-block-bg);
        background-image: var(--cms-block-bg-image);
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      @include tablet {
        width: 100%;
        min-height: 0;
      }

      &_link {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;

        .block-static-block[style] & {
          color: var(--cms-block-fc);
        }
      }

      &_title {
        font-family: $font-BlackerProDisplay;
        font-weight: 700;
        font-size: 44px;
        line-height: 48px;
        letter-spacing: -0.004em;
        margin-bottom: 16px;

        .block-static-block[style] & {
          color: var(--cms-block-fc);
        }
      }

      &_desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin-bottom: 70px;

        .block-static-block[style] & {
          color: var(--cms-block-fc);
        }

        @include tablet {
          margin-bottom: 20px;
        }

        + .beauty-cms_block-10_banner_image {
          margin-top: -57px;
        }
      }

      &_image {
        margin: 13px 0;
      }
    }

    &_slider {
      @include desktop {
        width: calc(100% - 303px);
      }

      @include tablet {
        width: 100%;
      }
    }

    &__product_slider{
      &.slick-slider {
        margin: 0 -8px 23px;

        @include desktop {
          margin: 0 -30px 28px;
        }

        .slick-prev {
          @include desktop {
            left: -6px;
          }
        }

        .slick-next {
          @include desktop {
            right: -16px;
          }

          @include tablet {
            right: -6px;
          }
        }

        .slick-dots {
          position: static;
          margin-top: 15px;

          @include desktop {
            margin-top: 38px;
          }
        }

        .ProductCard {
          padding: 0 8px;

          @include desktop {
            padding: 0 30px;
          }

          &-FigureReview {
            padding-bottom: 0;
          }

          &-Name {
            @include mobile {
              font-size: 14px;
              line-height: 22px;
              height: 44px;
              margin-bottom: 8px;
            }
          }

          &-ShortDesc {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;

            @include mobile {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              height: 48px;
            }

            @include desktop {
              font-weight: 300;
              font-size: 14px;
              line-height: 22px;
            }
          }

          &-ProductConfigurableAttributes {
            display: none;
          }

          &-Footer {
            flex-direction: column;
            align-items: flex-start;
          }

          .FreeGiftCategoryPage-Link {
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.04em;
            display: flex;
            align-items: center;
            gap: 21px;
            justify-content: space-between;
            width: 100%;

            @include desktop {
              justify-content: flex-start;
              width: auto;
            }

            &:after {
              content: "";
              width: 6px;
              height: 6px;
              border-width: 1px;
              border-style: solid;
              border-color: transparent $black $black transparent;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }

  &-13 {
    margin-bottom: 40px;

    .ContentWrapper {
      @include mobile {
        padding: 0;
      }

      @include desktop {
        display: flex;
        align-items: center;
      }
    }

    .VideoWidget-playMobile {
      display: none;
    }

    .VideoWidget-VideoContainer {
      background-image: var(--cms-block-bg-image);
      background-color: var(--cms-block-bg);
      background-size: 100% auto;
      padding: 20px;

      @include desktop {
        padding: 100px 8.4%;
      }

      &:before {
        content: "";
        background: $white;
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;

        @include desktop {
          height: 100px;
        }
      }
    }

    .VideoWidget-Image {
      position: relative;
      width: 100%;
      height: calc(100% + 20px);
      bottom: 10px;

      @include desktop {
        height: calc(100% + 100px);
        bottom: 50px;
      }
    }

    .VideoWidget-Player {
      @include desktop {
        height: calc(100% + 100px);
        position: relative;
        bottom: 50px;
      }
    }

    .VideoWidget-Thumbnail {
      img {
        @include desktop {
          height: 100% !important;
        }
      }
    }

    .VideoWidget-content {
      @include desktop {
        text-align: center;
        width: 36%;
        padding: 0 46px;
      }

      .VideoWidget-title {
        margin: 20px 0 0;
        text-align: left;

        @include desktop {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.003em;
          text-align: center;
          margin: 0;
        }
      }

      .VideoWidget-description {
        margin-top: 6px;

        @include desktop {
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;
        }
      }

      .VideoWidget-cta {
        margin-top: 17px;
        display: inline-block;
        vertical-align: top;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
      }
    }
  }

  &-14 {
    margin-bottom: 40px;

    .ContentWrapper {
      @include mobile {
        padding: 0;
      }

      @include desktop {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
      }
    }

    .VideoWidget-playMobile {
      display: none;
    }

    .VideoWidget-Image {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .VideoWidget-Player {
      @include desktop {
        height: 100%;
        position: relative;
      }
    }

    .VideoWidget-Thumbnail {
      img {
        @include desktop {
          height: 100% !important;
        }
      }
    }

    .VideoWidget-content {
      @include desktop {
        width: 39%;
        padding: 0 57px;
      }

      .VideoWidget-title {
        margin: 20px 0 0;
        text-align: left;

        @include desktop {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.003em;
          margin: 0;
        }
      }

      .VideoWidget-description {
        margin-top: 20px;

        @include desktop {
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;
        }
      }

      .VideoWidget-cta {
        margin-top: 21px;
        display: inline-block;
        vertical-align: top;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
      }
    }
  }

  &-16,
  &-15 {
    @include desktop {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &-wrapper {
      background: var(--cms-block-bg);
      padding: 32px 0 50px;

      .bg_mode_active & {
        background: none;
      }
    }

    &_col {
      @include mobile {
        margin-bottom: 14px;
      }

      &:before {
        content: "";
        background: $foux;
        height: 100%;
        width: 15px;
        position: absolute;
        right: 43px;
        z-index: 1;
        bottom: 0;

        @include desktop {
          right: auto;
          left: 0;
          bottom: 29px;
          width: 100%;
          height: 15px;
        }
      }

      &:first-child {
        @include desktop {
          width: 195px;
          flex-shrink: 0;
        }

        @include wide-desktop {
          padding-right: 100px;
          width: 450px;
        }

        &:before {
          display: none;
        }
      }
    }

    &_logo {
      max-width: 259px;
      margin: 0 auto;
    }

    &_image {
      img {
        vertical-align: top;
      }
    }

    &_content {
      text-align: center;

      h2 {
        font-family: $font-BlackerProDisplay;
        color: var(--cms-block-fc);
        font-weight: 700;
        font-size: 46px;
        line-height: 60px;
        letter-spacing: -0.004em;
        margin-top: 15px;
        margin-bottom: 0;

        @include desktop {
          font-size: 32px;
          line-height: 40px;
        }

        @include wide-desktop {
          font-size: 63px;
          line-height: 80px;
        }
      }

      h3 {
        font-family: $font-BlackerProDisplay;
        color: var(--cms-block-fc);
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        letter-spacing: -0.004em;
        margin-top: 13px;
        margin-bottom: 0;

        @include desktop {
          font-size: 14px;
        }

        @include wide-desktop {
          font-size: 23px;
        }
      }

      a {
        background: $foux;
        color: $white;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        min-height: 48px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &_wholeLink {
      position: static;

      a {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
      }
    }

    &_bkg {
      position: absolute;
      bottom: -46px;
      right: -14px;
      width: auto !important;
      max-width: 100%;
      z-index: 1;

      @include desktop {
        bottom: -20px;
        right: 6%;
      }
    }
  }

  &-16 {
    display: block;

    .VideoWidget {
      &-VideoContainer {
        width: 51% !important;

        @include desktop {
          height: 100% !important;
          position: absolute;
          right: 0;
        }

        @include ultra-narrow-desktop {
          width: 100% !important;
          max-width: 100%;
          position: relative;
          height: var(--video-height) !important;
        }

        @include mobile {
          position: relative;
          width: 100% !important;
          left: 0;
          height: var(--video-height) !important;
          display: block;
        }
      }

      &-Image {
        height: 100%;
        overflow: hidden;
      }

      &-Thumbnail {
        position: static;

        img {
          position: absolute;
          object-fit: cover;
          object-position: 0 100%;
        }
      }

      &-content {
        max-width: 100%;

        @include desktop {
          min-height: var(--video-height);
          width: 49%;
        }

        @include ultra-narrow-desktop {
          padding: 25px 5%;
          min-height: 0;
          width: 100%;
        }
      }

      &-description,
      &-title {
        @include ultra-narrow-desktop {
          max-width: 100%;
        }
      }

      &-Wrapper {
        @include desktop {
          justify-content: flex-end;
        }

        @include ultra-narrow-desktop {
          display: block;
          padding: 0;
        }

        &:before {
          @include desktop {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  &-17 {
    &-title {}

    &-cols {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include desktop {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &-Image {
      img {
        vertical-align: top;
      }
    }
  }

  &-18 {
    &_cols {
      display: flex;
      flex-direction: column;

      @include desktop {
        flex-direction: row;
      }

      .reverse & {
        @include desktop {
          flex-direction: row-reverse;
        }
      }
    }
    &_title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: -0.2px;
      margin: 0 0 4px;

      @include desktop {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        margin-bottom: 8px;
      }
    }

    &_description {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.0008em;
      margin: 0 auto 4px;

      @include desktop {
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0;
        margin-bottom: 7px;
      }

      &.mobile {
        margin-bottom: 0;
      }
    }

    &_link {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-decoration: underline;
      display: inline-block;
    }

    &_slider {
      @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 50px;
        padding-right: 26px;
        width: 50%;
      }

      .reverse & {
        @include desktop {
          padding-left: 26px;
          padding-right: 50px;
        }
      }
    }

    &_banner {
      @include desktop {
        display: flex;
        align-items: center;
        width: 50%;
      }

      img {
        vertical-align: top;
      }
    }

    &_labels {
      display: flex;
      flex-wrap: wrap;
    }

    &_label {
      background: $black;
      color: $white;
      font-weight: 500;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px 20px;

      &.light {
        background: $greyF0F0F0;
        color: $black;
      }
    }

    &_top {
      padding: 20px 0;

      @include desktop {
        padding-left: 5px;
      }
    }

    .ProductCard {
      padding: 0 5px !important;
    }

    .slick-slider {
      @include mobile {
        padding-bottom: 35px;
      }
    }

    .slick-dots {
      @include desktop {
        display: none !important;
      }
    }
  }

  &-19 {
    &_banner {
      position: relative;
    }

    &_whole_link {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &_blocks {
      display: flex;
      flex-wrap: wrap;
    }

    &_block {
      width: 50%;
      padding-right: 10px;
      margin-bottom: 10px;

      @include desktop {
        width: 16.6%;
        padding-right: 30px;
        margin-bottom: 30px;
      }

      a {
        right: 30px;
      }
    }

    &_title {
      font-family: $font-blackerDisplay;
      padding: 0 3%;
      font-size: 50px;

      @include mobile {
        padding: 10px 0;
      }

      @include desktop {
        color: $white;
      }

      @include wide-desktop {
        font-size: 100px;
      }

      a {
        display: block;
        font-size: 50px;

        @include desktop {
          color: $white;
        }

        @include wide-desktop {
          font-size: 100px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &_label {
      font-weight: 500;
      margin-top: 10px;

      @include desktop {
        color: $white;
      }
    }

    img {
      vertical-align: top;
    }

    &_content {
      @include desktop {
        position: absolute;
        top: 50%;
        left: 5%;
        right: 5%;
        transform: translateY(-50%);
      }

      &.right {
        .beauty-cms_block-19 {
          &_title {
            text-align: right;
          }

          &_blocks {
            justify-content: flex-end;
          }

          &_block {
            padding-right: 0;
            padding-left: 10px;

            @include desktop {
              padding-left: 30px;
            }

            a {
              right: 0;
              left: 10px;

              @include desktop {
                left: 30px;
              }
            }
          }
        }
      }

      &.center {
        .beauty-cms_block-19 {
          &_title {
            text-align: center;
          }

          &_blocks {
            justify-content: center;

            @include mobile {
              margin: 0 -5px;
            }
          }

          &_block {
            padding-right: 5px;
            padding-left: 5px;

            @include desktop {
              padding-right: 15px;
              padding-left: 15px;
            }

            a {
              right: 5px;
              left: 5px;

              @include desktop {
                right: 15px;
                left: 15px;
              }
            }
          }
        }
      }
    }
  }

  &-20 {
    @include desktop {
        margin-bottom: 20px;
    }

    @include mobile {
        margin: 0 16px;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: none;
        }

        &::-webkit-scrollbar {
            height: 0;
            background-color: none;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: none;
        }
    }

    &_wrapper {
        display: flex;
        margin-left: -16px;
        margin-right: -16px;

        @include desktop {
          flex-wrap: wrap;
          justify-content: center;
          margin-left: -5px;
          margin-right: -5px;
        }

      @include ultra-wide-desktop {
        margin-left: -16px;
        margin-right: -16px;
      }
    }

    &_col {
        padding: 0 16px;

        @include desktop {
          width: 25%;
          padding: 0 5px;
        }

        @include mobile {
            width: 80%;
            flex-shrink: 0;
        }

      @include ultra-wide-desktop {
        padding: 0 16px;
      }

        &:first-child:last-child {
            @include mobile {
                width: 100%;
                padding: 0;
            }

            beauty-cms_block-20_content {
                @include mobile {
                    left: 0;
                    right: 0;
                }
            }
        }
    }

    &_content {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 0;
        text-align: center;

        @include desktop {
          left: 5px;
          right: 5px;
        }

      @include ultra-wide-desktop {
        left: 16px;
        right: 16px;
      }

        h2 {
            margin: 0;
            font-size: 24px;
            line-height: 1.1;
            padding: 15px 10px;
            background: rgba(255,255,255,0.4);

          @include desktop {
            padding: 10px 2px;
            font-size: 12px;
          }

            @include wide-desktop {
                font-size: 18px;
                padding: 15px 5px;
            }
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
  }
}

.cms_swiper_arrow {
  cursor: pointer;
  position: absolute;
  inset-block-end: calc(100% - 6px);
  width: 6px;
  height: 12px;

  @include desktop {
    inset-block-end: calc(100% - 23px);
    width: 17px;
    height: 46px;
  }

  .ChevronIcon {
    width: 100%;
    height: 100%;
  }

  &_isPrev {
    inset-inline-start: 16px;

    &:dir(ltr) {
      transform: rotate(180deg) scale(-1);
    }
  }

  &_isNext {
    inset-inline-end: 16px;

    &:dir(rtl) {
      transform: rotate(180deg) scale(-1);
    }
  }

  &_isDisabled {
    pointer-events: none;
    opacity: .5;
  }

  &s {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(50%);
  }
}
.cms_swiper_dot {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: $black;
  border-radius: 50%;
  border: 6px solid $white;

  @include desktop {
    width: 18px;
    height: 18px;
    border-width: 4px;
    margin: 0 3px;
  }

  &.is-active {
    box-shadow: 0 0 0 1px $black;
    border-width: 4px;
    margin-right: 6px;
    margin-left: 6px;
  }

  &s {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    z-index: 10;
    bottom: 10px;
    left: 0;
    width: 100%;
  }
}

.cms_block {
  &_bg_grey {
    background: $xryso;
  }

  &_link {
    color: var(--cms-block-fc);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: underline;

    &:hover {
      color: var(--cms-block-fc);
    }
  }

  &_title {
    color: var(--cms-block-fc);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.003em;

    &_container {
      display: flex;
      align-items: center;

      @include mobile {
        flex-direction: column;
      }
    }
  }
}

.HeroBrandPage{
  &-Slider_1 {
    margin: 0 8px 18px;

    @include desktop {
      margin: 0 -8px 32px;
    }

    .SliderWidget {
      &-Figures {
        @include tablet {
          display: grid !important;
          grid-template-columns: repeat(2, 1fr);
        }

        @include mobile {
          display: grid !important;
          grid-template-columns: repeat(2, 1fr);
        }

        .SliderWidget-FigureImage {
          height: auto;
        }
      }

      &-Figure {
        padding: 0 8px 12px;

        @include desktop {
          padding-bottom: 16px;
        }
      }

      &-FigureImage {
        padding-bottom: 100%;
        height: 0;

        .Image-Image {
          position: absolute;

        }
      }

      &-Figcaption {
        margin-bottom: 24px;
        margin-top: 12px;
        position: static;
        transform: none;

        @include desktop {
          margin-top: 16px;
        }

        h2 {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.0008em;
          margin: 0 0 4px;

          @include desktop {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            margin-bottom: 8px;
          }
        }

        p {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.0008em;
          margin: 0 0 12px;

          @include desktop {
            font-size: 14px;
            line-height: 22px;
          }
        }

        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-decoration: underline;

          @include mobile {
            font-size: 13px;
            word-break: break-all;
          }
        }
      }
    }
  }

  &-Slider_2 {
    margin-bottom: 24px;

    @include desktop {
      margin-bottom: 18px;
    }

    .SliderWidget-Figcaption {
      max-width: 327px;
      margin: 0 auto;
      text-align: center;
      transform: none;
      top: auto;
      right: 0;
      bottom: 4px;

      @include desktop {
        right: auto;
        bottom: auto;
        top: 60%;
        left: 2% !important;
        transform: translateY(-50%);
      }

      h2 {
        color: $white;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        margin: 0 0 20px;

        @include desktop {
          font-size: 32px;
        }
      }

      a {
        color: $white;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        margin-bottom: 18px;
        display: inline-block;
      }
    }
  }

  &-Slider_3 {
    margin-bottom: 25px;

    @include desktop {
      margin-bottom: 54px;
    }

    .ProductCard-Promo & {
      margin-bottom: 0;
    }

    .SliderWidget-Figure {
      padding-bottom: 25px;

      @include desktop {
        padding-bottom: 0;
      }
    }

    .SliderWidget-FigureImage {
      @include ultra-narrow-desktop {
        position: static;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          object-fit: cover;
          font-family: 'object-fit: cover;';
          width: 0;
          min-width: 100%;
          min-height: 100%;
        }
      }
    }

    .SliderWidget-Figcaption {
      background: $white;
      left: 16px !important;
      right: 16px;
      top: auto;
      bottom: 0;
      transform: none;
      padding: 13px 24px;
      width: auto;
      text-align: center;

      @include desktop {
        bottom: 36px;
        top: 36px;
        left: auto !important;
        right: -41px;
        padding: 15px 52px 15px 81px;
        max-width: 528px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @include ultra-narrow-desktop {
        position: relative;
        right: auto;
        left: 0 !important;
        top: auto;
        bottom: auto;
        margin-top: 36px;
        margin-bottom: 36px;
        padding: 15px 26px 15px 40px;
      }

      h2 {
        font-family: $font-BlackerProDisplay;
        font-weight: 700;
        font-size: 32px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: 0 0 15px;

        @include wide-desktop {
          font-weight: 700;
          font-size: 40px;
          line-height: 56px;
          letter-spacing: 0.02em;
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin: 0;

        @include desktop {
          font-size: 14px;
          line-height: 22px;
        }

        &:not(:last-child) {
          margin: 0 0 20px;

          @include desktop {
            margin-bottom: 33px;
          }
        }
      }

      a {
        background: $black;
        color: $white;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        padding: 13px 28px;
        display: inline-block;

        @include desktop {
          padding: 13px 14px;
        }
      }
    }
  }

  &-Slider_chanel {
    margin-bottom: 40px;

    &.slick-slider {
      .slick-dots {
        position: static;
        margin-top: 25px;
      }

      .slick-arrow {
        width: 46px;
        z-index: 1;
        background: rgba(0,0,0,.3);
      }

      .slick-next {
        right: 0;

        &:before,
        &:after {
          background: $white;
          left: 25px;
          width: 1px;
        }
      }

      .slick-prev {
        left: 0;

        &:before,
        &:after {
          background: $white;
          left: auto;
          right: 25px;
          width: 1px;
        }
      }
    }
  }

  &__product_slider{
    &.slick-slider {
      margin: 0 8px 23px;

      @include desktop {
        margin: 0 -12px 28px;
      }

      .slick-prev {
        @include desktop {
          left: -16px;
        }
      }

      .slick-next {
        @include desktop {
          right: -16px;
        }
      }

      .slick-dots {
        position: static;
        margin-top: 15px;

        @include desktop {
          margin-top: 38px;
        }
      }

      .ProductCard {
        padding: 0 8px;

        @include desktop {
          padding: 0 12px;
        }
      }
    }
  }

  .beauty-cms_block-6 {
    @include mobile {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    &:before {
      content: "";
      background: $greyF8F8F8;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      display: block;
      width: 10000px;
      transform: translateX(-5000px);
    }

    &_title_container {
      &:before {
        content: "";
        background: $greyF8F8F8;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        display: block;
        width: 10000px;
        transform: translateX(-5000px);
      }
    }

    &.Layout1 {
      margin-bottom: 25px;

      @include desktop {
        margin-bottom: 32px;
      }

      .post-holder {
        @include mobile {
          margin-bottom: 42px;
        }
      }

      &.slick-slider .slick-dots {
        @include mobile {
          bottom: 25px;
        }
      }
    }
  }

  &-Dior-Slider_1 {
    padding: 0 16px;

    @include desktop {
      padding: 0;
      margin-bottom: 22px;
    }

    &.slick-slider {
      .slick-arrow {
        display: none !important;
      }

      .slick-track {
        transform: none !important;
        display: grid;

        @include desktop {
          grid-template-columns: calc(65% - 17px) calc(35.4% - 17px);
          grid-column-gap: 34px;
        }

        @include wide-desktop {
          grid-template-columns: calc(64.51% - 16px) calc(35.5% - 16px);
          grid-column-gap: 32px;
        }

        &:before,
        &:after {
          display: none;
        }
      }

      .slick-slide {
        float: none;
        width: 100% !important;
      }
    }

    .SliderWidget-Figcaption {
      position: static;
      transform: none;
      padding: 14px 0 22px;

      h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin: 0 0 8px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        position: static;
      }

      a {
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  &-Dior-Slider_2 {
    margin-bottom: 9px;

    @include desktop {
      margin-bottom: 29px;
    }

    &.slick-slider {
      .slick-arrow {
        display: none !important;
      }

      .slick-track {
        transform: none !important;

        &:before,
        &:after {
          display: none;
        }
      }

      .slick-slide {
        float: none;
        width: 100% !important;
      }
    }

    .SliderWidget-FigureImage {
      @include ultra-narrow-desktop {
        min-height: 263px;

        img {
          object-fit: cover;
          position: absolute;
        }
      }
    }

    .SliderWidget-Figcaption {
      text-align: center;
      top: auto;
      bottom: 19%;
      transform: none;

      @include desktop {
        bottom: 8%;
      }

      h2 {
        font-family: $font-BlackerProDisplay;
        color: $white;
        font-weight: 700;
        font-size: 44px;
        line-height: 48px;
        letter-spacing: -0.004em;
        margin: 0;

        @include desktop {
          margin-bottom: 15px;
        }
      }

      p {
        color: $white;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        margin: 0;
      }

      a {
        color: $white;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-top: 12px;
        text-decoration: underline;
        display: inline-block;

        @include desktop {
          margin-top: 5.4%;
        }
      }
    }
  }

  &-Dior-Slider_3 {
    margin-bottom: 44px;

    @include desktop {
      margin-bottom: 54px;
    }

    &.slick-slider {
      .slick-arrow {
        display: none !important;
      }

      .slick-track {
        transform: none !important;

        &:before,
        &:after {
          display: none;
        }
      }

      .slick-slide {
        float: none;
        width: 100% !important;
      }
    }

    .SliderWidget-Figure {
      @include ultra-narrow-desktop {
        text-align: right;
      }
    }

    .SliderWidget-FigureImage {
      @include mobile {
        z-index: -1;
      }

      @include ultra-narrow-desktop {
        position: static;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          object-fit: cover;
          font-family: 'object-fit: cover;';
          width: 0;
          min-width: 100%;
          min-height: 100%;
        }
      }
    }

    .SliderWidget-Figcaption {
      padding: 8px;
      background: $white;

      @include mobile {
        margin: -20px 16px 0;
        position: static;
        transform: none;
        width: auto;
        z-index: 1;
      }

      @include desktop {
        bottom: 36px;
        top: 36px;
        left: auto !important;
        right: -41px;
        padding: 15px 52px 15px 81px;
        max-width: 528px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: none;
      }

      @include ultra-narrow-desktop {
        position: relative;
        right: 0;
        top: auto;
        bottom: auto;
        margin-top: 36px;
        margin-bottom: 36px;
        padding: 15px 26px 15px 40px;
        display: inline-block;
        vertical-align: top;
      }

      h2 {
        font-family: $font-BlackerProDisplay;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.004em;
        margin: 0 0 16px;

        @include desktop {
          line-height: 45px;
          letter-spacing: 0.02em;
          margin-bottom: 16px;
        }
      }

      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: 0 0 8px;
      }

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
      }

      a {
        background: $black;
        color: $white;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 48px;
        margin-top: 24px;

        @include desktop {
          max-width: 253px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-Dior-Backstage{
    &-Slider_1 {
      padding: 0 16px;
      margin-bottom: 18px;

      @include desktop {
        padding: 0;
        margin-bottom: 22px;
      }

      &.slick-slider {
        .slick-arrow {
          display: none !important;
        }

        .slick-track {
          transform: none !important;
          display: grid;

          @include desktop {
            grid-template-columns: calc(61.3% - 8px) calc(38.7% - 8px);
            grid-column-gap: 16px;
          }

          @include wide-desktop {
            grid-template-columns: calc(61.18% - 9px) calc(38.65% - 9px);
            grid-column-gap: 18px;
          }

          &:before,
          &:after {
            display: none;
          }
        }

        .slick-slide {
          float: none;
          width: 100% !important;
          margin-bottom: 20px;
          height: auto;

          > div {
            vertical-align: top;
          }
        }
      }
    }

    &-Video {
      @include desktop {
        overflow: hidden;
      }

      &:before {
        content: "";
        background: $greyF8F8F8;
        position: absolute;
        left: 0;
        right: 0;
        top: 11px;
        bottom: 0;
        display: block;
        width: 10000px;

        @include desktop {
          top: 105px;
        }

        @include tablet {
          top: 55px;
        }
      }

      .VideoWidget-Wrapper {
        @include wide-desktop {
          padding-inline: 124px;
        }
      }

      .VideoWidget-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        padding: 16px 0 12px;
        margin: 0;

        @include wide-desktop {
          font-size: 24px;
          line-height: 32px;
          padding: 28px 0 40px;
        }
      }

      .VideoWidget-playMobile,
      .VideoWidget-description {
        display: none;
      }
    }
  }

  &-Bottom-section {
    &.slick-slider {
      .slick-arrow {
        display: none !important;
      }

      .slick-track {
        transform: none !important;
        display: grid;

        @include desktop {
          grid-template-columns: repeat(2,1fr);
          grid-column-gap: 30px;
        }

        @include wide-desktop {
          grid-column-gap: 40px;
        }

        &:before,
        &:after {
          display: none;
        }
      }

      .slick-slide {
        float: none;
        width: 100% !important;
      }
    }

    .SliderWidget-Figure {
      padding-bottom: 32px;
      margin-bottom: 7px;

      @include wide-desktop {
        padding-bottom: 39px;
        margin-bottom: 52px;
      }
    }

    .SliderWidget-Figcaption {
      background: $white;
      position: absolute;
      left: 16px !important;
      right: 16px;
      bottom: 0;
      padding: 12px 24px 20px;
      width: auto;
      transform: none;
      top: auto;
      text-align: center;

      @include wide-desktop {
        left: 34px !important;
        right: 34px;
        padding: 17px 30px 10px;
      }

      h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: 0 0 4px;

        @include wide-desktop {
          font-size: 20px;
          line-height: 28px;
          margin: 0;
        }
      }

      p {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @include wide-desktop {
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
        }

        &:not(:last-child) {
          margin-bottom: 9px;
        }
      }

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
      }
    }
  }

  .beauty-cms_block-6_title_container {
    &.Layout1 {
      @include mobile {
        padding: 25px 0 16px;
      }
    }
  }

  &__Shadefinder_block {
    @include mobile {
      margin: 0 -14px 35px;
    }

    @include desktop {
      padding: 0 16px;
      margin-bottom: 38px;
    }

    @include wide-desktop {
      padding-inline: 11.2%;
    }

    > div {
      border-bottom: 1px solid $greyD0D0D0;
      padding-bottom: 33px;

      @include wide-desktop {
        padding-bottom: 77px;
      }
    }

    #gdb_shadefiner {
      margin-bottom: 0;
    }
  }

  &__Product_block {
    margin-bottom: 32px;

    @include wide-desktop {
      margin-bottom: 82px;
    }

    &_title {
      background: $black;
      color: $white;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.2px;
      padding: 23px 14px 41px;
      margin: 0 -14px -41px;
      min-height: 128px;
      display: flex;
      align-items: flex-start;

      @include desktop {
        align-items: flex-end;
        font-size: 34px;
        line-height: 44px;
        letter-spacing: -0.003em;
        padding: 58px 16px;
        min-height: 205px;
        margin: 0 0 -30px;
      }

      @include tablet {
        padding: 23px 16px 41px;
        margin: 0 0 -41px;
        min-height: 128px;
      }

      @include wide-desktop {
        padding-inline: 11.2%;
      }

      &_opposite {
        @include desktop {
          text-align: right;
          justify-content: flex-end;
        }
      }

      &_main {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        margin-bottom: 19px;
        text-align: center;

        @include desktop {
          padding: 0 16px;
        }

        @include wide-desktop {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.003em;
          margin-bottom: 29px;
          padding-inline: 11.2%;
        }
      }
    }

    &_container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include desktop {
        flex-direction: row;
        padding: 0 16px;
      }

      @include tablet {
        flex-direction: column;
      }

      @include wide-desktop {
        gap: 16.5%;
        padding: 0 11.2%;
      }

      &_opposite {
        @include desktop {
          flex-direction: row-reverse;
        }
      }
    }

    &_banner {
      @include desktop {
        width: 55.3%;
        flex-shrink: 0;
      }

      @include tablet {
        width: 100%;
      }

      img {
        @include desktop {
          height: auto !important;
        }
      }
    }

    &_item {
      @include desktop {
        padding-top: 40px;
      }

      @include tablet {
        padding-top: 0;
      }

      @include wide-desktop {
        padding-top: 76px;
      }
    }

    &_step {
      color: $grey727272;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      margin-bottom: 4px;

      @include wide-desktop {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 8px;
      }
    }

    &_name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      margin-bottom: 16px;

      @include wide-desktop {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
      }
    }

    &_description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
      margin-bottom: 8px;

      @include wide-desktop {
        margin-bottom: 16px;
      }
    }

    &_widget {
      .ProductListWidget {
        padding-bottom: 16px;

        &-Page {
          display: block;
          padding: 0;
        }
      }

      .ProductCard {
        margin-bottom: 0 !important;

        &-Figure {
          text-align: center;
        }

        &-FigureReview {
          padding-bottom: 16px;
        }

        &-Picture {
          max-width: 254px;
          padding-bottom: 68%;
        }

        &-Content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 16px;
          align-items: center;
        }

        &-ProductConfigurableAttributes,
        &-Reviews,
        &-Brand {
          display: none !important;
        }

        &-Name {
          height: auto;
          margin: 0;
          width: auto;

          &:after {
            content: "-";
            margin: 0 3px;
          }
        }

        &-Price {
          width: auto;
          min-height: 0;
        }

        &-PriceWrapper {
          min-height: 0;

          .ProductPrice {
            del,
            &-DiscountPercentage {
              display: none !important;
            }

            &-Price {
              margin: 0;
            }
          }
        }

        .ProductWishlistButton {
          display: none !important;
        }

        .AddToCart.Button {
          background: $black;
          color: $white;

          &:not([disabled]):hover {
            background-color: $white;
            color: $black;
          }
        }
      }
    }

    &_howtouse {
      width: 100%;

      a {
        width: 100%;
      }
    }
  }

  &__List_block {
    margin-bottom: 6px;

    @include desktop {
      margin-bottom: 15px;
    }

    @include tablet {
      margin-bottom: 0;
    }

    &_title {
      background: $black;
      color: $white;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.2px;
      padding: 23px 14px 41px;
      margin: 0 -14px -41px;
      min-height: 128px;
      display: flex;
      align-items: flex-start;

      @include desktop {
        align-items: flex-end;
        font-size: 34px;
        line-height: 44px;
        letter-spacing: -0.003em;
        padding: 58px 16px;
        min-height: 205px;
        margin: 0 0 -30px;
      }

      @include tablet {
        padding: 23px 16px 41px;
        margin: 0 0 -41px;
        min-height: 128px;
      }

      @include wide-desktop {
        padding-inline: 11.2%;
      }

      &_opposite {
        @include desktop {
          text-align: right;
          justify-content: flex-end;
        }
      }
    }

    &_container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include wide-desktop {
        gap: 63px;
      }

      &_opposite {
        @include desktop {
          flex-direction: row-reverse;
        }
      }
    }

    &_banner {
      width: 100%;

      @include desktop {
        padding: 0 16px;
      }

      @include wide-desktop {
        padding: 0 11.2%;
      }

      img {
        @include desktop {
          height: auto !important;
        }
      }
    }

    &_item {
      @include mobile {
        margin: 0 -14px;
      }
    }
  }

  &__Banner_block {
    margin-bottom: 73px;

    @include wide-desktop {
      margin-bottom: 156px;
    }

    &_container {
      display: flex;
      flex-direction: column;
      gap: 39px;

      @include desktop {
        gap: 27px;
      }
    }

    &_banner {
      width: 100%;

      @include desktop {
        padding: 0 16px;
      }

      @include wide-desktop {
        padding: 0 11.2%;
      }

      img {
        @include desktop {
          height: auto !important;
        }
      }
    }

    &_link {
      text-align: center;

      .Button {
        width: 100%;

        @include desktop {
          max-width: 289px;
        }
      }
    }
  }

  &__Category_block {
    display: flex;
    flex-direction: column;
    margin: 0 -14px 28px;
    gap: 14px;

    @include desktop {
      flex-direction: row;
      align-items: center;
      margin: 0 0 33px;
    }

    @include tablet {
      flex-direction: column;
    }

    &_reivew {
      background: $greyF8F8F8;
      padding: 36px 47px 45px 60px;

      @include desktop {
        width: 45%;
        max-width: 358px;
        flex-shrink: 0;
      }

      @include tablet {
        width: 100%;
        max-width: 100%;
      }

      @include wide-desktop {
        padding-top: 90px;
        padding-bottom: 85px;
      }

      &_text {
        font-family: $font-BlackerProDisplay;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -0.003em;

        &:after,
        &:before {
          content: '';
          width: 35px;
          height: 24px;
          background-image: url("/style/icons/quotation.png");
          background-size: 100% auto;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 0;
        }

        &:before {
          margin: -5px 0 0 -20px;
        }

        &:after {
          margin: 10px 0 0 -12px;
        }

        span {
          position: relative;
          z-index: 1;
        }
      }

      &_author {
        border-top: 1px solid $greyD0D0D0;
        margin-top: 23px;
        padding-top: 19px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.0008em;
      }
    }

    &_items {
      display: flex;

      @include wide-desktop {
        gap: 25px;
      }
    }

    &_item {
      width: 33.3333%;
      text-align: center;

      @include wide-desktop {
        width: calc(33.3333% - 17px);
      }

      img {
        max-width: 100%;
        width: auto !important;
        height: auto !important;
      }

      &_name {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;

        @include wide-desktop {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.2px;
        }
      }
    }
  }
}

.blog_main_page {
  &_top {
    margin: 0 -14px;

    @include desktop {
      margin: 0;
    }

    &.slick-slider {
      .slick-arrow {
        display: none !important;
      }

      .slick-track {
        transform: none !important;
        width: 100% !important;

        &:before,
        &:after {
          display: none;
        }
      }

      .slick-slide {
        float: none;
        width: 100% !important;
      }
    }

    .SliderWidget-Figure {
      @include ultra-narrow-desktop {
        text-align: right;
      }
    }

    .SliderWidget-FigureImage {
      @include mobile {
        z-index: -1;
      }

      @include ultra-narrow-desktop {
        position: static;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          object-fit: cover;
          font-family: 'object-fit: cover;';
          width: 0;
          min-width: 100%;
          min-height: 100%;
        }
      }
    }

    .SliderWidget-Figcaption {
      padding: 17px 32px 32px;
      background: $white;

      @include mobile {
        margin: -36px 14px 0;
        position: static;
        transform: none;
        width: auto;
        z-index: 1;
      }

      @include desktop {
        bottom: 11%;
        top: 11%;
        left: auto !important;
        right: -41px;
        padding: 15px 63px;
        max-width: 557px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: none;
      }

      @include narrow-desktop {
        top: 25px;
        bottom: 25px;
      }

      @include ultra-narrow-desktop {
        position: relative;
        right: 0;
        top: auto;
        bottom: auto;
        margin-top: 36px;
        margin-left: 114px;
        margin-bottom: 36px;
        padding: 15px 26px 15px 40px;
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 100%;
      }

      @media (min-width: 811px) and (max-width: 1650px) {
        top: 15px;
        bottom: 15px;
      }

      h2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0;
      }

      h3 {
        font-family: $font-BlackerProDisplay;
        font-weight: 700;
        font-size: 34px;
        line-height: 49px;
        letter-spacing: -0.004em;
        margin: 0 0 10px;

        @include desktop {
          font-size: 44px;
          margin-bottom: 16px;
        }

        @media (min-width: 811px) and (max-width: 1650px) {
          font-size: 34px;
          line-height: 36px;
        }
      }

      h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0 0 17px;
        top: 0;

        @include desktop {
          margin-bottom: 24px;
        }
      }

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
      }

      a {
        background: $black;
        color: $white;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 48px;

        @include desktop {
          display: inline-flex;
          padding: 0 16px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &_single_banner {
    margin: 16px -14px 0;

    @include desktop {
      margin: 5px 0 0;
    }

    &.slick-slider {
      .slick-arrow {
        display: none !important;
      }

      .slick-track {
        transform: none !important;
        width: 100% !important;

        &:before,
        &:after {
          display: none;
        }
      }

      .slick-slide {
        float: none;
        width: 100% !important;
      }
    }

    .SliderWidget-Figure {
      @include ultra-narrow-desktop {
        text-align: right;
      }
    }

    .SliderWidget-FigureImage {
      @include mobile {
        z-index: -1;
      }

      @include ultra-narrow-desktop {
        position: static;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          object-fit: cover;
          font-family: 'object-fit: cover;';
          width: 0;
          min-width: 100%;
          min-height: 100%;
        }
      }

      .Image-Image {
        @include desktop {
          width: auto !important;
        }
      }
    }

    .SliderWidget-Figcaption {
      padding: 17px 32px 32px;
      background: $white;

      @include mobile {
        margin: -36px 14px 0;
        position: static;
        transform: none;
        width: auto;
        z-index: 1;
      }

      @include desktop {
        bottom: 11%;
        top: 11%;
        left: auto !important;
        right: -41px;
        padding: 15px 63px;
        max-width: 607px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: none;
      }

      @include narrow-desktop {
        top: 25px;
        bottom: 25px;
      }

      @include ultra-narrow-desktop {
        position: relative;
        right: 0;
        top: auto;
        bottom: auto;
        margin-top: 36px;
        margin-left: 114px;
        margin-bottom: 36px;
        padding: 15px 26px 15px 40px;
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 100%;
      }

      h2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0;
      }

      h1,
      h3 {
        font-family: $font-BlackerProDisplay;
        font-weight: 700;
        font-size: 34px;
        line-height: 49px;
        letter-spacing: -0.004em;
        margin: 0 0 10px;

        @include desktop {
          font-size: 44px;
          margin-bottom: 16px;
        }
      }

      h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0 0 17px;
        top: 0;

        @include desktop {
          margin-bottom: 24px;
        }
      }

      p {
        margin: 0;
      }

      a {
        background: $black;
        color: $white;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 48px;

        @include desktop {
          display: inline-flex;
          padding: 0 16px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &_recent_post {
    &_title {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.2px;
      margin: 22px 0 10px;
      text-align: center;

      @include desktop {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        margin: 32px 0 12px;
      }
    }

    .SliderWidget-Figure {
      &:hover {
        text-decoration: none;
      }
    }

    .SliderWidget-Figcaption {
      padding: 18px 0;
      position: static;
      transform: none;

      @include desktop {
        padding: 27px 0;
      }

      h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin: 0;
        min-height: 52px;

        @include desktop {
          margin-bottom: 6px;
          min-height: 0;
        }
      }

      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0;
      }
    }

    .slick-list {
      margin: 0 -7px;

      @include desktop {
        margin: 0 -8px;
      }
    }

    .slick-track {
      display: flex;
      flex-wrap: wrap;
      width: 100% !important;
    }

    .slick-slide {
      width: calc(50%) !important;
      padding: 0 7px;

      @include desktop {
        width: 33.3333% !important;
        padding: 0 8px;
      }

      &:last-child {
        width: 100% !important;

        @include desktop {
          width: 33.3333% !important;
        }

        .SliderWidget-Figcaption h2 {
          min-height: 0;
        }
      }
    }
  }

  &_two_banner {
    margin: 0 -14px;

    @include desktop {
      margin: 32px -8px 0;
    }

    .SliderWidget-Figure {
      @include desktop {
        padding: 0 8px;
      }
    }

    .SliderWidget-FigureImage {
      z-index: -1;
    }

    .SliderWidget-Figcaption {
      position: static;
      transform: none;
      padding: 20px 33px 26px;
      text-align: center;
      background: $white;
      margin: -43px 16px 0;
      width: calc(100% - 32px);

      @include desktop {
        margin: -75px 50px 0;
        width: calc(100% - 100px);
      }

      @include ultra-narrow-desktop {
        margin: -75px 20px 0;
        width: calc(100% - 40px);
        padding-inline: 20px;
      }

      h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        margin: 0 0 9px;
      }

      h3 {
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 20px;
      }

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
      }

      p {
        margin: 0;
      }
    }

    .slick-slide {
      width: 100% !important;

      @include desktop {
        width: 50% !important;
      }
    }
  }

  &_three_banner {
    margin-top: 22px;

    @include desktop {
      margin-top: 32px;
    }

    &_title {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.2px;
      margin: 22px 0 10px;
      text-align: center;

      @include desktop {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        margin: 32px 0 12px;
      }
    }

    .SliderWidget-Figure {
      &:hover {
        text-decoration: none;
      }
    }

    .SliderWidget-Figcaption {
      padding: 18px 0;
      position: static;
      transform: none;

      @include desktop {
        padding: 27px 0;
      }

      h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.2px;
        margin: 0;
        min-height: 42px;
      }

      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0;
      }
    }

    .slick-list {
      margin: 0 -7px;

      @include desktop {
        margin: 0 -8px;
      }
    }

    .slick-track {
      display: flex;
      flex-wrap: wrap;
      width: 100% !important;
    }

    .slick-slide {
      width: calc(50%) !important;
      padding: 0 7px;

      @media (min-width: 481px) {
        width: 33.3333% !important;
        padding: 0 8px;
      }

      &:first-child {
        width: 100% !important;

        @media (min-width: 481px) {
          width: 33.3333% !important;
        }

        .SliderWidget-Figcaption h2 {
          @media (max-width: 480px) {
            min-height: 0;
          }
        }
      }
    }
  }

  &_hc_insider {
    &_title {
      color: $color-grey-A1;
      font-family: $font-blackerProDisMd;
      font-size: 63px;
      line-height: 91px;
      letter-spacing: 0.055em;
      white-space: nowrap;
      z-index: 1;
      opacity: 0.18;

      @include desktop {
        font-size: 180px;
        line-height: 259px;
        left: -60px;
      }

      @include tablet {
        font-size: 150px;
      }

      @media (min-width: 1450px) {
        left: -10%;
      }

      &_container {
        margin-left: -14px;
        margin-right: -14px;
        padding-right: 14px;
        padding-left: 14px;
        display: block;

        @include desktop {

        }

        &:before {
          content: "";
          background: $black;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: calc(100% - 52px);
          display: block;
          width: 10000px;
          transform: translateX(-5000px);
          z-index: 0;

          @include desktop {
            height: calc(100% - 144px);
          }
        }
      }
    }

    &_wholeLink {
      position: static;

      a {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;

        @include desktop {
          left: 8px;
          right: 8px;
        }
      }
    }

    &_content {
      padding: 20px 0;

      @include desktop {
        padding-bottom: 54px;
      }

      h2 {
        color: $white;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0 0 6px;

        @include ultra-narrow-desktop {
          font-size: 12px;
          line-height: 20px;
        }
      }

      h3 {
        color: $white;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: 0 0 17px;

        @include ultra-narrow-desktop {
          font-size: 14px;
          line-height: 20px;
        }

        * {
          color: $white;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.2px;
          margin: 0;

          @include ultra-narrow-desktop {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      h4 {
        color: $color-grey-A1;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0;
      }
    }

    &.slick-slider {
      padding-top: 39px;

      @include desktop {
        display: flex;
        margin: 0 -8px;
        padding-top: 16px;
      }

      &.slick-initialized {
        @include desktop {
          display: block;
        }

        .post-holder {
          @include desktop {
            width: 100%;
          }
        }
      }

      &:before {
        content: "";
        background: $black;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        display: block;
        width: 10000px;
        transform: translateX(-5000px);
      }

      .slick-dots {
        position: static;
        padding-bottom: 50px;

        li {
          button:before {
            background: $white;
            color: $white;
          }

          &.slick-active button {
            border-color: $white;
          }
        }
      }
    }

    .post-holder {
      @include desktop {
        padding: 0 8px;
        width: 25%;
      }
    }

    .SliderWidget-Figcaption {
      position: static;
      transform: none;
    }

    .SliderWidget-FigureImage {
      height: auto;
    }
  }
}

.blog_detail_page {
  &_article_zigzag {
    @include desktop {
      margin: 92px -40px 0;
    }

    &.slick-slider {
      .slick-track {
        display: flex;
        flex-direction: column;
        transform: none;
        gap: 12px;

        @include desktop {
          gap: 0;
        }
      }

      .slick-slide {
        width: 100% !important;

        &:nth-child(2n+1) {
          .SliderWidget-Figure {
            @include desktop {
              flex-direction: row;
            }
          }
        }
      }
    }

    .SliderWidget-Figure {
      display: flex !important;
      flex-direction: column;

      @include desktop {
        flex-direction: row-reverse;
        align-items: center;
        gap: 12%;
      }

      @include tablet {
        gap: 6%;
      }
    }

    .SliderWidget-FigureImage {
      @include desktop {
        width: 39.3%;
        flex-shrink: 0;
      }

      @include tablet {
        width: 48%;
      }
    }

    .SliderWidget-Figcaption {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
      position: static;
      transform: none;
      padding: 18px 12px;

      @include desktop {
        padding: 18px 0;
      }

      h2 {
        font-family: $font-BlackerProDisplay;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.004em;
        margin: 0 0 4px;

        @include ultra-wide-desktop {
          font-size: 28px;
          line-height: 40px;
          margin-bottom: 32px;
        }

        @include tablet {
          margin-bottom: 16px;
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        + p {
          a {
            margin-top: 0;
          }
        }
      }

      a {
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0;
        margin-top: 15px;
        text-decoration: underline;

        @include desktop {
          margin-top: 11px;
        }
      }
    }
  }
}

.career_page {
  &-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.2px;
    padding-top: 21px;
    margin-bottom: 12px;

    @include desktop {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.003em;
      padding-top: 10px;
    }
  }

  &-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.0008em;
    margin-bottom: 17px;

    @include desktop {
      max-width: 958px;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      margin-bottom: 42px;
    }
  }

  &_four_block {
    .SliderWidget-Figcaption {
      position: static;
      transform: none;
      padding: 28px 14px;

      @include desktop {
        padding: 34px 27px;
      }

      h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin: 0;
        text-align: left;

        + h3 {
          margin-top: 27px;
        }
      }

      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0;
        text-align: left;
      }
    }

    &.slick-slider {
      padding: 23px 0 27px;
      position: relative;
      margin-bottom: 30px;

      @include desktop {
        padding: 41px 0 47px;
        margin-bottom: 47px;
      }

      &:before {
        content: "";
        background: $icegrey;
        position: absolute;
        width: 10000px;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .slick-track {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        transform: none;

        @include desktop {
          grid-template-columns: repeat(4, 1fr);
          gap: 24px;
        }

        &:before,
        &:after {
          display: none;
        }
      }

      .slick-slide {
        background: $white;
        border: 1px solid $color-grey-E5;
        width: auto !important;
      }
    }
  }

  &-values {
    &_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      border-top: 1px solid $grey;
      padding-top: 35px;
      text-align: center;
      max-width: 556px;
      margin: 0 auto 11px;

      @include desktop {
        border-top: 0 none;
        padding-top: 0;
      }
    }

    &_description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
      text-align: center;
      max-width: 556px;
      margin: 0 auto 13px;
    }
  }

  &.blog_main_page_single_banner {
    @include desktop {
      margin-bottom: 47px;
    }

    .SliderWidget-Figcaption {
      @include mobile {
        padding-bottom: 8px;
      }
    }
  }

  &-contact {
    @include desktop {
      display: flex;
      flex-direction: row-reverse;
      gap: 10%;
    }

    &_form {
      @include desktop {
        width: 33%;
      }

      &_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        text-align: center;
      }

      &_content {
        max-width: 100%;

        .Field {
          margin-top: 15px;

          @include desktop {
            margin-top: 0;
          }
        }

        .inputbox {
          @include desktop {
            margin-top: 15px;
          }

          &-row-top {
            @include desktop {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-column-gap: 15px;
            }

            @include ultra-narrow-desktop {
              display: block;
            }
          }

          &-full {
            @include desktop {
              grid-column: 1/-1;
            }
          }
        }

        textarea {
          @include desktop {
            width: 100%;
            vertical-align: top;
          }
        }

        .Button {
          width: 100%;
        }
      }
    }

    &_banner {
      position: relative;
      margin: 0 -14px 30px;

      @include desktop {
        margin: 0;
        width: 57%;
      }
    }

    &_content {
      background: $white;
      padding: 11px 22px;
      margin: -23px 14px 0;

      @include desktop {
        margin: -134px 14% 0;
        padding: 7% 10%;
      }
    }

    &_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      margin-bottom: 11px;
    }

    &_description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
    }
  }
}

.giftcard {
  &_main_banner {
    margin-bottom: 50px;

    @include desktop {
      margin-bottom: 150px;
    }

    &_content {
      text-align: center;

      @include desktop {
        display: inline-block;
      }

      h1 {
        font-family: $font-blackerDisplay;
        text-shadow: white 1px 0 5px;

        @include wide-desktop {
          font-size: 42px;
        }
      }

      h4 {
        font-size: 24px;
        line-height: normal;
        font-weight: 500;
        text-shadow: white 1px 0 5px;
      }
    }

    img {
      vertical-align: top;
    }

    .ContentWrapper {
      @include desktop {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      @include mobile {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  &_page_single_banner {
    &.blog_main_page_single_banner {
      margin-top: 0;
      margin-bottom: 50px;

      @include desktop {
        margin-bottom: 150px;
      }
    }
    .SliderWidget {
      &-FigureImage {
        display: inline-block;
        height: 100%;
        vertical-align: top;

        img {
          position: static;
          display: block;

          @include desktop {
            width: auto !important;
          }
        }
      }

      &-Figcaption {
        @include desktop {
          position: absolute;
        }
      }
    }

    &.giftcard_page_single_banner_reverse {
      .SliderWidget {
        &-FigureImage {
          img {
            @include desktop {
              margin-left: auto;
            }
          }
        }

        &-Figcaption {
          @include desktop {
            left: -41px !important;
            right: auto;
          }

          @include ultra-narrow-desktop {
            margin-left: 0;
            margin-right: 114px;
            left: 0 !important;
          }
        }
      }
    }
  }

  &_block_4 {
    padding-top: 50px;

    @include desktop {
      display: flex;
      align-items: center;
      gap: 16px;
      padding-bottom: 129px;
      padding-top: 129px;

      .giftcard_block_4-wrapper2 & {
        padding-left: 48px;
        padding-right: 48px;
        padding-bottom: 80px;
      }
    }

    .giftcard_block_4-wrapper2 & {
      @include mobile {
        display: grid;
        grid-template-columns: calc(50% - 7px) auto;
        gap: 14px;
      }
    }

    &-wrapper {
      background: var(--cms-block-bg);
      padding: 0 0 10px;
      margin-bottom: 50px;

      @include desktop {
        margin-bottom: 150px;
        margin-top: 200px;
        padding-bottom: 0;
      }

      &.giftcard_block_4-wrapper2 {
        background-image: var(--cms-block-bg-image);
        background-size: 100% 100%;

        @include mobile {
          background-image: var(--cms-block-bg-image-mobile);
          margin-left: -14px;
          margin-right: -14px;
        }
      }
    }

    &_title {
      color: $greyF2F2F2;
      padding: 0;
      font-family: $font-BlackerProDisplay;
      font-style: italic;
      font-size: 44px;
      line-height: 48px;
      letter-spacing: -0.004em;
      position: absolute;
      left: 14px;
      right: 14px;
      top: -25px;
      opacity: 0.5;
      white-space: nowrap;
      overflow: hidden;

      @include desktop {
        font-size: 9.7vw;
        line-height: normal;
        left: 48px;
        right: 48px;
        top: -147px;
        opacity: 0.3;
      }

      @media (min-width: 1700px) {
        font-size: 180px;
      }

      &_Link {
        color: $greyE0E0E0 !important;
        font-size: 44px;

        @include desktop {
          font-size: 9.7vw;
        }

        @media (min-width: 1700px) {
          font-size: 180px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &_link {
      @include desktop {
        margin-left: auto;
      }
    }

    &_col {
      @include mobile {
        margin-bottom: 30px;
      }

      .giftcard_block_4-wrapper2 & {
        @include mobile {
          margin-bottom: 0;
        }
      }

      &:first-child {
        @include desktop {
          width: 50%;
          flex-shrink: 0;
        }

        @include wide-desktop {
          padding-right: 100px;
        }

        &:before {
          display: none;
        }

        .giftcard_block_4-wrapper2 & {
          @include mobile {
            grid-column: 1/-1;
          }
        }
      }
    }

    &_logo {
      max-width: 259px;
      margin: 0 auto;
    }

    &_image {
      img {
        vertical-align: top;
      }
    }

    &_content {
      @include mobile {
        margin-bottom: 50px;
      }

      h2 {
        font-family: $font-BlackerProDisplay;
        color: var(--cms-block-fc);
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 20px;

        @include wide-desktop {
          font-size: 44px;
          line-height: 48px;
        }

        .CmsPage-Content & {
          text-align: left;
        }
      }

      h3 {
        color: var(--cms-block-fc);
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.004em;
        margin-top: 0;
        margin-bottom: 20px;
      }

      a {
        background: $black;
        color: $white;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        min-height: 48px;
        padding: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 225px;

        .giftcard_block_4-wrapper2 & {
          background: none;
          border: 1px solid $white;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &_wholeLink {
      position: static;

      a {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
      }
    }

    &_bkg {
      position: absolute;
      bottom: -46px;
      right: -14px;
      width: auto !important;
      max-width: 100%;
      z-index: 1;

      @include desktop {
        bottom: -20px;
        right: 6%;
      }
    }
  }

  &.beauty-cms_block-19 {
    margin-bottom: 50px;

    @include desktop {
      margin-bottom: 150px;
    }
  }

  &_terms {
    margin-bottom: 50px;

    @include desktop {
      margin-bottom: 150px;
    }

    &_title {
      font-size: 24px;
      line-height: 32px;
      position: relative;
      padding: 0;
      margin: 0 0 16px;
      text-align: center;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        border-bottom: 1px solid $black;
      }

      span {
        background: $white;
        padding: 0 16px;
      }
    }

    &_description {
      font-weight: 400;
      font-size: 14px;
      margin: 0 0 28px;
      text-align: center;
    }
  }

  &_check_balance {
    margin-bottom: 50px;

    @include desktop {
      margin-bottom: 150px;
    }

    &-columns {
      width: 100%;
      max-width: 360px;
      margin: 0 auto;

      @include desktop {
        display: flex;
        align-items: center;
        max-width: 982px;
      }
    }

    &-left {
      @include desktop {
        flex: 1;
      }
    }

    &-right {
      padding: 24px 0;

      @include desktop {
        flex: 1;
        padding: 48px 32px;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.2px;
      padding: 0 0 16px;
      margin: 0;
      position: relative;

      .giftcard_check_balance-columns & {
        @include mobile {
          text-align: left !important;
        }
      }
    }

    &-description {
      font-weight: 400;
      font-size: 14px;
      margin: 0 0 10px;
    }

    &-form {
      .Field {
        margin-top: 0;
      }

      .GiftCartCoupon-Button.Button {
        margin-top: 0;
      }

      .GiftCartCoupon-InfoGrid {
        margin-top: 40px;
      }
    }

    .RenderWhenVisible,
    .CmsBlock-Wrapper {
      @include desktop {
        height: 100%;
      }
    }

    .Button {
      background: $black;
      color: $white;
      margin: 0;
      float: right;
      min-width: 208px;
      width: auto;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.04em;
      margin-top: 28px;

      &:hover {
        background: $white;
        color: $black;
        border-radius: 0;
        color: $black;
      }

      &_isLoading {
        opacity: 0.5;
      }
    }
  }

  &_check_balance_two {
    margin-bottom: 50px;

    @include desktop {
      margin-bottom: 150px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 10%;
    }

    &_form {
      @include desktop {
        width: 33%;
      }

      &_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        text-align: center;
        margin-bottom: 15px;
      }

      &_content {
        max-width: 100%;

        .Field {
          margin-top: 15px;

          @include desktop {
            margin-top: 0;
          }
        }

        .inputbox {
          @include desktop {
            margin-top: 15px;
          }

          &-row-top {
            @include desktop {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-column-gap: 15px;
            }

            @include ultra-narrow-desktop {
              display: block;
            }
          }

          &-full {
            @include desktop {
              grid-column: 1/-1;
            }
          }
        }

        textarea {
          @include desktop {
            width: 100%;
            vertical-align: top;
          }
        }

        .Button {
          width: 100%;
        }

        .GiftCartCoupon-InfoGrid {
          margin-top: 40px;
        }
      }
    }

    &_banner {
      position: relative;
      margin: 0 -14px 30px;

      @include desktop {
        margin: 0;
        width: 57%;
      }
    }

    &_content {
      background: $white;
      padding: 11px 22px;
      margin: -23px 14px 0;

      @include desktop {
        margin: -134px 14% 0;
        padding: 7% 10%;
      }
    }

    &_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      margin-bottom: 11px;
    }

    &_description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
    }
  }

  &_stores {
    min-height: 0;
    margin-bottom: 50px;

    @include desktop {
      margin-bottom: 150px;
    }
  }

  &__page_menu {
    border-bottom: 1px solid $greyE0E0E0;
    border-top: 1px solid $greyE0E0E0;

    @include mobile {
      display: flex;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        border-radius: 5px;
      }

      &::-webkit-scrollbar {
        height: 2px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
      }
    }

    .giftcard_main_banner + div & {
      margin-top: -50px;
      margin-bottom: 50px;

      @include desktop {
        margin-top: -150px;
        margin-bottom: 150px;
      }
    }

    &_container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include desktop {
        justify-content: center;
      }
    }

    &_link {
      font-size: 14px;
      line-height: 16px;
      color: var(--cms-block-fc);
      padding: 32px 35px;
      position: relative;
      transition: color 0.2s ease;
      margin-left: 0;
      cursor: pointer;
      text-align: center;
      flex-shrink: 0;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.clarins {
  @include desktop {
    margin-top: -24px;
  }

  &_container {
    @include desktop {
      max-width: 1100px;
      margin: 0 auto;
    }
  }

  &_rare_products {
    padding: 25px 0;

    @include desktop {
      padding-left: 100px;
      padding-right: 100px;
    }

    @include wide-desktop {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &_title {
      color: $white;
      text-align: center;
      font-size: 32px;
      letter-spacing: 4px;
      margin-bottom: 30px;

      @include desktop {
        font-size: 42px;
        letter-spacing: 7px;
        margin-bottom: 50px;
      }

      @include wide-desktop {
        font-size: 64px;
      }
    }

    &_cols {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      @include mobile {
        align-items: center;
        flex-direction: column;
      }
    }

    &_col {
      padding: 20px 0;

      @include desktop {
        padding: 0 30px;
      }
    }

    &_col1 {
      @include mobile {
        padding-left: 140px;
      }
    }

    &_col3 {
      @include desktop {
        padding-bottom: 50px;
      }

      @include mobile {
        padding-right: 100px;
      }
    }

    &_col2 {
      @include mobile {
        padding-left: 100px;
      }
    }

    &_container {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    &_link {
      display: flex;
      align-items: flex-start;
      color: $white;
      position: absolute;
      right: calc(100% - 20px);
      top: 46px;
      white-space: nowrap;

      .clarins_rare_products_col2 & {
        top: 27px;
        right: calc(100% - 38px);
      }

      .clarins_rare_products_col3 & {
        top: 14px;
        right: auto;
        left: calc(100% - 34px);
      }

      img {
        width: 27px !important;
        height: 27px !important;
        margin-right: 10px;
      }

      span {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
      }

      &.reverse {
        flex-direction: row-reverse;
        text-align: right;

        img {
          margin-right: 0;
          margin-left: 10px;
        }
      }

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }

  &_rare_flower {
    color: $white;
    padding: 25px 0;

    @include wide-desktop {
      padding: 50px 0;
    }

    &_cols {
      @include desktop {
        display: flex;
        align-items: center;
      }

      &.reverse {
        @include desktop {
          flex-direction: row-reverse;
          text-align: right;
        }
      }
    }

    &_col {
      &:last-child {
        @include desktop {
          max-width: 560px;
          padding-left: 60px;
        }

        .reverse & {
          @include desktop {
            padding-left: 0;
            padding-right: 60px;
          }
        }
      }
    }

    h2 {
      color: $white;
      font-weight: 400;
      font-size: 32px;
      line-height: 1;
      display: inline-block;
      letter-spacing: 7px;
      margin: 0 0 50px;

      @include desktop {
        font-size: 36px;
      }

      @include wide-desktop {
        font-size: 49px;
      }
    }

    h3 {
      color: $gold2;
      font-size: 20px;
      letter-spacing: 0.5px;
      margin: 0 0 25px;
    }

    p {
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.4px;

      @include desktop {
        padding-right: 90px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .reverse {
      p {
        @include desktop {
          padding-right: 0;
          padding-left: 90px;
        }
      }
    }
  }

  &_moonlight_flower {
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 245px;
      text-align: center;
      color: white;
      font-weight: 500;
      font-size: 32px;
      line-height: 1.4;

      @include wide-desktop {
        font-size: 46px;
        height: 745px;
      }
    }
  }

  &_rare_results {
    padding: 25px 0;

    @include wide-desktop {
      padding: 50px 0;
    }

    &_into {
      color: $white;
      text-align: center;
      margin-bottom: 30px;

      @include desktop {
        margin-bottom: 70px;
      }

      h3 {
        color: $gold2;
        display: inline-block;
        letter-spacing: 0.5px;
        margin: 0 0 20px;

        .CmsPage & {
          @include mobile {
            font-size: 18px;
          }
        }
      }

      p {
        color: $white;
        max-width: 460px;
        margin: 0 auto;
        font-weight: 500;
      }
    }

    &_cols {
      @include desktop {
        display: flex;
        align-items: center;
        padding-bottom: 100px;
      }
    }

    &_col {
      padding-bottom: 30px;

      @include mobile {
        text-align: center;
      }

      @include desktop {
        width: 50%;
        flex-shrink: 0;
        padding-bottom: 0;
      }

      &:last-child {
        @include desktop {
          padding-left: 50px;
        }
      }

      img {
        vertical-align: top;
      }

      h2 {
        color: $white;
        font-weight: 500;
        font-size: 30px;
        line-height: normal;
        display: inline-block;
        letter-spacing: 6px;
        margin: 0 0 10px;

        @include desktop {
          font-size: 50px;
        }
      }

      p {
        color: $white;
        max-width: 300px;
        font-weight: 500;

        @include mobile {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &_points {
      margin: 20px 0;
      display: inline-block;

      &_title {
        color: $white;
        font-size: 18px;
        padding: 0 20px;
        text-align: left;
      }
    }

    &_point {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &_value {
        border: 1px solid $gold2;
        border-radius: 50%;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 38px;
        font-weight: 300;
        width: 98px;
        height: 98px;
        flex-shrink: 0;

        @include wide-desktop {
          width: 117px;
          height: 117px;
        }

        span {
          font-size: 22px;
          margin-top: -10px;
        }
      }
    }

    &_note {
      color: $white;
      font-size: 10px;
      line-height: 14px;
    }

    &_statment {
      text-align: center;
      margin-bottom: 25px;

      @include desktop {
        margin-bottom: 50px;
      }

      h3 {
        color: $white;
        max-width: 476px;
        margin: 0 auto;
        font-weight: 400;
        font-size: 29px;
        line-height: normal;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 30px;
        text-align: left;

        .CmsPage & {
          @include mobile {
            font-size: 16px;
            max-width: 252px;
            padding-bottom: 12px;
          }
        }

        strong {
          color: $gold2;
          font-size: 124px;
          line-height: 89px;
          font-weight: 500;

          @include mobile {
            font-size: 54px;
            line-height: 46px;
          }
        }

        span {
          color: $gold2;

          &.clarins_rare_results_statment_year {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
          }
        }
      }

      a {
        border: 1px solid $white;
        border-radius: 3px;
        color: $white;
        display: inline-flex;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        padding: 10px 13px;

        &:before {
          content: "";
          border: 5px solid transparent;
          border-left-color: $white;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &_bottom_note {
      color: $white;
      font-size: 10px;
      text-align: center;
    }
  }

  &_rare_science {
    padding-top: 25px;

    @include wide-desktop {
      padding-top: 50px;
    }

    &_title {
      h2 {
        color: $white;
        font-weight: 500;
        font-size: 30px;
        line-height: normal;
        letter-spacing: 6px;
        margin: 0 0 10px;
        text-align: center;

        @include desktop {
          font-size: 50px;
        }
      }
    }
  }

  &_science_precious {
    &_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 245px;
      text-align: center;

      @include wide-desktop {
        height: 745px;
      }

      h2 {
        color: $white;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
        margin: 0 0 20px;
        letter-spacing: 3px;

        @include wide-desktop {
          font-size: 46px;
          margin-bottom: 40px;
        }
      }

      p {
        color: $white;
        max-width: 790px;
        letter-spacing: 1px;
        font-weight: 500;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  &_rare_science_flower {
    color: $white;
    padding: 25px 0;

    @include wide-desktop {
      padding: 50px 0;
    }

    &_cols {
      @include desktop {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.reverse {
        @include desktop {
          flex-direction: row-reverse;
          text-align: right;
        }
      }
    }

    &_col {
      &:last-child {
        @include desktop {
          max-width: 470px;
          padding-left: 60px;
        }

        .reverse & {
          @include desktop {
            padding-left: 0;
            padding-right: 60px;
          }
        }
      }
    }

    h2 {
      color: $white;
      font-weight: 400;
      font-size: 32px;
      line-height: normal;
      display: inline-block;
      letter-spacing: 2px;
      margin: 0 0 30px;

      @include desktop {
        font-size: 36px;
      }

      .CmsPage-Content & {
        @include mobile {
          text-align: left;
        }
      }
    }

    h3 {
      color: $gold2;
      font-size: 20px;
      letter-spacing: 0.5px;
      margin: 0 0 25px;
    }

    h4 {
      color: $white;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.4px;
      margin-bottom: 10px;
    }

    p {
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .reverse {
      p {
      }
    }
  }

  &_trio_peptides {
    padding-top: 30px;
    text-align: center;

    h2 {
      color: $white;
      font-weight: 400;
      font-size: 30px;
      line-height: normal;
      letter-spacing: 5px;
      margin: 0 0 15px;

      @include desktop {
        font-size: 38px;
      }
    }

    p {
      color: $white;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      max-width: 850px;
      margin-right: auto;
      margin-left: auto;

      @include mobile {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &_points {
      margin: 50px auto 80px;
      display: inline-block;

      &_title {
        color: $white;
        font-size: 18px;
        padding: 0 20px;
        text-align: left;
        max-width: 265px;
        line-height: 28px;
        font-weight: 500;
      }
    }

    &_point {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 25px;
      }

      &_value {
        border: 1px solid $gold2;
        border-radius: 50%;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 38px;
        font-weight: 300;
        width: 98px;
        height: 98px;
        flex-shrink: 0;

        @include wide-desktop {
          width: 117px;
          height: 117px;
        }

        span {
          font-size: 22px;
          margin-top: -10px;
        }
      }
    }

    &_bottom_note {
      color: $white;
      font-size: 10px;
      font-weight: 500;
      text-align: left;
      padding-bottom: 20px;
    }
  }

  &_sustainable_luxury {
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;

    @include desktop {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    h5 {
      color: $gold2;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    p {
      color: $white;
      display: inline-block;
      font-weight: 500;
      font-size: 18px;
      line-height: normal;
      letter-spacing: 0.5px;
      max-width: 840px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .VideoWidget {
      &-Wrapper {
        display: flex;
        flex-direction: column-reverse;
      }

      &-VideoContainer {
        margin-bottom: 20px;

        @include desktop {
          margin-bottom: 40px;
        }
      }

      &-title {
        color: $white;
        font-size: 32px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 4px;
        margin-top: 0;
        margin-bottom: 20px;

        @include desktop {
          font-size: 42px;
          letter-spacing: 7px;
        }

        @include wide-desktop {
          font-size: 64px;
        }
      }

      &-description {
        color: $white;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.5px;
        margin-bottom: 25px;

        @include desktop {
          margin-bottom: 50px;
        }
      }

      &-playMobile,
      &-PlayIcon {
        display: none !important;
      }
    }
  }

  &_nature_slider {
    padding: 25px 0;

    @include desktop {
      padding: 50px 0;
    }

    .SliderWidget {
      &-Figure {
        @include desktop {
          display: flex !important;
          flex-direction: row-reverse;
          align-items: center;
        }
      }

      &-Figcaption {
        position: static;
        transform: none;
        text-align: center;
        padding: 20px 0;

        @include desktop {
          text-align: right;
          padding: 0 60px 0 75px;
          width: 50%;
          flex-shrink: 0;
        }

        h2 {
          color: $gold2;
          margin: 0 0 15px;
        }

        h3 {
          color: $white;
          line-height: 35px;
          font-size: 48px;
          font-weight: 400;
          margin: 16px 0;
        }

        p {
          color: $white;
          font-weight: 500;
          font-size: 17px;
          line-height: normal;
          margin: 0;
        }
      }
    }

    &.slick-slider {
      .slick-prev,
      .slick-next {
        &:before,
        &:after {
          background-color: $white;
        }
      }
    }
  }

  &_rare_routine {
    margin-top: 25px;

    @include wide-desktop {
      margin-top: 50px;
    }

    &_title {
      color: $white;
      font-size: 32px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 4px;
      margin-top: 0;
      margin-top: 25px;
      text-align: center;

      @include desktop {
        font-size: 42px;
        letter-spacing: 7px;
      }

      @include wide-desktop {
        font-size: 64px;
        margin-top: 50px;
      }
    }

    .SliderWidget {
      &-Figure {
        padding: 0 10px;
      }

      &-FigureImage {
        height: 428px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .Image-Image {
          width: auto !important;
          height: auto !important;
          max-width: 100%;
        }
      }

      &-Figcaption {
        position: static;
        transform: none;
        text-align: center;

        h2 {
          color: $white;
          font-size: 23px;
          font-weight: 500;
          margin: 0 0 20px;
        }

        p {
          color: $white;
          font-weight: 500;
          font-size: 16px;
          margin: 0 auto 30px
        }

        .description {
          max-width: 272px;
          min-height: 100px;
        }

        .Button {
          background-color: $gold2;
          border-color: $gold2;
          color: $black;
          height: 44px;
          font-weight: 500;
          letter-spacing: 2px;
          min-width: 186px;
          border-radius: 4px;
        }
      }
    }

    &.slick-slider {
      .slick-dots {
        position: static;
        margin-top: 25px;

        @include desktop {
          margin-top: 55px;
        }

        li {
          button {
            border-color: transparent;

            &:before {
              background-color: $white;
            }
          }

          &.slick-active {
            button {
              &:before {
                background-color: $gold2;
              }
            }
          }
        }
      }

      .slick-prev,
      .slick-next {
        &:before,
        &:after {
          background-color: $white;
        }
      }
    }
  }
}

