/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';

.SpecialOffers {
    &Filters {
        background: $greyF8F8F8;
        padding: 12px 14px 17px;
        margin: 0 -14px 30px;

        @include desktop {
            margin: 0;
            min-height: 949px;
        }

        &.ExpandableContent {
            padding: 0;
            margin: 0;
            background: none;
            border-bottom-color: rgba(0,0,0,.14);

            @include desktop {
                min-height: 0;
            }

            &:last-of-type {
                border-bottom: 1px solid rgba(0, 0, 0, 0.14);
            }
        }

        &-checkbox {
            display: flex;
            font-size: 12px;
            line-height: 17px;
        }

        &-resetall {
            p {
                margin: 10px;
                font-size: 20px;
                color: white;
                text-align:center;
                vertical-align: middle;
            }
            text-align: center;
            background-color: black;
            border: solid;
        }

        input {
            &[type=checkbox] {
                border-color: rgba(0, 0, 0, 0.25);
                width: 14px;
                height: 14px;
                min-height: 14px;
                min-width: 14px;
                margin: 1px 14px 0 0;
                padding: 0;
                flex-shrink: 0;

                &.checked {
                    border-color: $black;
                    border-width: 4px;
                }
            }
        }

        li {
            margin: 24px 0 0;

            &:before {
                display: none;
            }
        }

        .count {
            font-size: 12px;
            line-height: 17px;
            margin: 0 0 0 2px;

            &:before {
                content: " (";
            }

            &:after {
                content: ")";
            }
        }
    }

    &Listing {
        .CategoryListing-Grid {
            @include desktop {
                grid-gap: 26px 14px;
            }
        }
    }
}