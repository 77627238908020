/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';

.NewEvents {
    &-Heading {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 12px;

        .CategoryListing-Title {
            font-weight: 500;
            font-size: 32px;
            line-height: 32px;
            letter-spacing: -0.003em;
            text-align: center;
            margin: auto 0;
            padding: 0;
        }

        .NewEvents {
            &_tabs {
                text-align: center;
                margin: auto 0;

                .NewEvents-Grid_filter {
                    .Field {
                        margin-top: 0;
                    }

                    .selected-month {
                        margin-top: 0;

                        @include desktop {
                            max-width: 234px;
                            width: 234px;
                            position: relative;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }

    .ContentWrapper {
        max-width: 1326px;

        .CategoryListing-Description {
            margin-bottom: 12px;
        }
    }

    &_tabs {
        &__tabs {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 14px;
            margin-bottom: 18px;

            @include desktop {
                gap: 32px;
            }
        }

        &__tab {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.2px;
            cursor: pointer;

            &.is-active {
                font-weight: 500;
                text-decoration: underline;
                cursor: default;
            }
        }

        &__content_wrapper {
            &:not(.is-active) {
                display: none;
            }
        }
    }

    &_LatestPost {
        &_content {
            border-bottom: 1px solid $grey;
            margin-bottom: 34px;
            padding-bottom: 26px;

            @include desktop {
                margin-bottom: 44px;
                padding-bottom: 73px;
            }

            p {
                margin: 0;
            }

            img {
                vertical-align: top;
            }
        }

        &_link {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }
    }

}