/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.CareerForm {
  &-CheckLabel {
    margin-bottom: 0;
    line-height: 18px;

  }

  &-PolicyCheckBox {
    @include mobile {
      margin-bottom: 15px;
    }

    &-container {
      display: flex;
    }
  }

  .Field {
    input {
      width: 100%;
    }

    [type='file'] {
      border: 0 none;
      height: auto;
      opacity: 1;
      padding: 0;
      margin-bottom: 14px;
      display: flex;

      &::-webkit-file-upload-button {
        visibility: hidden;
      }

      &::before {
        content: attr(label);
        display: inline-block;
        border-bottom: 1px solid $black;
        padding-bottom: 2px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}