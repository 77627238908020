@font-face {
    font-family: 'TT Norms Pro';
    src: url(/style/fonts/TTNormsPro-Light.woff2) format('woff2'),
    url(/style/fonts/TTNormsPro-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url(/style/fonts/TTNormsPro-Regular.woff2) format('woff2'),
    url(/style/fonts/TTNormsPro-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url(/style/fonts/TTNormsPro-Medium.woff2) format('woff2'),
    url(/style/fonts/TTNormsPro-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url(/style/fonts/TTNormsPro-Bold.woff2) format('woff2'),
    url(/style/fonts/TTNormsPro-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Pro Tit Rg';
    src: url(/style/fonts/BlackerProTit-Bd.woff2) format('woff2'),
    url(/style/fonts/BlackerProTit-Bd.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Pro Dis Md';
    src: url(/style/fonts/BlackerProDis-MdIt.woff2) format('woff2'),
    url(/style/fonts/BlackerProDis-MdIt.woff) format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Blacker Pro Tx Lt';
    src: url(/style/fonts/BlackerProTx-Lt.woff2) format('woff2'),
    url(/style/fonts/BlackerProTx-Lt.woff) format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Display';
    src: url(/style/fonts/BlackerDisplay-Regular.woff2) format('woff2'),
    url(/style/fonts/BlackerDisplay-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Display';
    src: url(/style/fonts/BlackerDisplay-Medium.woff2) format('woff2'),
    url(/style/fonts/BlackerDisplay-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Display';
    src: url(/style/fonts/BlackerDisplay-ExtraBold.woff2) format('woff2'),
    url(/style/fonts/BlackerDisplay-ExtraBold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Pro Display';
    src: url(/style/fonts/BlackerProDisBd/font.woff2) format('woff2'),
    url(/style/fonts/BlackerProDisBd/font.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Pro Display';
    src: url(/style/fonts/BlackerProDisIt/font.woff2) format('woff2'),
    url(/style/fonts/BlackerProDisIt/font.woff) format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Arial';
    src: url(/style/fonts/ArialMT.woff2) format('woff2'),
    url(/style/fonts/ArialMT.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}
