.scroll-top {
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;
  right: 20px;
  bottom: 129px;
  position: fixed;
  border: 1px solid $black;
  box-sizing: border-box;
  background: $black;
  box-shadow: 0 0 10px 0 $grey;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  transition: opacity $ease-main, transform $ease-main;
  opacity: 0;
  transform: translate3d(0, 5px, 0);
  display: none;

  @include desktop {
    right: 64px;
    bottom: 75px;
  }

  &.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &:hover {
    opacity: .5;
  }

  $b: &;

  svg, img {
    width: 14px;
    height: 8px;
    @include center-image;
    fill: $white;
  }
}
