/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    // h1 desktop
    --h1-font-size: var(--imported_h1_font_size, 36px);
    --h1-font-weight: var(--imported_h1_font_weight, 700);
    --h1-color: var(--imported_h1_color, #{$black});
    --h1-line-height: var(--imported_h1_line_height, 40px);
    --h1-font-style: var(--imported_h1_font_style, normal);

    // h1 mobile
    --h1-font-weight-mobile: var(--imported_h1Mobile_font_weight, 700);
    --h1-font-size-mobile: var(--imported_h1Mobile_font_size, 24px);
    --h1-color-mobile: var(--imported_h1Mobile_color, #{$black});
    --h1-line-height-mobile: var(--imported_h1Mobile_line_height, 28px);
    --h1-font-style-mobile: var(--imported_h1Mobile_font_style, normal);

    // h2 desktop
    --h2-font-size: var(--imported_h2_font_size, 24px);
    --h2-font-weight: var(--imported_h2_font_weight, 700);
    --h2-color: var(--imported_h2_color, #{$black});
    --h2-line-height: var(--imported_h2_line_height, 28px);
    --h2-font-style: var(--imported_h2_font_style, normal);

    // h2 mobile
    --h2-font-size-mobile: var(--imported_h2Mobile_font_size, 18px);
    --h2-font-weight-mobile: var(--imported_h2Mobile_font_weight, 700);
    --h2-color-mobile: var(--imported_h2Mobile_color, #{$black});
    --h2-line-height-mobile: var(--imported_h2Mobile_line_height, 24px);
    --h2-font-style-mobile: var(--imported_h2Mobile_font_style, normal);

    // h3 desktop
    --h3-font-size: var(--imported_h3_font_size, 18px);
    --h3-font-weight: var(--imported_h3_font_weight, 700);
    --h3-color: var(--imported_h3_color, #{$black});
    --h3-line-height: var(--imported_h3_line_height, 24px);
    --h3-font-style: var(--imported_h3_font_style, normal);

    // h3 mobile
    --h3-font-size-mobile: var(--imported_h3Mobile_font_size, 16px);
    --h3-font-weight-mobile: var(--imported_h3Mobile_font_weight, 700);
    --h3-color-mobile: var(--imported_h3Mobile_color, #{$black});
    --h3-line-height-mobile: var(--imported_h3Mobile_line_height, 24px);
    --h3-font-style-mobile: var(--imported_h3Mobile_font_style, normal);

    // paragraph
    --paragraph-font-size: var(--imported_paragraph_font_size, 14px);
    --paragraph-font-weight: var(--imported_paragraph_font_weight, 400);
    --paragraph-color: var(--imported_paragraph_color, #{$black});
    --paragraph-line-height: var(--imported_paragraph_line_height, 20px);
    --paragraph-font-style: var(--imported_paragraph_font_style, normal);

    // paragraph mobile
    --paragraph-font-size-mobile: var(--imported_paragraphMobile_font_size, 14px);
    --paragraph-font-weight-mobile: var(--imported_paragraphMobile_font_weight, 400);
    --paragraph-color-mobile: var(--imported_paragraphMobile_color, #{$black});
    --paragraph-line-height-mobile: var(--imported_paragraphMobile_line_height, 16px);
    --paragraph-font-style-mobile: var(--imported_paragraphMobile_font_style, normal);

    // caption
    --caption-font-size: var(--imported_caption_font_size, 12px);
    --caption-font-weight: var(--imported_caption_font_weight, 400);
    --caption-color: var(--imported_caption_color, #{$black});
    --caption-line-height: var(--imported_caption_line_height, 20px);
    --caption-font-style: var(--imported_caption_font_style, normal);

    // caption mobile
    --caption-font-size-mobile: var(--imported_captionMobile_font_size, 12px);
    --caption-font-weight-mobile: var(--imported_captionMobile_font_weight, 400);
    --caption-color-mobile: var(--imported_captionMobile_color, #{$black});
    --caption-line-height-mobile: var(--imported_captionMobile_line_height, 16px);
    --caption-font-style-mobile: var(--imported_captionMobile_font_style, normal);
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: $color-grey-E5;
}

::-webkit-scrollbar {
    width: 2px;
    background-color: $color-grey-E5;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $black;
}

* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family: inherit;
    outline: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    // backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label,
summary {
    display: block;
}

header,
footer,
main {
    font-size: 14px;
}

main {
    min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
    max-width: 100vw;

    @include mobile {
        min-height: calc(
                100vh
                - var(--header-total-height)
                - var(--navigation-tabs-height)
                - var(--category-page-miscellaneous-height));
        //margin-block-start: var(--header-total-height);
        margin-block-end: var(--footer-total-height);
    }
}

.desktop {
    @include mobile {
        display: none !important;
    }
}

.mobile {
    @include desktop {
        display: none !important;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 17px;
    }
}

img {
    width: 100%;

    .CmsBlock-Wrapper &,
    .block-static-block &,
    .ProductCard-Promo &,
    .CategoryPage-LeftCol & {
        max-width: 100%;
        height: auto !important;
    }
}

html {
    font-size: $font-standard-size;
}

body {
    font-family: $font-muli;
    color: $black;
    scroll-behavior: smooth;
    background-color: #fff;
}

a {
    color: $black;
    text-decoration: inherit;

    &:hover {
        cursor: pointer;
    }
}

h1 {
    color: var(--h1-color);
    font-size: var(--h1-font-size);
    font-style: var(--h1-font-style);
    font-weight: var(--h1-font-weight);
    line-height: var(--h1-line-height);

    @include mobile {
        color: var(--h1-color-mobile);
        font-size: var(--h1-font-size-mobile);
        font-style: var(--h1-font-style-mobile);
        font-weight: var(--h1-font-weight-mobile);
        line-height: var(--h1-line-height-mobile);
    }

    + h2,
    + h3,
    + h4 {
        margin-block-start: 7px;

        @include mobile {
            margin-block-start: 8px;
        }
    }
}

h2 {
    color: var(--h2-color);
    font-weight: var(--h2-font-weight);
    font-size: var(--h2-font-size);
    font-style: var(--h2-font-style);
    line-height: var(--h2-line-height);
    margin-block-end: 18px;
    margin-block-start: 22px;

    @include mobile {
        color: var(--h2-color-mobile);
        font-size: var(--h2-font-size-mobile);
        font-style: var(--h2-font-style-mobile);
        font-weight: var(--h2-font-weight-mobile);
        line-height: var(--h2-line-height-mobile);
        margin-block-end: 21px;
        margin-block-start: 25px;
    }
}

h3 {
    color: var(--h3-color);
    font-size: var(--h3-font-size);
    font-style: var(--h3-font-style);
    font-weight: var(--h3-font-weight);
    line-height: var(--h3-line-height);
    margin-block-start: 22px;
    margin-block-end: 18px;

    @include mobile {
        color: var(--h3-color-mobile);
        font-size: var(--h3-font-size-mobile);
        font-style: var(--h3-font-style-mobile);
        font-weight: var(--h3-font-weight-mobile);
        line-height: var(--h3-line-height-mobile);
        margin-block-start: 25px;
        margin-block-end: 21px;
    }

    + h4 {
        inset-block-start: -9px;
        margin-block-start: 7px;
        margin-block-end: 7px;

        @include mobile {
            inset-block-start: -9px;
            margin-block-start: 8px;
            margin-block-end: 8px;
        }
    }
}

h4,
p,
li,
dl {
    color: var(--paragraph-color);
    font-size: var(--paragraph-font-size);
    font-style: var(--paragraph-font-style);
    font-weight: var(--paragraph-font-weight);
    line-height: var(--paragraph-line-height);

    @include mobile {
        color: var(--paragraph-color-mobile);
        font-size: var(--paragraph-font-size-mobile);
        font-style: var(--paragraph-font-style-mobile);
        font-weight: var(--paragraph-font-weight-mobile);
        line-height: var(--paragraph-line-height-mobile);
    }
}

li {
    list-style-type: none;
    list-style-position: inside;
    margin-block-end: 6px;

    ol & {
        counter-increment: list;

        @include mobile {
            counter-increment: list;
        }
    }

    &:last-child {
        margin-block-end: 20px;
    }
}

p {
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 17px;
    }

    &.caption {
        color: var(--caption-color);
        font-size: var(--caption-font-size);
        font-style: var(--caption-font-style);
        font-weight: var(--caption-font-weight);
        line-height: var(--caption-line-height);

        @include mobile {
            color: var(--caption-color-mobile);
            font-size: var(--caption-font-size-mobile);
            font-style: var(--caption-font-style-mobile);
            font-weight: var(--caption-font-weight-mobile);
            line-height: var(--caption-line-height-mobile);
        }
    }
}

input,
select {
    min-height: 24px;
    min-width: 48px;
    font-size: 14px;

    @include mobile {
        min-height: 28px;
        min-width: 56px;
        font-size: 14px;
    }
}

textarea {
    resize: none;

    @include mobile {
        font-size: 16px;
        width: 100%;
    }
}

mark {
    margin-inline-end: 5px;
}

iframe {
    max-width: 100%;

    &#launcher {
        @include mobile {
            box-shadow: 0 0 10px 0 $grey;
            border-radius: 24px;
            bottom: 58px !important;
            z-index: 100 !important;
        }
    }
}

a,
input,
button,
textarea,
select,
details {
    @include mobile {
        touch-action: manipulation;
    }
}

.rc-slider {
    height: 11px !important;
    padding: 5px 0 !important;
}

.rc-slider-handle {
    transition: left 300ms !important;
    width: 11px !important;
    height: 11px !important;
    background-color: $black !important;
    border-color: $black !important;
}

.rc-slider-handle:active,
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2) !important;
}

.rc-slider-handle-1 {
    display: none !important;
}

.rc-slider-track,
.rc-slider-step,
.rc-slider-rail {
    height: 1px !important;
}

.rc-slider-rail,
.rc-slider-track {
    background-color: $black !important;
}

.has-float-label {
    @include float-label-container;

    @include float-label;

    .Field-Label {
        padding-bottom: 0;

        .MyAccountOverlay & {
            padding-bottom: 0;
            line-height: 14px;
            max-width: calc(100% - 20px);
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    input, textarea, select {
        padding-left: 16px;
        @include float-label-input;

        @include float-label-scaled {
            left: 10px;
            top: 11px;
        }

        &:focus {
            outline: none;
            border-color: rgba(0,0,0,.5);

            &::-webkit-input-placeholder { /* Edge */
                opacity: 0;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                opacity: 0;
            }

            &::placeholder {
                opacity: 0;
            }
        }

        + label {
            font-size: 10px;
            font-weight: 600;
            line-height: 14px;
            padding: 0 6px;
            background: $white;
            max-width: calc(100% - 20px);
            min-width: 0;
            min-height: 28px;
            display: flex;
            align-items: center;
        }
    }
}

.alignItemsStart {
    align-items: flex-start !important;
}

.alignItemsCenter {
    align-items: center !important;
}

.alignItemsEnd {
    align-items: flex-end !important;
}

#CookiebotWidget {
    &:not(.CookiebotWidget-inactive) {
        @include mobile {
            bottom: 68px !important;
            left: 20px !important;
            z-index: 100 !important;
        }
    }

    &.CookiebotWidget-open {
        @include mobile {
            z-index: 102 !important;
        }

        + #CookiebotWidgetUnderlay {
            @include mobile {
                z-index: 101 !important;
            }
        }
    }
}

#CybotCookiebotDialog {
    img {
        max-width: 100%;
        width: auto;
    }

    .CybotCookiebotFader {
        opacity: 0;
        display: none;
    }

    .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
        display: flex;
        flex-wrap: wrap;
    }
}
