/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';

.Magazine {
    .ContentWrapper {
        max-width: 1326px;
    }

    &_tabs {
        &__tabs {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 14px;
            margin-bottom: 18px;

            @include desktop {
                gap: 32px;
            }
        }

        &__tab {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.2px;
            cursor: pointer;

            &.is-active {
                font-weight: 500;
                text-decoration: underline;
                cursor: default;
            }
        }

        &__content_wrapper {
            &:not(.is-active) {
                display: none;
            }
        }
    }

    &_LatestPost {
        &_content {
            border-bottom: 1px solid $grey;
            margin-bottom: 34px;
            padding-bottom: 26px;

            @include desktop {
                margin-bottom: 44px;
                padding-bottom: 73px;
            }

            p {
                margin: 0;
            }

            img {
                vertical-align: top;
            }
        }

        &_link {
            display: inline-block;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-top: 21px;
            text-decoration: underline;

            @include desktop {
                margin-top: 34px;
            }
        }
    }

    &_title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        text-align: center;
        margin: 57px 0 8px;

        @include desktop {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.2px;
            margin: 16px 0 21px;
        }
    }
}