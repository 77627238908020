/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../../src/style/abstract/variables';
@import '../../../../../../../../src/style/abstract/media';
@import '../../../../../../../../src/style/abstract/button';
@import '../../../../../../../../src/style/abstract/loader';
@import '../../../../../../../../src/style/abstract/icons';
@import '../../../../../../../../src/style/abstract/parts';

:root {
  --blog-post-card-title-weight: 600;
  --blog-post-card-title-size: 18px;
  --blog-post-card-description-height: 100px;
  --blog-post-card-border: 1px solid lightgray;
}

.NewEvents {
  &BlogPostCard {
    &-DescriptionWrapper {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.2px;
    }

    &-Content {

      &_Location,
      &_Event {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        color: rgb(158, 158, 158);
      }

      &_Location {
        color: #EC008C;
      }

      &_Date,
      &_Details-Location {
        margin: 0;
        font-size: 14px;
        letter-spacing: 0.2px;
      }

      &_Details-Location {
        a {
          font-weight: 700;
          display: flex;
          align-items: center;
          margin: 8px 0;

          img {
            height: 20px !important;
            width: 18px !important;
            margin-right: 6px;
          }
        }

      }

      &_Title {
        min-height: 38px;

        a {
          line-height: 1.33;
          letter-spacing: -0.2px;
          font-style: italic;
          font-size: 28px;
          font-weight: 300;
        }
      }

      &_Details {
        overflow: hidden;
        line-height: 1.5;
        padding: 16px 0;
        max-height: 100%;
        transition: height 500ms ease-in-out;
      }

      &_Actions {
        margin-top: auto;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .NewEvents_LatestPost_showMore {
          margin-right: 24px;
          text-decoration: underline;
          text-wrap: nowrap;
        }
      }
    }

    &-month {
      border-bottom: 1px solid $grey;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.2px;
      padding: 6px 0;
      display: none;

      @include desktop {
        padding: 14px 0;
        margin-bottom: 12px;
      }

      &:first-child {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-01 {
      +.NewEventsBlogPostCard-month:not(.month-01) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-02 {
      +.NewEventsBlogPostCard-month:not(.month-02) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-03 {
      +.NewEventsBlogPostCard-month:not(.month-03) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-04 {
      +.NewEventsBlogPostCard-month:not(.month-04) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-05 {
      +.NewEventsBlogPostCard-month:not(.month-05) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-06 {
      +.NewEventsBlogPostCard-month:not(.month-06) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-07 {
      +.NewEventsBlogPostCard-month:not(.month-07) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-08 {
      +.NewEventsBlogPostCard-month:not(.month-08) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-09 {
      +.NewEventsBlogPostCard-month:not(.month-09) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-10 {
      +.NewEventsBlogPostCard-month:not(.month-10) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-11 {
      +.NewEventsBlogPostCard-month:not(.month-11) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-12 {
      +.NewEventsBlogPostCard-month:not(.month-12) {
        display: block;
        grid-column: 1 / -1;
      }
    }
  }

  .NewEventsBlogPostCard {
    &-Image {
      &.Image {
        height: 100%;
        padding-bottom: 0;
        display: block;
      }
    }

    &-ImageWrapper {
      flex-basis: 45%;
      flex-shrink: 0;
      max-height: 380px;

      @include desktop {
        flex-basis: 37%;
      }

      @include mobile {
        height: 380px;
      }
    }
  }

  &-Grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;

    .NewEventsBlogPostCard-ContentWrapper {
      display: grid;

      @include desktop {
        grid-template-columns: 1fr 1fr;
        grid-gap: 32px;
        grid-template-areas: "NewEventsBlogPostCard-ImageWrapper NewEventsBlogPostCard-Details";
        height: 380px;

      }

      @include mobile {
        grid-template-columns: 1fr;
        height: auto;
        grid-gap: 0px;
        grid-template-rows: repeat(1, 1fr);
      }

      &_expand {
        height: auto;
      }

      &>* {
        padding: 24px 0;
      }

      .NewEventsBlogPostCard {
        &-Details {
          display: grid;
          grid-template-columns: 1fr;
          // grid-template-rows: 16px auto 16px auto 48px;
          grid-template-rows: auto 16px auto 48px;
          gap: 8px 0px;
          grid-template-areas:
            // "NewEventsBlogPostCard-Content_Event"
            "NewEventsBlogPostCard-Content_Title"
            "NewEventsBlogPostCard-Content_Date"
            "NewEventsBlogPostCard-Content_Details"
            "NewEventsBlogPostCard-Content_Actions";
        }
      }
    }

    &_filter {
      text-align: center;

      label {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        padding: 10px;

      }

      .selected-month {
        margin-top: 0;

        @include desktop {
          max-width: 234px;
          margin-left: auto;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}