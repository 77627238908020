/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --navigation-tabs-height: calc(50px + env(safe-area-inset-bottom));
    --navigation-tabs-background: var(--secondary-light-color);
    --navigation-tabs-color: var(--secondary-dark-color);
}

.NavigationTabs {
    position: fixed;
    inset-inline-start: 0;
    width: 100%;
    z-index: 100;
    padding-block-end: env(safe-area-inset-bottom);
    transform: translateY(0);
    transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

    .hideOnScroll & {
        transform: translateY(100%);
    }

    @include desktop {
        display: none;
    }

    &_isHidden {
        display: none;

        @at-root .hiddenNavigationTabs {
            --navigation-tabs-height: env(safe-area-inset-bottom);
        }
    }

    &-Nav {
        height: var(--footer-nav-height);
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 14px;
        z-index: 20;
        max-width: var(--content-wrapper-width);
        margin: auto;
        height: 0;
    }

    &-Button {
        
        &_isHidden {
            opacity: 0;
            pointer-events: none;
        }

        flex-grow: 1;
        display: inherit;
        position: absolute;
        top: -95px;
        width: 24px;
        height: 24px;

        &[aria-label="Home"] {
            display: none;
        }

        &[aria-label="Go to menu and search"] {
            left: 16px;
        }

        &[aria-label="Open my account"] {
            right: 56px;
            padding: 1px;
        }

        &[aria-label="Minicart"] {
            right: 16px;

            .Header-MinicartItemCount {
                position: absolute;
                bottom: 0;
                right: -3px;
            }
        }
    }

    &-Icon {
        font-weight: bold;
    }
}
