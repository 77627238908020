/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.slick-slider {
  .slick-arrow {
    width: 17px;
    height: 46px;

    @include mobile {
      display: none !important;
    }

    &.slick-disabled {
      opacity: 0.25;
    }
  }

  .slick-prev {
    left: 0;

    @include desktop {
      left: -29px;
    }

    &:before {
      content: "";
      width: 2px;
      height: 21px;
      background: var(--cms-block-fc);
      position: absolute;
      transform: rotate(36deg);
      top: 2px;
      left: 7px;
      opacity: 1;
    }

    &:after {
      content: "";
      width: 2px;
      height: 21px;
      background: var(--cms-block-fc);
      position: absolute;
      bottom: 6px;
      transform: rotate(-36deg);
      left: 7px;
      opacity: 1;
    }
  }

  .slick-next {
    right: 0;

    @include desktop {
      right: -29px;
    }

    &:before {
      content: "";
      width: 2px;
      height: 21px;
      background: var(--cms-block-fc);
      position: absolute;
      transform: rotate(-36deg);
      top: 2px;
      left: 7px;
      opacity: 1;
    }

    &:after {
      content: "";
      width: 2px;
      height: 21px;
      background: var(--cms-block-fc);
      position: absolute;
      bottom: 6px;
      transform: rotate(36deg);
      left: 7px;
      opacity: 1;
    }
  }

  .slick-dots {
    bottom: 10px;

    li {
      width: auto;
      height: auto;
      margin: 0;
      vertical-align: top;

      @include desktop {
        margin: 0 3px;
      }

      &.slick-active {
        margin: 0 4px;
      }

      &:before {
        display: none !important;
      }

      button {
        width: 16px;
        height: 16px;
        border: 1px solid transparent;
        background: transparent;
        border-radius: 50%;
        padding: 0;

        @include desktop {
          width: 18px;
          height: 18px;
        }

        &:before {
          width: 6px;
          height: 6px;
          content: "";
          background: var(--cms-block-fc);
          border-radius: 50%;
          top: 4px;
          left: 4px;
          opacity: 1;

          @include desktop {
            width: 10px;
            height: 10px;
            top: 3px;
            left: 3px;
          }
        }
      }

      &.slick-active {
        button {
          border-color: var(--cms-block-fc);

          &:before {
            width: 10px;
            height: 10px;

            @include mobile {
              top: 2px;
              left: 2px;
            }
          }
        }
      }
    }
  }
}