/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.contact {
    &-content {

        //@include after-mobile {
        //    padding-top: 40px;
        //}

        &:after {
            content: "";
            clear: both;
            display: block;
        }

        h3 {
            margin: 0;
            font-size: 22px;
            font-weight: 600;
            letter-spacing: .06em;
            line-height: 1;
        }
    }

    &-form {
        padding: 0 0 29px;

        @include desktop {
            padding: 21px 0 55px;
        }

        @include wide-desktop {
            padding: 21px 55px 55px;
        }

        .inputbox {
            &-row {
                &-top {
                    @include desktop {
                        display: flex;
                        gap: 15px;

                        > .inputbox {
                            width: 33.3333%;
                        }
                    }
                }

                &-bottom {
                    @include desktop {
                        display: flex;
                        gap: 15px;
                        flex-direction: row-reverse;

                        > .inputbox {
                            width: 66.6666%;

                            &-row {
                                width: 32.3333%;
                            }
                        }
                    }
                }

                &-right {
                    display: flex;
                    justify-content: flex-end;
                    gap: 16px;
                    align-items: center;
                    margin-top: 15px;
                }

                &-required {
                    font-size: 12px;
                    line-height: 14px;
                }
            }

            .Field {
                @include desktop {
                    margin-top: 15px;
                }
            }
        }

        .ContactUs-Buttons {
            min-width: 100%;

            @include desktop {
                min-width: 306px;
            }
        }

        form {
            width: 100%;
        }
    }

    &_bkg_image {
        position: absolute;
        top: -25px;
        right: -20px;

        //@include after-mobile {
        //    top: 10px;
        //    right: 0;
        //}

        .desktop {
            height: 452px;
            padding-bottom: 0;
            width: 333px;

            //@include mobile {
            //    display: none;
            //}
        }

        .mobile {
            height: 110px;
            padding-bottom: 0;
            width: 120px;

            //@include after-mobile {
            //    display: none;
            //}
        }
    }
}

.ContactUs-Legend {
    &.MyAccountOverlay {
        textarea {
            min-height: 109px;
        }

        textarea,
        input {
            background: none;
            width: 100%;
        }

        .Field {
            &_type_checkbox {
                padding-left: 38px;

                label {
                    font-size: 13px;
                    font-weight: 300;
                }
            }

            [type='checkbox'] {
                width: auto;

                & + label {
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 2px;
                }

                &:checked + label::after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    transition: opacity 150ms ease-in;
                    top: 4px;
                    left: 4px;
                    width: 8px;
                    height: 4px;
                    transform: rotate(135deg);
                    border-right: 2px solid white;
                    border-top: 2px solid white;
                    box-shadow: none;
                }
            }
        }
    }
}

.ContactPage {
    .CmsPage-Faq_accordian_content {
        p {
            font-size: 14px;
            line-height: 22px;
        }

        h5 {
            font-weight: 500;
            font-size: 16px;
        }

        h6 {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .Footer-SocialLinks {
            margin-top: 20px;
            margin-bottom: 0 !important;

            img {
                filter: none;
            }
        }
    }
}

.store-sidebar {
    &-title {
        border-bottom: 1px solid black;
        padding: 0 0 8px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1;

        //@include after-mobile {
        //    padding: 0 0 12px;
        //    font-size: 18px;
        //    margin-bottom: 5px;
        //}
    }

    &-content {
        p {
            font-size: 13px;
            line-height: 1.4;
            font-weight: 300;
        }

        strong {
            font-size: 14px;
        }
    }
}

.cms-col-left {
    background: #f6f6f6;
    padding: 20px;

    //@include after-mobile {
    //    float: left;
    //    padding: 25px 40px 10px;
    //    width: 337px;
    //}
}
.chat_click_open {
    cursor: pointer;
}