/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContentShowMore {
    text-align: start;
    overflow: hidden;

    &-ShowAllButton.Button_likeLink {
        --button-height: fit-content;
        --button-hover-height: fit-content;
        --button-hover-padding: 0;

        font-weight: 700;

        &:focus:not(:hover) {
            text-decoration: unset;
        }
    }

    &-ShowAllButtonWrapper {
        background: $white;
        padding-top: 10px;
    }

    &-ContentShowMoreChildren {
        max-height: 300px;
        transition: .5s;
        overflow: hidden;
    }

    &-ContentShowMorePlaceholder {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
    }
}
