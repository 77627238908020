/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.PayPal {
    //min-height: 5rem;

    //@include after-mobile {
    //    margin-top: 2rem;
    //}
    .disabled {
        position: relative;
        margin-bottom: -8px;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: .5;
            z-index: 1000;
        }
    }

    &_isDisabled {
        display: none;
    }

    &CheckoutShipping-Button {
        display: inline-block;
        width: 48%;
        max-width: 361px;
        height: 45px;
        border-radius: 2px;
        margin: 0 1%;
        justify-content: center;
        align-items: center;

        @include mobile {
            display: block;
            margin: 0 auto 15px;
            width: 100%;
        }
    }
}
