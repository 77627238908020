/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../../src/style/abstract/variables';
@import '../../../../../../../../src/style/abstract/media';
@import '../../../../../../../../src/style/abstract/button';
@import '../../../../../../../../src/style/abstract/loader';
@import '../../../../../../../../src/style/abstract/icons';
@import '../../../../../../../../src/style/abstract/parts';

:root {
  --blog-post-card-title-weight: 600;
  --blog-post-card-title-size: 18px;
  --blog-post-card-description-height: 100px;
  --blog-post-card-border: 1px solid lightgray;
}

.SpecialOffers {
  &BlogPostCard {
    &:nth-child(1),
    &:nth-child(2) {
      @media (min-width: 360px) and (max-width: 1279px) {
        order: -2;
      }

      @media (min-width: 481px) and (max-width: 810px) {
        order: 0;
      }
    }

    &-ImageWrapper {
      display: block;
    }

    &-Image {
      &.Image {
        @media (max-width: 359px) {
          padding-bottom: 0;
          height: auto;
          vertical-align: top;

          img {
            position: static;
            vertical-align: top;
          }
        }
      }
    }

    &-Title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.0008em;
      min-height: 32px;
      margin-bottom: 6px;

      a {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &-DescriptionWrapper {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
      margin-bottom: 12px;

      p {
        margin-bottom: 0;
      }
    }

    &-Link {
      a {
        width: 100%;
      }
    }

    &-DetailsContent {
      padding: 8px 0;

      @include desktop {
        padding: 8px;
      }
    }

    &-Tag,
    &-OfferType {
      display: inline-block;
      vertical-align: top;

      span {
        background: $greyF0F0F0;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.02em;
        padding: 4px 8px;
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 10px;
        height: 20px;
        overflow: hidden;
        vertical-align: top;

        @include desktop {
          margin-bottom: 4px;
        }
      }
    }

    &-Brand {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.0008em;
      margin-bottom: 2px;
      height: 16px;
      overflow: hidden;
    }
  }

  &_LatestPost {
    margin-bottom: 16px;

    @include desktop {
      margin-bottom: 74px;
    }

    .NewsBlogPostCard {
      padding: 0;
      margin: 0;
      border: 0 none;

      &-ContentWrapper {
        @include ultra-narrow-desktop {
          text-align: right;
        }
      }

      &-ImageWrapper {
        margin: 0 -14px;

        @include desktop {
          margin: 0;
        }

        @include ultra-narrow-desktop {
          text-align: right;
          position: static;
        }

        .Image {
          @include desktop {
            padding-bottom: 0;
            height: auto;
            vertical-align: top;

            img {
              position: static;
              vertical-align: top;
              width: auto !important;
            }
          }

          @include ultra-narrow-desktop {
            position: static;

            img {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              object-fit: cover;
              font-family: 'object-fit: cover;';
              width: 0;
              min-width: 100%;
              min-height: 100%;
            }
          }
        }
      }

      &-Details {
        background: $white;
        width: 100%;
        padding: 36px 36px 43px;
        margin-top: -30px;

        @include desktop {
          bottom: 9.5%;
          top: 7.9%;
          left: auto !important;
          right: 0;
          padding: 15px 63px;
          max-width: 607px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transform: none;
          position: absolute;
          margin-top: 0;
        }

        @include narrow-desktop {
          top: 25px;
          bottom: 25px;
        }

        @include ultra-narrow-desktop {
          position: relative;
          right: 0;
          top: auto;
          bottom: auto;
          margin-top: 36px;
          margin-left: 114px;
          margin-bottom: 36px;
          padding: 15px 26px 15px 40px;
          display: inline-block;
          vertical-align: top;
          width: auto;
          max-width: 100%;
        }
      }

      &-Title {
        font-family: $font-BlackerProDisplay;
        font-weight: 700;
        font-size: 44px;
        line-height: 48px;
        letter-spacing: -0.004em;
        padding: 0;
        margin-bottom: 31px;

        @include desktop {
          font-weight: 500;
        }

        a {
          font-size: 44px;

          &:hover {
            text-decoration: none;
          }
        }
      }

      &-DescriptionWrapper {
        color: $grey727272;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        display: block;
        margin-bottom: 39px;

        p {
          color: $grey727272;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.0008em;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .News_LatestPost_link {
      &.Button {
        width: auto;
        align-self: flex-start;
      }
    }
  }

  &-Promo {
    grid-column: 1 / -1;
    margin-bottom: 22px;

    @include desktop {
      margin-bottom: 31px;
    }

    @media (min-width: 360px) and (max-width: 1279px) {
      order: -1;
    }

    @media (min-width: 481px) and (max-width: 810px) {
      order: 0;
    }

    .ProductListWidget & {
      display: none !important;
    }

    > p {
      width: 100%;
      margin-bottom: 0;
    }

    .slick-slide {
      width: 100% !important;
    }
  }

  &-PromoListing {
    position: relative;
    width: 100%;

    &_image {
      vertical-align: top;
      min-height: 429px;

      @include ultra-narrow-desktop {
        min-height: 329px;
      }

      img {
        .SpecialOffers-Promo & {
          vertical-align: top;
          object-fit: cover;
          position: absolute;
          left: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    &_content {
      background: $white;
      position: absolute;
      top: 27px;
      right: 0;
      bottom: 27px;
      padding: 12px;
      width: 100%;
      max-width: 486px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px 10px 10px 56px;

      @include ultra-narrow-desktop {
        max-width: 386px;
        padding-left: 36px;
      }
    }

    &__title {
      font-family: $font-BlackerProDisplay;
      font-weight: bold;
      font-size: 44px;
      line-height: 48px;
      letter-spacing: -0.004em;
      margin-bottom: 24px;

      @include ultra-narrow-desktop {
        font-size: 36px;
      }

      a {
        font-size: 44px;

        @include ultra-narrow-desktop {
          font-size: 36px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__text {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
      margin-bottom: 24px;
    }

    &__link {
      a {
        background: $black;
        color: $white;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        text-decoration: none !important;
        letter-spacing: 0.04em;
        min-width: 224px;
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: $white;
          color: $black;
        }
      }
    }
  }
}