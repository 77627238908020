/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.ConfigurationSelect {
    .ChevronIcon {
        fill: black;
    }

    &-Title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: var(--price-color);
        font-weight: 700;
        font-size: 14px;
        vertical-align: middle;
        margin-bottom: 0;
        letter-spacing: -0.0008em;
        margin-top: 16px;

        .Option {
            margin-left: auto;
            margin-right: 6px;
        }

        .ChevronIcon {
            margin-right: 0;
        }
    }

    &-ItemList {
        display: none;

        &_isOpened {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 50;
            background: white;
            margin-left: -10px;
            margin-right: -8px;
            border: 1px solid black;
            top: 38px;
            min-width: calc(100% + 18px);
            width: calc(100% + 18px);

            @include mobile {
                background: rgba(0, 0, 0, 0.4);
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 102;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        .Item {
            padding: 5px 30px 5px 8px;
            display: flex;
            justify-content: space-between;

            .LabelContainer {
                display: flex;
            }

            .Color {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }

            @include mobile {
                padding: 12px 30px 12px 16px;
                background-color: white;
            }

            :not(:first-child) {
                margin-left: 8px;
            }

            &_isSelected {
                :not(:first-child):after {
                    content: "";
                    width: 8px;
                    height: 4px;
                    border-color: transparent transparent #000000 #000000;
                    border-style: solid;
                    border-width: 1px;
                    display: block;
                    transform: rotate(-45deg);
                    margin-top: -3px;
                    position: absolute;
                    right: -20px;
                    top: 8px;

                    @include mobile {
                        margin-top: -6px;
                        right: -18px;
                    }
                }
            }
        }
    }
}