/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';

.Events {
  &-Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;

    @include mobile-small {
      grid-template-columns: 1fr;
    }

    @include desktop {
      grid-template-columns: repeat(4, 1fr);
    }

    @include ultra-narrow-desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    &:before {
      @include desktop {
        content: "HC Insider";
        font-family: $font-BlackerProDisplay;
        color: $black;
        opacity: 0.03;
        position: absolute;
        font-size: 145px;
        line-height: 1;
        letter-spacing: 4px;
        top: 27%;
        left: -60px;
        z-index: -1;
      }

      @include tablet {
        top: 35%;
      }

      @media (min-width: 1600px) {
        left: -7%;
      }

      @media (min-width: 1800px) {
        left: -180px;
      }
    }

    nav {
      grid-column: 1/-1;
    }

    .CategoryPagination {
      @include mobile {
        display: flex;
      }
    }
  }

  .CategoryListing-Description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 25px;
    text-align: center;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
}