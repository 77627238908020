/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SlickSlider {
    &-Placeholder {
        display: grid;
        grid-column-gap: 10px;

        @include desktop {
            grid-template-columns: repeat(5, 1fr);
        }

        @include mobile {
            grid-template-columns: repeat(2, 1fr);
        }

        &::before {
          content: '';
          display: none;
        }
    }
    &-FigureImage {
        display: block;
        padding-bottom: 120%;
    }
    .slick-slider {
        .slick-arrow {
          display: none !important;
        }
      
        .slick-dots {
          bottom: 10px;
      
          li {
            width: auto;
            height: auto;
            margin: 0;
      
            @include desktop {
              margin: 0 3px;
            }
      
            &.slick-active {
              margin: 0 4px;
            }
      
            &:before {
              display: none !important;
            }
      
            button {
              width: 16px;
              height: 16px;
              border: 1px solid transparent;
              background: transparent;
              border-radius: 50%;
              padding: 0;
      
              @include desktop {
                width: 18px;
                height: 18px;
              }
      
              &:before {
                width: 6px;
                height: 6px;
                content: "";
                background: $black;
                border-radius: 50%;
                top: 4px;
                left: 4px;
                opacity: 1;
      
                @include desktop {
                  width: 10px;
                  height: 10px;
                  top: 3px;
                  left: 3px;
                }
              }
            }
      
            &.slick-active {
              button {
                border-color: $black;
      
                &:before {
                  width: 10px;
                  height: 10px;
      
                  @include mobile {
                    top: 2px;
                    left: 2px;
                  }
                }
              }
            }
          }
        }
      }
}