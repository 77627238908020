/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Slider {
    --translateX: 0;
    --translateY: 0;
    --animation-speed: 0;
    --slider-height: 0;
    --slide-height: 0;
    --slide-width: 0;
    --height-transition-speed: 0;

    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;

    transition: height var(--height-transition-speed);

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        height: 100%;

        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), var(--translateY), 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }

        &_isVertical {
            flex-direction: column;
        }

        &:last-child {
            .ProductGallery-Slider:not(.ProductGallery-Slider_isImageZoomPopupActive) & {
                @include desktop {
                    height: calc(100% - 152px);
                }
            }
        }
    }

    &-Image {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Crumbs {
        height: auto;
        min-height: 16px;
        position: absolute;
        margin: auto;
        display: grid;
        grid-gap: 6px;
        width: 100%;
        grid-template-columns: repeat(auto-fit, 18px);
        justify-content: center;
        inset-block-end: 12px;
    }

    &-Crumb {
        border-radius: 50%;
        will-change: width, height;
        transition: width 300ms, height 300ms;
        width: 18px;
        height: 18px;
        border: 1px solid transparent;
        background: transparent;
        padding: 0;
        position: relative;

        &:before {
            width: 10px;
            height: 10px;
            content: "";
            background: $black;
            border-radius: 50%;
            top: 3px;
            left: 3px;
            opacity: 1;
            position: absolute;
        }

        &_isActive {
            border-color: $black;

            &:before {
                width: 10px;
                height: 10px;
            }
        }
    }

    &-Arrows {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 13px;

        @include desktop {
            bottom: auto;
            top: 50%;
            transform: translateY(50%);
        }
    }

    &-Arrow {
        background: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        position: absolute;
        inset-block-end: 0;
        width: 12px;
        height: 18px;
        padding: 3px;

        .ProductGallery & {
            padding: 0;
            background: none;
            width: 6px;
            height: 12px;
        }

        @include desktop {
            padding: 6px;
            inset-block-end: calc(100% - 29px);
            width: 29px;
            height: 58px;

            .ProductGallery & {
                padding: 0;
                inset-block-end: calc(100% - 23px);
                width: 17px;
                height: 46px;
            }
        }

        @include mobile {
            width: 26px;
            height: 48px;
        }

        .ChevronIcon {
            width: 100%;
            height: 100%;
        }

        &_isPrev {
            inset-inline-start: 16px;

            &:dir(ltr) {
                transform: rotate(180deg) scale(-1);
            }
        }

        &_isNext {
            inset-inline-end: 16px;

            &:dir(rtl) {
                transform: rotate(180deg) scale(-1);
            }
        }

        &_isDisabled {
            opacity: .5;
        }
    }
}
