/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.deal {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;

    @include desktop {
        flex-direction: row;
        justify-content: center;
        gap: 88px;
        max-width: 1118px;
        margin: 20px auto 0;
    }

    @include mobile {
        overflow: hidden;
        margin-top: 20px;
    }

    $b: &;

    &__products {
        display: flex;
        flex-direction: column;
        width: 100%;

        &_top {
            border: 1px solid $black;
            display: flex;
            flex-direction: column;

            @include desktop {
                flex-direction: row;
            }

            .ProductCard {
                margin: 0;
                padding: 32px 16px;
                position: relative;

                @include desktop {
                    flex: 1;
                    padding: 10px 31px;
                }

                &:first-child {
                    @include mobile {
                        padding-top: 24px;
                    }
                }

                &:last-child {
                    @include mobile {
                        padding-bottom: 24px;
                    }
                }

                &:not(:first-child) {
                    border-top: 1px solid $black;

                    @include desktop {
                        border-top: 0 none;
                        border-left: 1px solid $black;
                    }

                    &:before {
                        background: $white;
                        border: 1px solid $black;
                        border-radius: 50%;
                        box-sizing: border-box;
                        content: "+";
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: -20px;
                        left: 50%;
                        margin-left: -20px;
                        padding: 0;
                        font-size: 23px;
                        z-index: 1;

                        @include desktop {
                            top: 50%;
                            left: -20px;
                            margin-left: 0;
                            margin-top: -20px;
                        }
                    }
                }

                &-AdditionalContent {
                    display: none;
                }

                &-Link {
                    flex-direction: row;
                    align-items: center;
                    gap: 16px;
                }

                &-FigureReview {
                    width: 92px;
                    height: 96px;
                    flex-shrink: 0;
                    padding-bottom: 0;
                    display: block;
                }

                &-Picture {
                    padding-bottom: 96px;
                }

                &-BrandAttributeValue {
                    font-weight: 400;
                }

                &-Name {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 22px;
                    height: auto;
                    margin-bottom: 16px;

                    @include desktop {
                        height: 44px;
                        margin-bottom: 0;
                    }
                }

                &-PriceWrapper,
                &-Price {
                    min-height: 0;
                }

                &-Figure {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }

        &_stylishplus {
            //@include rule(align-self, (xl: center, lg: center));
            //@include rule(width, (xl: 26px, lg: 22px));
            //@include rule(margin, (xl: 0 20px, lg: 0 -3px 150px));

            flex-shrink: 0;
        }
    }

    &__icon {
        &_plus {
            //@include rule(align-self, (xl: center, lg: center));
            //@include rule(width, (xl: 26px, lg: 22px));
            //@include rule(margin, (xl: 0 20px, lg: 0 -3px 150px));

            flex-shrink: 0;
            position: absolute;
            left: 0;
            top: 50%;
        }

        &_summ {
            //@include rule(width, (xl: 40px, lg: 33px));
            //@include rule(margin, (xl: 0 20px, lg: 0));

            flex-shrink: 0;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;

        @include desktop {
            width: auto;
            min-width: 230px;
        }
    }

    &__wrapper {
        width: 100%;
    }

    &__prices {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        letter-spacing: -0.2px;
        margin-bottom: 8px;

        &:before {
            content: attr(data-label);
        }
    }

    &__price {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        display: block;

        &_old {
            text-decoration: line-through;
        }

        &:not(:last-child) {
            //@include rule(margin-right, (lg: 12px));
        }

        &:not(:first-child) {
            //@include rule(margin-top, (xl: 4px, lg: 0));
            //@include rule(font-weight, (lg: 500));
            //@include rule(font-size, (lg: 24px));

            color: color(red);
        }
    }

    &__offer {
        width: 163px;
    }

    &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 12px;
        gap: 12px;
        letter-spacing: -0.2px;
        margin-bottom: 8px;

        @include desktop {
            margin-bottom: 16px;
        }
    }

    &__buttons {
        width: 100%;
    }

    &__button {
        width: 100%;
    }

    &__button {
        &.button {
            //@include rule(padding, (lg: 0 20px));
            //@include rule(height, (lg: 40px));

            img,
            svg {
                width: 25px;
                height: 25px;
            }
        }
    }

    &__product {
        flex: 1;
    }
}

.BundleItem {
    margin-bottom: 45px;

    &-Wrapper {
        padding: 0;

        &.ContentWrapper {
            @include mobile {
                padding: 0;
            }
        }
    }

    .ProductLinks-Title {
        font-family: $font-BlackerProDisplay;
    }
}