/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.StorePage{
    overflow-x: hidden;
    overflow-y: auto;

    .stores__header{
        display:block
    }
    .select.js-store-select.choices__input{
        display: block;
        width: 100%;
        border-color: #dadada;
        background-color: #fff;
        margin: 0;
        color: #000;
        height: 44px;
        padding: 0 10px;
        appearance: none;
        background-image: url("../../public/assets/images/global/arrow_select_store.svg");
        background-repeat: no-repeat;
        background-position: 96% 50%;
    }
    .list-checkbox {
        display: block;
        cursor: pointer;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        white-space: nowrap;

        @media (min-width: 1050px) and (max-width: 1400px) {
            display: inline-block;
            margin-left: 0;
            margin-right: 43px;
            margin-bottom: 10px;
        }
    }
    .leaflet-container {
        width: 100%;
        height: 100vh;

        //@include rule(height, (xl: 398px, lg: 242px));

        &.map {
            //@include rule(max-height, (xl: 100%, lg: 142px));
        }
    }

    &-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        margin: 0 0 20px;
        text-align: center;

        @include desktop {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
            margin-bottom: 18px;
        }
    }

    &-description {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        max-width: 958px;
        margin: 0 auto 42px;
        text-align: center;

        @include desktop {
            margin-bottom: 85px;
        }
    }

    &-category {
        margin-bottom: 13px;

        @include desktop {
            display: flex;
            align-items: center;
            gap: 95px;
            margin-bottom: 26px;
        }

        @include ultra-narrow-desktop {
            display: block;
        }

        &_current {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.2px;
            margin-bottom: 13px;
            flex-shrink: 0;

            @include desktop {
                margin-bottom: 0;
            }

            @include ultra-narrow-desktop {
                margin-bottom: 13px;
            }
        }

        &_stores {
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar-track {
                border-radius: 14px;
                background-color: $color-grey-E5;
            }

            &::-webkit-scrollbar {
                height: 7px;
                background-color: $color-grey-E5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 14px;
                background-color: $black;
            }

            &_wrapper {
                display: flex;
                gap: 32px;
            }

            a {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.2px;
                flex-shrink: 0;

                &.current {
                    font-weight: 500;
                    text-decoration: underline;
                }
            }
        }
    }

    &-store_slider {
        margin-bottom: 25px;

        @include mobile {
            margin-left: -14px;
            margin-right: -14px;
        }

        @include desktop {
            margin-bottom: 69px;
        }

        &.slick-slider {
            .slick-dots {
                position: static;
                margin-top: 15px;
            }
        }
    }

    &-tabs {
        padding: 14px 14px 41px;
        margin-bottom: 53px;

        @include desktop {
            padding: 0 0 15px;
            margin-bottom: 78px;
        }

        @include mobile {
            margin-right: -14px;
            margin-left: -14px;
        }

        &:before {
            content: "";
            width: 10000px;
            bottom: 0;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background: $icegrey;

            @include desktop {
                top: 38px;
            }
        }

        &_header {
            border-bottom: 1px solid rgba(0, 0, 0, 0.14);

            @include desktop {
                border-bottom: 0 none;
                display: flex;
                gap: 83px;
                padding: 0 125px;
            }
        }
    }

    &-sections_by_floor {
        margin-left: -12px !important;
        margin-right: -12px !important;

        @include mobile {
            padding-bottom: 19px;
        }

        .SliderWidget {
            &-Figure {
                padding: 0 12px;
                height: 100%;
            }

            &-FigureImage {
                display: none !important;
            }

            &-Figcaption {
                position: static !important;
                transform: none !important;
                background: $white;
                border: 1px solid $color-grey-E5;
                padding: 36px 44px;
                height: 100%;

                h2 {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    margin: 0 0 27px;
                }

                h3 {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.0008em;
                    margin: 0 0 24px;

                    @include desktop {
                        margin-bottom: 32px;
                    }
                }

                h4 {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.0008em;
                    margin: 0 0 8px;

                    @include desktop {
                        margin-bottom: 10px;
                    }
                }

                p {
                    color: $color-grey-A1;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.0008em;
                    margin: 0 0 8px;

                    @include desktop {
                        margin-bottom: 10px;
                    }

                    strong {
                        font-weight: 500;
                        margin-bottom: -4px;
                        display: block;

                        @include desktop {
                            margin-bottom: -6px;
                        }
                    }
                }
            }
        }

        .slick {
            &-track {
                display: flex !important;
            }

            &-slide {
                height: inherit !important;

                > div {
                    height: 100% !important;
                }
            }

            &-dots {
                bottom: -8px;

                @include desktop {
                    position: static !important;
                    margin-top: 20px;
                }
            }
        }
    }

    &-tab {
        &_header {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            letter-spacing: -0.0008em;
            height: 46px;

            @include desktop {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.2px;
                height: auto;
                padding: 8px 0;
                font-weight: 400;

                &:not(.active) {
                    cursor: pointer;
                }
            }

            &:not(:first-child) {
                @include mobile {
                    border-top: 1px solid rgba(0, 0, 0, 0.14);
                }
            }

            &:after {
                @include mobile {
                    content: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6V18' stroke='black' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M6 12H18' stroke='black' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
                }
            }

            &.active {
                @include desktop {
                    font-weight: 600;
                }

                &:after {
                    content: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12H18' stroke='black' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");

                    @include desktop {
                        content: "";
                        width: 100%;
                        height: 2px;
                        background: $black;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                }
            }
        }

        &_content {
            max-height: 0;
            overflow: hidden;
            transition: max-height $ease-main;

            @include desktop {
                max-height: 100%;
                padding: 49px 0;
            }

            &.active {
                max-height: 100%;
                padding: 25px 0;
            }

            &.widget_tab,
            &.map_tab,
            &.info_tab {
                @include desktop {
                    padding-left: 125px;
                    padding-right: 125px;
                }
            }

            &.map_tab {
                > div {
                    height: 372px !important;
                }
            }

            &.widget_tab {
                @include desktop {
                    overflow: visible;
                }
            }
        }
    }

    &-store_info {
        @include desktop {
            display: flex;
            justify-content: space-between;
        }

        &_col {
            @include desktop {
                width: 56%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;


                &:not(:first-child) {
                    width: 357px;
                    flex-shrink: 0;
                    max-width: 44%;
                }
            }
        }

        &_section {
            margin-bottom: 16px;

            @include desktop {
                margin-bottom: 21px;
            }
        }

        &_title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
            margin-bottom: 8px;
        }

        &_content {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;

            p {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
            }
        }

        &_phone.Button {
            background: transparent;
            color: $black;
            margin-top: 17px;
            gap: 11px;
            font-weight: 400;
            width: 100%;

            &:before {
                content: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.0751 14.4765V17.1862C19.0761 17.4377 19.0246 17.6867 18.9238 17.9172C18.823 18.1477 18.6752 18.3546 18.4899 18.5247C18.3045 18.6947 18.0857 18.8242 17.8474 18.9048C17.6091 18.9854 17.3566 19.0153 17.106 18.9927C14.3266 18.6907 11.6568 17.7409 9.31106 16.2197C7.12868 14.8329 5.27839 12.9827 3.89161 10.8003C2.36511 8.44389 1.41514 5.76107 1.11866 2.96915C1.09609 2.71938 1.12577 2.46764 1.20582 2.22996C1.28587 1.99229 1.41454 1.77389 1.58362 1.58866C1.7527 1.40343 1.9585 1.25544 2.18791 1.15411C2.41732 1.05278 2.66532 1.00032 2.91611 1.00009H5.62584C6.06419 0.995773 6.48915 1.151 6.82151 1.43683C7.15388 1.72267 7.37097 2.11961 7.43232 2.55366C7.54669 3.42084 7.7588 4.27229 8.06459 5.09177C8.18612 5.41507 8.21242 5.76642 8.14038 6.1042C8.06834 6.44198 7.90098 6.75203 7.65813 6.99761L6.51101 8.14473C7.79683 10.406 9.66916 12.2784 11.9305 13.5642L13.0776 12.4171C13.3232 12.1742 13.6332 12.0069 13.971 11.9348C14.3088 11.8628 14.6601 11.8891 14.9834 12.0106C15.8029 12.3164 16.6544 12.5285 17.5215 12.6429C17.9603 12.7048 18.361 12.9258 18.6475 13.2639C18.9339 13.6019 19.0861 14.0335 19.0751 14.4765Z' stroke='black' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
            }

            &:not([disabled]):hover {
                background-color: $black;
                color: $white;

                &:before {
                    content: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.0751 14.4765V17.1862C19.0761 17.4377 19.0246 17.6867 18.9238 17.9172C18.823 18.1477 18.6752 18.3546 18.4899 18.5247C18.3045 18.6947 18.0857 18.8242 17.8474 18.9048C17.6091 18.9854 17.3566 19.0153 17.106 18.9927C14.3266 18.6907 11.6568 17.7409 9.31106 16.2197C7.12868 14.8329 5.27839 12.9827 3.89161 10.8003C2.36511 8.44389 1.41514 5.76107 1.11866 2.96915C1.09609 2.71938 1.12577 2.46764 1.20582 2.22996C1.28587 1.99229 1.41454 1.77389 1.58362 1.58866C1.7527 1.40343 1.9585 1.25544 2.18791 1.15411C2.41732 1.05278 2.66532 1.00032 2.91611 1.00009H5.62584C6.06419 0.995773 6.48915 1.151 6.82151 1.43683C7.15388 1.72267 7.37097 2.11961 7.43232 2.55366C7.54669 3.42084 7.7588 4.27229 8.06459 5.09177C8.18612 5.41507 8.21242 5.76642 8.14038 6.1042C8.06834 6.44198 7.90098 6.75203 7.65813 6.99761L6.51101 8.14473C7.79683 10.406 9.66916 12.2784 11.9305 13.5642L13.0776 12.4171C13.3232 12.1742 13.6332 12.0069 13.971 11.9348C14.3088 11.8628 14.6601 11.8891 14.9834 12.0106C15.8029 12.3164 16.6544 12.5285 17.5215 12.6429C17.9603 12.7048 18.361 12.9258 18.6475 13.2639C18.9339 13.6019 19.0861 14.0335 19.0751 14.4765Z' stroke='white' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    &-location {
        margin: 0 -14px 32px;

        @include desktop {
            margin: 0 0 68px;
        }

        &_slider_title,
        &_title {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.2px;
            margin-bottom: 12px;
            text-align: center;

            @include desktop {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.003em;
                margin-bottom: 17px;
            }
        }

        &_slider {
            .SliderWidget-FigureImage .Image-Image {
                @include desktop {
                    width: auto !important;
                }
            }

            .SliderWidget-Figcaption {
                background: $white;
                width: calc(100% - 32px);
                padding: 12px 24px;
                margin: -68px auto 0;
                text-align: center;

                @include mobile {
                    position: relative !important;
                    transform: none !important;
                    top: auto;
                    left: auto;
                }

                @include desktop {
                    margin: 0;
                    width: 100%;
                    max-width: 496px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    padding: 87px 43px 101px 58px;
                    text-align: left;
                    left: auto !important;
                }

                h2 {
                    font-family: $font-BlackerProDisplay;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 32px;
                    letter-spacing: -0.003em;
                    margin: 0 0 12px;

                    @include desktop {
                        font-size: 40px;
                        line-height: 56px;
                        letter-spacing: 0.02em;
                        margin-bottom: 18px;
                    }
                }

                h3 {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.0008em;
                    margin: 0 0 10px;

                    @include desktop {
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 22px;
                        margin-bottom: 18px;
                    }
                }

                a {
                    background: $black;
                    color: $white;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    padding: 13px 50px;
                    text-decoration: none !important;
                    display: block;
                    text-align: center;

                    @include desktop {
                        display: inline-block;
                    }

                    &:not([disabled]):hover {
                        background: $white;
                        color: $black;
                    }
                }
            }

            &.slick-slider .slick-dots {
                position: static;
                margin-top: 16px;
            }
        }
    }

    &-categories {
        position: relative;
        padding: 25px 0;
        margin: 0 -14px 30px;

        @include desktop {
            padding: 40px 0;
            margin: 0 -8px 30px;
        }

        &:before {
            content: "";
            width: 10000px;
            bottom: 0;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background: $icegrey;
        }

        &_slider_title,
        &_title {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.2px;
            margin: 0 0 16px;
            text-align: center;

            @include desktop {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.003em;
                margin-bottom: 24px;
            }
        }

        .SliderWidget {
            &-Figure {
                position: relative;
                padding: 0 8px;

                @include mobile-small {
                    padding: 0;
                }

                @include desktop {
                    padding-bottom: 52px;
                }
            }

            &-Figcaption {
                padding: 20px 16px;
                text-align: center;
                width: auto;
                top: auto;
                transform: none !important;

                @include mobile {
                    position: static !important;
                }

                @include desktop {
                    background: $white;
                    left: 16px !important;
                    right: 16px !important;
                    bottom: 0;
                    padding: 8px 10px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    margin: 0 0 4px;
                }

                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.0008em;
                    margin: 0 auto;
                    max-width: 471px;

                    &.mobile {
                        margin-bottom: 0;
                    }
                }

                a {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    text-decoration: underline;
                    display: inline-block;
                }
            }
        }

        .slick-dots {
            position: static;

            @include desktop {
                margin-top: 20px;
            }
        }

        > p {
            margin: 0;
        }
    }

    &-popular_products {
        margin: 0 0 16px;

        h2 {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.2px;
            margin: 0 -14px 21px;
            text-align: center;
            position: relative;
            overflow: hidden;

            @include desktop {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.003em;
                margin: 0 0 50px;
            }

            span {
                background: $white;
                padding: 0 20px;
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                height: 1px;
                background: $black;
                display: block;
            }
        }

        .slick-dots {
            position: static;
            margin-top: 24px;
        }

        .ProductListWidget {
            padding: 0;
        }
    }
}

.stores {
    display: block;

    //@include rule(padding-top, (xl: 25px, lg: 0));

    $b: &;

    &__header {
        display: block;
    }

    &__title {
        //@include rule(font-size, (xl: 60px, lg: 20px));

        //font-family: $font-second;
        line-height: 1;
        letter-spacing: .06em;

        //@include screen(lg) {
        //    text-align: center;
        //    padding: 18px 0;
        //}
    }

    .container {
        display: block;
    }

    // Left

    &__left {
        display: block;

        //@include rule(margin-top, (xl: 25px, lg: 0));
    }

    &__checkboxes {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;

        @include desktop {
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 38px;
            flex-wrap: wrap;
            gap: 16px;
        }

        @media (min-width: 1050px) and (max-width: 1400px) {
            display: block;
            padding: 10px 30px 0;
        }
    }

    &__filter {
        //@include rule(max-width, (xl: 386px, lg: 100%));
        //@include rule(margin-top, (xl: 16px, lg: 22px));
    }

    &__list {
        //@include rule(margin-top, (xl: 43px, lg: 22px));
    }

    // Right

    &__map-wrapper {
        //@include rule(padding, (xl: 0 13px, lg: 0));

        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        max-height: 0;
        transition: max-height 1s;
        transition-duration : 0.5s;

        &.is-active {
            max-height: 372px;
        }
    }

    &__map-spacer {
        //@include rule(padding, (xl: 36px 0 0, lg: 0));
    }

    &__map-container {
        //@include rule(height, (xl: 298px, lg: auto));
        //@include rule(border, (xl: 1px solid $color-grey-49, lg: 0));
        //@include rule(display, (xl: flex, lg: block));

        position: relative;

        //@include screen(lg) {
        //    background: $color-grey-41;
        //    padding: 0 10px 10px;
        //    box-sizing: border-box;
        //}
    }

    &__tooltip-close {
        font-size: 0;
        padding: 21px;
        flex-shrink: 0;
        cursor: pointer;

        &:hover {
            opacity: .5;
        }

        svg, img {
            width: 14px;
            height: 14px;
        }
    }

    .list-checkbox {
        display: block;
        cursor: pointer;
        flex-shrink: 0;
        white-space: nowrap;
        margin-bottom: 12px;

        @include desktop {
            margin-bottom: 0;
        }

        //&:not(:first-child) {
        //    @include desktop {
        //        margin-left: 16px;
        //    }
        //}

        &__input {
            display: none;

            &:checked {
                + .list-checkbox__box {
                    background: $black;

                    .list-checkbox {
                        &__tick {
                            margin-right: 12px;
                            font-size: 0;
                            position: relative;
                            box-sizing: border-box;
                            border: 1px solid transparent;
                            background: transparent;
                            transition: background $ease-main;
                            width: 13px;
                            height: 13px;

                            &:after {
                                content: url("data:image/svg+xml,%0A%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8333 1L4.5 8.33333L1.16667 5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                transition: all 0.25s ease-out;
                            }
                        }

                        &__text {
                            color: $white;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        &__box {
            background: $greyF8F8F8;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            min-height: 40px;
            justify-content: center;
            padding: 9px;
        }

        &__text {
            color: $black000F0F;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin: 0 !important;
        }
    }
}
.store-tooltip {
    //@include rule(padding, (xl: 27px 33px 33px, lg: 0 14px 0));
    //@include rule(background, (xl: white, lg: transparent));
    //@include rule(width, (xl: 280px, lg: auto));
    //@include rule(font-size, (xl: 12px, lg: 11px));

    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;
    line-height: 25px;
    letter-spacing: 0.06em;
    display: none !important;

    $b: &;

    &__title {
        //@include rule(font-size, (xl: 12px, lg: 11px));

        font-weight: 600;
        line-height: 25px;
        letter-spacing: .06em;
    }

    &__schedule {
        //@include rule(font-size, (xl: 12px, lg: 11px));
        //@include rule(margin-top, (xl: 3px, lg: 5px));

        line-height: 25px;
        letter-spacing: .06em;

        li {
            display: flex;

            &::before {
                display: none;
            }
        }

        span {

            &:first-child {
                width: 80px;
                margin-right: 3px;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: .06em;
        text-decoration-line: underline;
        //transition: opacity $ease-main;
        margin-top: 26px;

        &:hover {
            opacity: .5;
        }

        svg, img {
            width: 25px;
            height: 10px;
            margin-left: 16px;
        }
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 14px;
        height: 14px;
        fill: black;
        cursor: pointer;
        //transition: opacity $ease-main;
        display: none !important;

        &:hover {
            opacity: .5;
        }
    }
}
.shop-list {
    display: block;

    @include desktop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 9.5%;
    }

    $b: &;

    &__list {
        max-height: 348px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 34px;
        margin-bottom: 24px;

        @include desktop {
            width: 50%;
            flex-shrink: 0;
            max-width: 490px;
            max-height: 372px;
            margin-bottom: 0;
        }
    }

    &__shop {
        //@include rule(width, (xl: (1/3)*100%, lg: 100%));
        //@include rule(padding, (xl: 36px 13px 0, lg: 0));

        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        display: flex;
        gap: 20px;
        margin-bottom: 20px;

        &:not(:first-child) {
            //@include rule(margin-top, (xl: 0, lg: 16px));
        }
        &::before {
            display: none;
        }
    }

    &__checkbox {
        width: 14px;
        height: 14px;
        border: 1px solid $black;
        opacity: 0.25;
        border-radius: 50%;
        background: $white;
        position: absolute;
        left: 5px;
        top: 5px;
    }

    &__wrapper {
        //background: $color-grey-41;
        //transition: border-color $ease-main;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        font-feature-settings: 'pnum' on, 'lnum' on;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding-left: 39px;
        position: relative;

        &.is-active {
            .shop-list__checkbox {
                border-width: 4px;
                opacity: 1;
            }
        }
    }

    &__count {
        //@include rule(width, (xl: 29px, lg: 21px));
        //@include rule(height, (xl: 40px, lg: 29px));
        //@include rule(font-size, (xl: 16px, lg: 14px));
        //@include rule(left, (xl: -5px, lg: 3px));
        //@include rule(top, (xl: -5px, lg: -7px));
        //@include rule(line-height, (xl: 33px, lg: 25px));

        font-style: normal;
        font-weight: 700;
        letter-spacing: .06em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        flex-shrink: 0;
        color: white;
        position: absolute;
        text-align: center;

        &_text {
            color: white;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;

        @include desktop {
            font-size: 18px;
            line-height: 26px;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.0008em;
        flex-grow: 1;

        @include desktop {
            line-height: 16px;
        }

        p {
            margin: 0;
        }
    }

    &__link {
        color: $color-grey-A1;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.0008em;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 4px;
        text-decoration: none !important;
    }

    &__photo {
        width: 100px;
        height: 100px;
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;

        img {
            //@include fill-img;
        }
    }

    &__button {
        //@include rule(margin-top, (xl: 22px, lg: 11px));
        //@include rule(padding, (xl: 0 17px, lg: 0 11px));

        height: 34px;
        line-height: 32px;
        //border-color: $color-grey-37;
        font-size: 12px;
        font-weight: 600;
        //transition: background-color $ease-main, border-color $ease-main, color $ease-main;
        opacity: 1 !important;

        #{$b}__shop:hover & {
            border-color: black;
        }

        #{$b}__shop.is-active & {
            background: black;
            border-color: black;
            color: white;
        }
        &:not([disabled]) {
            &:focus,
            &:hover {
                color: black;
            }
        }
    }
}
.map {
    display: block;
    height: 100%;
    position: relative;
    z-index: 1;
    width: 100%;
    flex-grow: 1;
    border-radius: 2px;
    overflow: hidden;

    //@include screen(lg) {
    //    height: 142px;
    //    margin-top: 10px;
    //}
}

.leaflet-touch .leaflet-bar, .leaflet-touch .leaflet-control-layers {
    border: none;
    box-shadow: none !important;
}

.leaflet-bar {
    box-shadow: none;
    background: transparent;
}

.leaflet-bar a {
    border: 0 !important;
    //transition: opacity $ease-main;
    //color: $color-basic;
    width: 30px !important;
    height: 30px !important;
    border-radius: 2px !important;
    background: white !important;
    line-height: 30px !important;
    //font-family: $font-main;

    &:last-child {
        margin-top: 10px;
    }

    &:hover {
        opacity: .5;
    }
}

.leaflet-popup-content-wrapper {
    border-radius: 3px;
}

.leaflet-popup-content {
    margin: 0;
    padding: 13px 20px;
    font-size: 14px;
}

.leaflet-container .leaflet-control-attribution { display: none; }

.leaflet-left {
    left: auto;
    right: 0;

    .leaflet-control {
        margin-left: 0;
        margin-right: 10px;
    }
}

.leaflet-top {
    top: auto;
    bottom: 0;

    .leaflet-control {
        margin-top: 0;
        margin-bottom: 10px;
    }
}
.Stores-PlaceHolder {
    background-image: var(--placeholder-image);
    background-size: var(--placeholder-size);
    animation: var(--placeholder-animation);
}


