/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../../src/style/abstract/variables';
@import '../../../../../../../../src/style/abstract/media';
@import '../../../../../../../../src/style/abstract/button';
@import '../../../../../../../../src/style/abstract/loader';
@import '../../../../../../../../src/style/abstract/icons';
@import '../../../../../../../../src/style/abstract/parts';

:root {
  --blog-post-card-title-weight: 600;
  --blog-post-card-title-size: 18px;
  --blog-post-card-description-height: 100px;
  --blog-post-card-border: 1px solid lightgray;
}

.Magazine {
  &BlogPostCard {
    .Magazine-LatestGrid & {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      padding: 24px 0;
      border-top: 1px solid $grey;
      border-bottom: 1px solid $grey;
      margin-bottom: 4px;

      @include desktop {
        margin-bottom: -1px;
        padding: 41px 0;
      }

      &_isLoading {
        padding: 80% 0 0;
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        box-shadow: var(--attraction-shadow);
      }

      &-ContentWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        @include desktop {
          gap: 50px;
        }
      }

      &-Title {
        font-size: 16px;
        font-weight: var(--blog-post-card-title-weight);
        line-height: 24px;
        letter-spacing: -0.2px;

        @include desktop {
          font-size: var(--blog-post-card-title-size);
          font-weight: var(--blog-post-card-title-weight);
          line-height: 26px;
          margin-bottom: 20px;
        }

        a {
          font-size: 16px;

          @include desktop {
            font-size: var(--blog-post-card-title-size);
          }
        }
      }

      &-DescriptionWrapper {
        color: $grey727272;
        display: none;

        @include desktop {
          display: block;
        }
      }

      &-Details {
        flex-basis: 50%;

        @include desktop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 50px;
          flex-basis: 60%;
        }
      }

      &-DetailsNumber {
        letter-spacing: -0.0008em;
        margin-bottom: 3px;

        @include desktop {
          margin-bottom: 0;
          white-space: nowrap;
        }
      }

      &-DetailsContent {
        @include desktop {
          flex-basis: 67%;
        }
      }

      &-Date {
        display: none;
      }

      &-Image {
        &.Image {
          height: 100%;
          padding-bottom: 0;
          display: block;
          max-width: 156px;
          max-height: 83px;
          margin-left: auto;

          @include desktop {
            max-width: 360px;
            max-height: 192px;
          }

          > img {
            position: relative;
            object-fit: cover;
            vertical-align: top;
          }
        }
      }

      &-ImageWrapper {
        flex-basis: 45%;
        flex-shrink: 0;

        @include desktop {
          flex-basis: 37%;
        }
      }
    }

    &-Title {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.2px;
      padding: 18px 0 6px;

      a {
        font-size: 18px;
      }
    }

    &-DescriptionWrapper {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
    }

    &-month {
      border-bottom: 1px solid $grey;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.2px;
      padding: 6px 0;
      display: none;

      @include desktop {
        padding: 14px 0;
        margin-bottom: 12px;
      }

      &:first-child {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-01 {
      + .MagazineBlogPostCard-month:not(.month-01) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-02 {
      + .MagazineBlogPostCard-month:not(.month-02) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-03 {
      + .MagazineBlogPostCard-month:not(.month-03) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-04 {
      + .MagazineBlogPostCard-month:not(.month-04) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-05 {
      + .MagazineBlogPostCard-month:not(.month-05) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-06 {
      + .MagazineBlogPostCard-month:not(.month-06) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-07 {
      + .MagazineBlogPostCard-month:not(.month-07) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-08 {
      + .MagazineBlogPostCard-month:not(.month-08) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-09 {
      + .MagazineBlogPostCard-month:not(.month-09) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-10 {
      + .MagazineBlogPostCard-month:not(.month-10) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-11 {
      + .MagazineBlogPostCard-month:not(.month-11) {
        display: block;
        grid-column: 1 / -1;
      }
    }

    &.month-12 {
      + .MagazineBlogPostCard-month:not(.month-12) {
        display: block;
        grid-column: 1 / -1;
      }
    }
  }

  &-Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;

    @include desktop {
      grid-template-columns: repeat(4, 1fr);
    }

    &_filter {
      margin-bottom: 12px;
      text-align: center;

      @include desktop {
        margin-bottom: -32px;
        text-align: right;
      }

      label {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        padding: 10px;

      }

      .selected-year {
        margin-top: 0;

        @include desktop {
          max-width: 234px;
          margin-left: auto;
          position: relative;
          z-index: 1;
        }
      }
    }
  }

  &_LatestPost {
    .MagazineBlogPostCard {
      padding: 0;
      margin: 0;
      border: 0 none;

      &-ContentWrapper {
        display: flex;
        align-items: flex-start;
        gap: 16px;

        @include desktop {
          gap: 19px;
        }
      }

      &-ImageWrapper {
        width: 156px;
        flex-basis: 156px;

        @include desktop {
          width: 230px;
          flex-basis: 230px;
        }
      }

      &-Image {
        &.Image {
          max-height: 100%;
        }
      }

      &-Details {
        flex-basis: calc(100% - 172px);
        width: calc(100% - 172px);

        @include desktop {
          display: block;
          flex-basis: calc(100% - 249px);
          width: calc(100% - 249px);
        }
      }

      &-Title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        padding: 19px 0 6px;
        margin-bottom: 0;

        @include desktop {
          font-weight: 500;
          padding-top: 27px;
        }

        a {
          font-size: 18px;
        }
      }

      &-DescriptionWrapper {
        color: $black;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        display: block;

        p {
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}