.popup {
  width: 100%;
  background: white;
  position: relative;
  padding: 0;
  border-radius: 2px;
  max-width: 808px;

  @include mobile {
    height: 100%;
    overflow: auto;
    border-radius: 0;
  }

  .MyAccountOverlay & {
    @include desktop {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 5;
      max-width: 1120px;
      max-height: 756px;
      width: 95%;
      height: 95%;
    }
  }

  &__close {
    cursor: pointer;
    transition: opacity $ease-main;
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
    height: 14px;
    z-index: 5;
    box-sizing: content-box;
    padding: 10px;

    @include desktop {
      width: 100% !important;
      height: 100% !important;
      left: 0;
      bottom: 0;
      opacity: 0 !important;
      padding: 20px;
    }

    &:hover {
      opacity: .5;
    }
  }

  &__wrapper {
    height: 100%;

    @include desktop {
      display: flex;
    }
  }

  &__images {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 50%;

    @include mobile {
      display: none;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      width: 0;
      min-width: 100%;
      min-height: 100%;
    }

    .Image {
      padding-bottom: 0;
      height: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__info {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    z-index: 1;
  }

  &__info-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 48px 20px;
    color: rgba(255, 255, 255, .8);
    fill: rgba(255, 255, 255, .8);
  }

  &__info-title {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__info-list {
    margin-top: 11px;
  }

  &__info-item {
    color: $white;
    display: flex;
    align-items: center;
    padding-left: 14px;
    margin-bottom: 0;

    &::before {
      position: absolute;
      inset-inline-start: 1em;
      inset-block-start: .07em;
    }

    svg {
      width: 13px;
      height: 10px;
      flex-shrink: 0;
      margin-right: 8px;
    }

    .Image {
      position: relative;
      width: 13px;
      height: 10px;
      flex-shrink: 0;
      margin-right: 10px;

      img {
        position: absolute;
      }
    }

    span {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
    }

    &:not(:first-child) {
      margin-top: 20px;
      margin-bottom: 0;
    }

    &::before {
      left: 0 !important;
    }
  }

  &__content {
    @include desktop {
      align-items: center;
      display: flex;
      padding: 25px 5.7%;
      width: 50%;
    }

    .Form {
      display: flex;
      flex-direction: column;
    }
  }

  &__links {
    display: flex;

    @include mobile {
      margin: 0 0 32px;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: .06em;
    border-bottom: 1px solid transparent;
    transition: font-weight $ease-main, border-color $ease-main, color $ease-main;
    color: #999;

    @include mobile {
      line-height: 34px;
      text-align: center;
      width: 50%;
    }

    &:not(:first-child) {
      margin-left: 22px;
    }

    &.is-active {
      color: black;
      font-weight: 700;
      border-color: inherit;
    }

    @include desktop {
      font-size: 16px;
      font-weight: 600 !important;
      line-height: 22px;
      color: #999;

      &.is-active {
        color: black;
      }

      &:not(:first-child) {
        margin-left: 22px;
      }
    }
    .MyAccountOverlay & {
      width: auto;
      margin-top: 0;
    }
  }

  &__forms {
    transition: height $ease-main;

    @include mobile {
      margin-top: 0;
    }

    .Header_isCheckout & {
      @include mobile {
        position: static;
      }
    }
  }

  &__form {
    display: none;
    transition: opacity $ease-main, transform $ease-main;
    opacity: 0;
    transform: translate3d(0, 10px, 0);

    .inputbox_link {
      margin-bottom: auto;
    }

    &.is-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &.is-active-passive {
      display: block;
    }
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
  }
  .RenderWhenVisible {
    display: flex;
  }
}