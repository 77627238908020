/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScrollItem {
    display: flex;
    scroll-snap-align: start;
    border: solid 1px var(--option-border-color);
    transition: border-color var(--animation-speed), filter var(--animation-speed);
    //filter: opacity(.5);
    flex: 1;
    cursor: pointer;
    width: 96px;
    height: 124px;
    border-color: transparent;
    border-radius: 2px;

    &.gift {
        height: 80px;
    }

    &.external-video {
        &:before {
            content: "";
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
            inset-block-end: 0;
            inset-inline-start: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-block-start: 15px solid transparent;
            border-inline-start: 30px solid $white;
            border-block-end: 15px solid transparent;
            transform: scale(1);
            transition: transform 150ms ease-in;
            overflow: hidden;
            z-index: 1;
        }
    }

    &+.CarouselScrollItem {
        margin-inline-start: var(--carousel-scroll-gap);
    }

    &_isActive {
        filter: opacity(1);
        border-color: var(--option-border-color);

        &.gift {
            border: none;
            border-color: transparent;

            &:after {
                content: "";
                width: 60px;
                border-bottom: 1px solid $black;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                display: block;
            }

        }
    }

    &:focus,
    &:hover {
        filter: opacity(1);
    }
}