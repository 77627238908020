/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.OrderGridList {
    &-Action {
        display: flex;
        .Button {
            margin-block-end: 10px;
            margin-inline-end: 10px;

            &_isDisabled {
                pointer-events: none;
                opacity: .25;
                cursor: not-allowed;
            }
        }
    }
    table {
        th {
            text-align: center;
        }
        tbody {
            td {
                text-align: center;
            }
        }
    }
}