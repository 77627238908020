/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

.SearchField {
    margin: 8px auto;

    @include mobile {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
    }

    @include desktop {
        display: block;
        width: 20%;
        max-width: 305px;
        margin-right: 0;
        margin-left: 35px;
    }

    @include ultra-narrow-desktop {
        width: 38%;
        margin-left: 24px;
        max-width: 200px;
    }

    @media (min-width: 1450px) {
        width: 55%;
    }

    &_isVisible {
        display: block;

        @include mobile {
            margin-block-start: 16px;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
                border-radius: 7px;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        height: 24px;
        inset-inline-end: 3px;
        inset-block-start: calc(50% - 12px);
        width: 24px;
    }

    &-SearchIcon {
        @include mobile {
            display: none;
        }
    }

    &-Input {
        width: 100%;
        border-color: $black;
        border-width: 0 0 1px 0 !important;

        .Header & {
            background: var(--header-bg);
            border-color: var(--header-fc);
            color: var(--header-fc);
        }

        @include mobile {
            text-align: center;
            height: 56px;
            border-width: 1px 0 !important;
        }

        @include desktop {
            padding: 4px 2px;
        }
    }
}
