/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkbox-border-radius: var(--imported_checkboxes_border_radius, 0);
    --checkbox-border-color: var(--imported_checkboxes_border_color, var(--primary-base-color));
    --checkbox-border-color-active: var(--imported_checkboxes_border_color, var(--primary-base-color));
    --checkbox-width: var(--imported_checkboxes_width, 14px);
    --checkbox-height: var(--imported_checkboxes_height, 14px);
}

.Field {
    margin-block-start: 24px;

    @include mobile {
        margin-block-start: 14px;
    }

    &-Label {
        font-size: 12px;
        font-weight: 500;
        padding-block-end: 8px;
    }

    &-LabelContainer {
        display: flex;
        align-items: center;

        .ChevronIcon {
            width: 23px;
        }
    }

    &-CheckboxLabel{
        display: inline-flex;
        align-items: center;

        span {
            display: contents;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &-Message {
        color: var(--primary-error-color);
        font-size: 12px;
        margin-block-end: -.1em;
        margin-block-start: 6px;
    }

    &-Note {
        margin-block-end: 2px;
        font-size: 12px;
        font-weight: 600;
        color: var(--field-note);
        opacity: 0;
        height: 0;
        transition: height .1s ease-in, opacity .1s linear;
        will-change: height, opacity;

        @include mobile {
            margin-block-end: 3px;
            font-size: 14px;
        }

        &::before {
            content: '* ';
        }
    }

    &_hasError {
        input,
        textarea {
            border: 1px solid var(--primary-error-color);
        }
    }

    input::placeholder {
        color:  var(--input-color);
        opacity: .5;
    }

    input:focus + .Field-Note {
        opacity: 1;
        height: 100%;
    }

    &_style {
        &_inline {
            $size-mobile: 20px;
            $size: 28px;
            $border-color: #000;
            $input-width-mobile: 35px;
            $input-width: 35px;

            &.Field {
                display: flex;
                margin: 0;

                button {
                    position: relative;
                    width: $size-mobile;
                    height: $size-mobile;
                    border: solid 1px $border-color;

                    @include desktop {
                        width: $size;
                        height: $size;
                    }

                    &:last-of-type {
                        order: -1;
                    }
                }
            }

            input {
                height: $size-mobile;
                min-height: $size-mobile;
                width: $input-width-mobile;
                min-width: $input-width-mobile;
                border: none;
                padding: 0;
                text-align: center;

                @include mobile {
                    font-size: 14px;
                }

                @include desktop {
                    height: $size;
                    min-height: $size;
                    width: $input-width;
                    min-width: $input-width;
                }

                &:focus {
                    border: none;
                }
            }
        }
    }

    &_type {
        &_checkbox,
        &_radio {
            label {
                &:first-of-type {
                    display: inline-flex;
                    max-width: calc(100vw - 24px - 45px);
                    vertical-align: middle;

                    @include mobile {
                        max-width: calc(100vw - 28px - 45px);
                    }
                }
            }

            .input-control {
                vertical-align: middle;
            }
        }

        &_number {
            align-items: center;
            display: inline-flex;

            .AddButton,
            .SubtractButton {
                width: 24px;
            }
        }

        &_text,
        &_textarea,
        &_email,
        &_password {
            &.Field_isValid {
                input,
                textarea {
                    border: 1px solid var(--primary-success-color);
                }
            }
        }

        &_checkbox {
            span {
                font-size: 12px;
            }
        }
    }

    [type='checkbox'],
    [type='radio'] {
        position: absolute;
        opacity: 0;
        padding: 0;
        min-width: 0;
        top: -12px;
        z-index: 2;
        // min-height: 0;

        + .input-control {
            --box-color: #{$white};

            background-color: var(--box-color);
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: var(--checkbox-border-radius);
            cursor: pointer;
            display: inline-block;
            height: 21px;
            position: relative;
            padding: 0;
            transition-duration: .25s;
            transition-property: background-color, border-color;
            transition-timing-function: ease-in;
            width: 21px;
            will-change: background-color, border-color;
            margin-inline-end: 11px;
            flex-shrink: 0;

            @include desktop {
                width: var(--checkbox-width);
                height: var(--checkbox-height);
            }

            &:hover {
                @include desktop {
                    --box-color: var(--secondary-light-color);

                    border-color: var(--checkbox-border-color-active);
                }
            }
        }

        &:disabled + .input-control {
            color: var(--color-dark-gray);
            cursor: auto;
        }

        &:checked {
            + .input-control {
                border-width: 4px;
                border-color: var(--checkbox-border-color-active);

                &::after {
                    --checkmark-color: var(--primary-base-color);

                    // required for ltr + rtl support, don't change to 'inset-inline-start'
                    left: calc(50% - 5px);
                    inset-block-start: calc(50% - 1px);
                }
            }
        }
    }

    [type='radio'] {
        + .input-control {
            border-radius: 50%;
        }
    }

    [type='file'] {
        border-style: dashed;
        cursor: pointer;
        height: 180px;
        opacity: 0;
        max-width: 100%;
        z-index: 1;

        + label {
            align-items: center;
            border: 2px dashed var(--input-border-color);
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 180px;
            position: absolute;
            inset-block-start: 0;
            width: 100%;

            p {
                font-size: 16px;
                margin-block-end: 0;
            }

            span {
                color: var(--primary-base-color);
                font-size: 18px;
                font-weight: 700;
            }

            .UploadIcon {
                margin-block-end: 10px;
                height: 50px;
                width: auto;
            }

            + p {
                margin-block-start: 5px;
                margin-block-end: 0;
            }
        }
    }

    &-Labels {
        span {
            letter-spacing: 1px;
        }
    }

    &-SubLabel {
        font-weight: bold;
        font-size: 12px;
        white-space: break-spaces;
    }

    &.telephone {
        .FieldPhone {
            .Field-Label {
                position: absolute;
                left: 10px;
                top: -6px;
                cursor: text;
                color: $black;
                transition: all .2s;
                font-size: 10px;
                font-weight: 600;
                line-height: 14px;
                padding: 0 6px;
                background: $white;
                min-width: 0;
            }

            > input {
                padding-left: 103px;

                @include mobile {
                    padding-left: 94px;
                    padding-right: 7px;
                }

                @media (max-width: 350px) {
                    padding-left: 44px;
                    padding-right: 3px;
                }
            }

            .react-custom-flag-select__select__wrapper {
                width: 94px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;

                @include mobile {
                    width: 87px;
                }

                @media (max-width: 350px) {
                    width: 40px;
                }

                &:after {
                    content: "";
                    width: 1px;
                    height: 24px;
                    border-left: 1px solid $grey;
                    position: absolute;
                    top: 11px;
                    right: 0;

                    @media (max-width: 350px) {
                        height: 18px;
                        top: 15px;
                    }
                }
            }

            .react-custom-flag-select__select__button {
                background: none;
                color: $black;

                @media (max-width: 350px) {
                    vertical-align: top;
                }
            }

            .react-custom-flag-select__select__selector {
                padding: 0 12px;

                @media (max-width: 350px) {
                    padding: 0;
                }
            }

            .react-custom-flag-select__select__dropdown-flag {
                border-radius: 50%;
                overflow: hidden;
                width: 20px;
                height: 20px;

                @media (max-width: 350px) {
                    flex-shrink: 0;
                    flex: 0 0 20px;
                }
            }

            .react-custom-flag-select__select__dropdown {
                @media (max-width: 350px) {
                    flex-direction: column;
                    font-size: 0;
                    padding: 7px 0 0;
                }
            }

            .react-custom-flag-select__select__dropdown-name {
                flex: none;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                gap: 4px;

                @media (max-width: 350px) {
                    gap: 1px;
                }

                > div {
                    &:before {
                        content: "+";
                    }
                }
            }

            .react-custom-flag-select__select__dropdown-icon {
                &:before {
                    display: none;
                }

                &.react-custom-flag-select__showArrow {
                    //&:before{
                    //    content: "";
                    //    border-width: 0 1px 1px 0;
                    //    border-color: transparent $black $black transparent;
                    //    width: 6px;
                    //    height: 6px;
                    //    transform: rotate(45deg);
                    //    top: 4px;
                    //}

                    &:after {
                        content: "";
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        border-color: transparent $black $black transparent;
                        width: 6px;
                        height: 6px;
                        transform: rotate(45deg);
                        display: block;
                        position: absolute;
                        top: 4px;
                    }
                }
            }

            .react-custom-flag-select__select__options-container {
                box-shadow: none;
                border: 1px solid $black;
                background: $white;
            }

            .react-custom-flag-select__select__hover-active {
                background: $black;
            }

            .react-custom-flag-select__select__options-item {
                &.react-custom-flag-select__active {
                    background: $black;
                }
            }
        }
    }

    &.gender {
        .has-float-label {
            display: flex;
            flex-direction: column-reverse;

            input,
            select {
                &::-webkit-input-placeholder { /* Edge */
                    opacity: 0.3;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    opacity: 0.3;
                }

                &::placeholder {
                    opacity: 0.3;
                }

                + label {
                    padding: 0 0 4px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    position: static;
                    min-height: 0;
                }

                ~ svg {
                    display: none;
                }
            }
        }

        .FieldSelect-FieldLabelMobile_isExpanded {
            padding: 0;
            height: 0;
            overflow: hidden;
        }
        .FieldSelect-OptionsWrapper_isExpanded {
            @include mobile {
                position: static;
            }
        }

        .FieldSelect-Select {
            width: 0;
            height: 0;
            overflow: hidden;
            padding: 0;
            position: absolute;
            z-index: -1;
            min-height: 0;
            border: 0;
            opacity: 0;
        }
        .FieldSelect-Options {
            position: static;
            max-height: 100%;
            display: flex;
            gap: 6px;
            border: 0 none;
        }

        .FieldSelect-Option {
            border: 1px solid $grey;
            padding: 0;
            width: 48px;
            height: 48px;
            max-width: calc(33.3333% - 4px);
            font-size: 0;
            width: 100%;

            &:last-child {
                &:last-child {
                    border-color: $black;
                }
            }

            &.active {
                border-color: $black;

                ~ .FieldSelect-Option:last-child {
                    &:last-child {
                        border-color: $grey;
                    }
                }
            }

            span {
                width: 100%;
                gap: 0;
                justify-content: center;

                > img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}
