/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';

.SpecialOffer {
  .PostsDetails {
    &-Title {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.004em;
      margin: 0 0 24px;

      @media (min-width: 571px) and (max-width: 810px) {
        margin: 0;
      }

      @include ultra-wide-desktop {
        font-size: 28px;
        line-height: 40px;
      }
    }

    &-Top {
      padding-bottom: 20px;

      @media (min-width: 571px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 50px;
        padding: 11px 0 60px;
      }

      @include ultra-wide-desktop {
        padding: 11px 82px 60px;
        grid-column-gap: 142px;
      }
    }

    &-MainImage {
      @media (min-width: 571px) and (max-width: 810px) {
        margin: 0;

        .Image img {
          position: static;
          object-fit: contain;
        }
      }

      + .PostsDetails-Content {
        @media (min-width: 571px) {
          margin: 0;
          padding: 0;
        }
      }
    }

    &-Description {
      .note {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.0008em;
      }

      .SpecialOffer-button {
        background: $black;
        border: 1px solid $black;
        color: $white;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        width: 100%;
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        @include desktop {
          max-width: 291px;
        }

        &:hover {
          background-color: $white;
          color: $black;
          text-decoration: none !important;
        }
      }
    }
  }

  .ProductList {
    padding-bottom: 0;
  }

  .CategoryPagination {
    margin: 0;
    min-height: 0;
  }

  .ProductLinks-Slider.slick-slider {
    margin-bottom: 0;
  }
}