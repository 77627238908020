/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.WishlistSharedPage {
    &-Products {
        grid-template-columns: 1fr !important;

        .ProductCard-Price {
            display: flex;
            flex-direction: column;
        }
    }
}

.WishlistSharedPage {
    .WishlistItem { 
        
    &-Content {
        .ProductPrice {
            min-height: 0;

            @include desktop {
                flex-direction: row;
            }
        }
    }
    &-RowWrapper {
        align-items: flex-end;
    }
    &-ActionWrapper {
        justify-content: space-between;
        flex-direction: row;
    }
    &-AddToCart {
        &.Button {
            width: initial;
            order: -1;
        }
    }
    }
    
}
.GuestWishlist {
    .MyAccountMyWishlist {
        display: grid;

        &-ActionBar {
                width: 100%;
                order: -1;
            }
        
            .WishlistItem {
        
                &-ActionWrapper {
                    justify-content: space-between;
                    flex-direction: row;
        
                }
        
                &-AddToCart {
                    &.Button {
                        width: initial;
                        order: -1;
                    }
                }

                &-Content,
                &-ActionWrapper {
                    align-items: center;
                }
            }
    }
}