/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CmsPage {
    overflow: hidden;

    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 14px);
        }
    }

    &-Content {
        .cms-content {
            @include default-list;
        }

        h2 {
            @include mobile {
                text-align: center;
            }
        }

        .cms-title-backline {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
            position: relative;
            margin: 0 0 28px;
            text-align: center;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            @include desktop {
                margin: 0 0 28px;
            }

            &:before {
                content: "";
                display: flex;
                flex-grow: 1;
                height: 1px;
                margin-right: 16px;
                background-color: black;
            }

            &:after {
                content: "";
                height: 1px;
                flex-grow: 1;
                display: flex;
                margin-left: 16px;
                background-color: black;
            }
        }
    }

    &-SearchInnerWrapper {
        display: flex;
        margin: 0 auto;
        max-width: 600px;
        margin-bottom: 10px;

        span {
            position: absolute;
            display: flex;
            right: 3px;
            height: 100%;
            align-items: center;
            &::after {
                @include mobile {
                    font-size: 42px;
                }
                content: "\00d7";
                font-size: 24px;
                cursor: pointer;
            }
        }
    }

    mark {
        margin-inline-end: 0;
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 48px;
        //margin-block-start: calc(var(--header-total-height) + 12px);
        margin-block-end: var(--header-nav-height);
        margin-inline: auto;

        @include mobile {
            padding-inline: 14px;
            //margin-block-start: calc(var(--header-total-height) + 14px);
        }

        @include desktop {
            margin-block-start: 24px;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }

        &.CmsPage-Wrapper_page_layout_2columns-left {
            z-index: 1;
        }

        &:not(.CmsPage-Wrapper_page_width_full) {
            .ContentWrapper {
                padding: 0;
                width: 100%;
            }
        }
    }

    &-Top {
        h1 {
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.2px;
            margin: 15px -14px;
            padding: 0 14px 9px;

            @include desktop {
                margin: 0 0 51px;
                padding: 0 0 17px;
            }
        }

        border-bottom: 1px solid $grey;
        margin: 60px 0;
        padding-bottom: 60px;

        &_noSearch {
            margin: 30px 0;
            padding-bottom: 0px;
            h1 {
                @include desktop {
                    margin: 0 0 20px;
                }
            }
        }

    }

    &-Content {
        .CmsPage-Wrapper_page_layout_2columns-left & {
            @include desktop {
                display: grid;
                grid-column-gap: 20px;
                grid-template-columns: 240px auto;
            }

            @include wide-desktop {
                grid-column-gap: 31px;
                grid-template-columns: 343px auto;
            }
        }
    }

    &-Foot {
        &_layout3 {
            font-family: $font-BlackerProDisplay;
            text-align: center;
            font-size: 31px;
            line-height: 1;
            border-top: 6px solid #000;
            padding: 5px 0 21px;

            @include desktop {
                padding: 26px 0 18px;
                border-top-width: 8px;
            }

            a {
                display: inline-block;
                vertical-align: top;
                padding: 7px;
            }

            img {
                width: auto;
                vertical-align: top;

                @include mobile {
                    height: 20px;
                }
            }

            &.HeroBrandPage__main_title_layout_footer {
                padding-bottom: 30px;
                padding-top: 30px;

                @include mobile {
                    padding-bottom: 0;
                    padding-top: 20px;
                }

                img {
                    @include mobile {
                        height: auto;
                        max-height: 110px;
                    }
                }
            }
        }

        &_diorReseller {
            max-width: 320px;
            margin-inline: auto;
            margin-top: -20px;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        position: unset;
        margin-block: 16px 40px;

        @include mobile {
            margin-block: 20px 28px;
        }
    }

    h3 {
        @include mobile {
            font-size: 14px;
        }
    }

    p:empty {
        display: none;
    }

    &-BkgImage {
        display: none;

        @include desktop {
            position: absolute;
            width: 277px;
            right: 0;
            top: 511px;
            display: block;
            z-index: -1;
        }

        .ContactPage & {
            @include desktop {
                top: 217px;
            }
        }
    }

    &-LeftNav {
        @include desktop {
            background: $icegrey;
            min-height: 477px;
        }
    }

    &-LeftBlock {
        background: $icegrey;
        margin-bottom: 14px;

        @include desktop {
            background: none;
        }

        &_title {
            display: none;

            @include desktop {
                border-bottom: 1px solid $color-grey-E5;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                height: 58px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 32px;
                margin-bottom: 32px;
            }
        }

        &_link {
            padding: 0 18px 18px;

            @include desktop {
                padding: 0 32px 16px;
            }

            a {
                display: block;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
            }
        }

        .ExpandableContent {
            border: 0 none !important;

            &-Button {
                padding: 16px 18px;
            }

            &-Heading {
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
            }
        }
    }

    &-MainBlock {
        @include desktop {
            padding-right: 21%;
        }

        .ContactPage & {
            @include desktop {
                padding-right: 0;
            }

            @include wide-desktop {
                padding-right: 21%;
            }
        }
    }

    &-Faq {
        &_accordian_content {
            padding: 0 10px 20px;

            @include desktop {
                padding: 0 40px 45px 20px;
            }

            .ContactPage & {
                padding: 18px 0 44px;

                @include desktop {
                    padding: 40px 55px 50px;
                }
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &_row_title {
            background: $greyF8F8F8;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
            padding: 10px;
            margin-bottom: 3px;

            &:not(:first-child) {
                margin-top: 29px;
            }
        }
    }

    &_row_title {
        background: $greyF8F8F8;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        padding: 10px;
        margin-bottom: 16px;

        @include desktop {
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
        }

        span {
            font-weight: 400;
        }
    }

    &_row_content {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin-bottom: 28px;

        @include desktop {
            padding: 0 15px;
        }

        p {
            font-size: 14px;
            line-height: 22px;

            @include desktop {
                margin-bottom: 30px;
            }
        }

        h5 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .HeroBrandPage{
        overflow: hidden;

        &-FullWidthTop {
            margin: 0px auto 33px auto;
        }

        &-Wrapper {
            display: flex;
            flex-direction: column;

            @include desktop {
                display: grid;
                padding-inline: 0;
                grid-column-gap: 48px;
                grid-template-columns: 236px calc(100% - 284px);
            }

            @include wide-desktop {
                grid-column-gap: 53px;
                grid-template-columns: 309px calc(100% - 362px);
            }

            @include mobile {
                padding: 0;
            }

            &_hideProducts {
                display: block;

                .CategoryPage-CMS {
                    @include mobile {
                        margin-block-start: 0;
                    }
                }
            }
        }

        &-MainColTop {
            order: 0;
        }

        &-MainCol {
            order: 2;

            img {
                vertical-align: top;
            }

            .content-container {
                background: $white;
                border: 1px solid $color-grey-E5;
                padding: 24px 15px;
                margin-bottom: 30px;

                @include desktop {
                    margin: -110px 14px 30px;
                }

                @include wide-desktop {
                    padding: 65px 69px;
                    margin: -110px 61px 40px;
                }

                .title {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    letter-spacing: -0.003em;
                    margin-bottom: 10px;
                }

                .intro {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    margin-bottom: 10px;
                }

                .description {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.0008em;
                    margin-bottom: 20px;
                }

                .image-container-three {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;
                    margin-bottom: 20px;

                    @media (max-width: 360px) {
                        flex-direction: column;
                        gap: 26px;
                        margin-bottom: 26px;
                    }

                    @include wide-desktop {
                        gap: 23px;
                    }

                    img {
                        @media (min-width: 361px) {
                            width: calc(33.3333% - 10px) !important;
                        }

                        @include wide-desktop {
                            width: calc(33.3333% - 15px) !important;
                        }
                    }
                }

                .image-container-two {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;
                    margin-bottom: 20px;

                    @media (max-width: 406px) {
                        flex-direction: column;
                        gap: 26px;
                        margin-bottom: 26px;
                    }

                    @include wide-desktop {
                        gap: 23px;
                    }

                    img {
                        @media (min-width: 407px) {
                            width: calc(50% - 8px) !important;
                        }

                        @include wide-desktop {
                            width: calc(50% - 11px) !important;
                        }
                    }
                }

                .image-container-one {
                    margin-bottom: 26px;

                    @include desktop {
                        margin-bottom: 20px;
                    }
                }

                .image-container-one-mobile {
                    @include mobile {
                        min-height: 202px;
                        overflow: hidden;
                    }

                    img {
                        @include mobile {
                            object-fit: cover;
                            position: absolute;
                            object-position: center center;
                            height: 100% !important;
                        }
                    }
                }

                .image-container-123 {
                    display: flex;
                    gap: 18px;
                    justify-content: space-between;
                    margin-bottom: 26px;

                    @include mobile {
                        flex-wrap: wrap;
                    }

                    @include desktop {
                        gap: 2%;
                        margin-bottom: 20px;
                    }

                    img {
                        height: auto !important;
                        width: auto !important;
                        max-width: 100%;
                    }
                }

                .image-container-1 {
                    width: 100%;
                    margin-bottom: 22px;

                    @include desktop {
                        width: 43%;
                        margin-bottom: 0;
                    }

                    img {
                        @include mobile {
                            width: 100% !important;
                        }
                    }
                }

                .image-container-2 {
                    width: calc(50% - 9px);
                    display: flex;
                    justify-content: center;

                    @include desktop {
                        width: 26%;
                    }

                    img {
                        @include mobile {
                            width: 100% !important;
                        }
                    }
                }

                .image-container-3 {
                    width: calc(50% - 9px);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;

                    @include desktop {
                        width: 28%;
                    }

                    img {
                        @include mobile {
                            width: 100% !important;
                        }
                    }
                }
            }

            .main-banner-container {
                margin: 0 -14px;

                @include desktop {
                    margin: 0;
                }

                + .content-container {
                    margin-top: -70px;

                    @include desktop {
                        margin-top: -95px;
                    }
                }
            }

            .dior-product-slider {
                @include desktop {
                    margin: 0 14px;
                }

                @include wide-desktop {
                    margin: 0 61px;
                }

                .ProductListWidget h2 {
                    @include mobile {
                        margin-left: -14px;
                        margin-right: -14px;
                    }
                }
            }
        }

        &-MainColTop,
        &-MainCol {
            padding: 0;
            margin: 0;

            //@include desktop {
            //    padding: 0 48px;
            //    margin: 0 -48px;
            //}

            //@include wide-desktop {
            //    padding: 0 53px;
            //    margin: 0 -53px;
            //}
        }

        &__left_image {
            background: $white;
        }

        &-LeftCol {
            background: $greyF8F8F8;
            margin: 0 0 30px;

            @include desktop {
                position: relative;
                grid-row: 1/6;
                width: 100%;
                opacity: 1;
                pointer-events: all;
                display: flex;
                flex-direction: column;
                height: auto;
                overflow: visible;
                margin: 0;
                background: none;
            }

            &1,
            &2 {
                order: 1;
            }

            .nav-1 {
                > li {
                    > .title { display: block;
                        &.category { display: none; }
                    }
                }
            }
            .nav-2 {
                > li {
                    > a { display: inline-block;
                        .text { display: inline-block; }

                        &.active { font-weight: 500 }
                    }
                    &.selected {
                        > a { font-weight: 500 }
                        > .nav-3 { display: block; }
                    }
                    &.accountMenuItem-cookie-privacy-policy { display: none; }
                }
            }
            .nav-3 { display: none;
                &.opened { display: block; }
                > li {
                    > a { display: inline-block;

                        .text { border-bottom: 1px solid transparent; }
                    }
                    &.selected {
                        > a {
                            font-weight: 500;
                        }
                    }
                    &.selected.hasChildren {
                        > a::before { display: none; }
                    }
                }
            }
            .nav-4 {
                > li {
                    > a { display: inline-block;

                        .text { border-bottom: 1px solid transparent; }
                    }
                    &.selected {
                        > a {
                            font-weight: 500;
                        }
                    }
                    &.last { }
                }
            }

            ul {
                ul {
                    padding-left: 15px;
                }
            }

            .ExpandableContent.scandi_desktop_accordian-Content {
                border-bottom: 0 none;

                @include desktop {
                    background: $greyF8F8F8;
                }
            }

            .ExpandableContent-Content_isContentExpanded.scandi_desktop_accordian-ExpandableContentContent {
                border-bottom: 0 none;
            }

            .scandi_desktop_accordian-Content {
                .ExpandableContent-Button {
                    padding-left: 20px;
                    padding-right: 20px;

                    @include desktop {
                        border-bottom: 1px solid $color-grey-E5;
                        justify-content: center;
                    }

                    svg {
                        @include desktop {
                            display: none;
                        }
                    }
                }

                .ExpandableContent-Content {
                    @include desktop {
                        max-height: 100%;
                        opacity: 1;
                    }
                }

                .ExpandableContent-Heading {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;

                    @include desktop {
                        font-weight: 500;
                    }
                }
            }

            .CmsPage-Faq_accordian_content {
                padding: 13px 20px 10px;

                @include desktop {
                    padding: 26px 20px;
                }

                li {
                    margin: 0;

                    &:before {
                        display: none;
                    }
                }

                a {
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    margin-bottom: 27px;
                    display: block;

                    @include desktop {
                        margin-bottom: 14px;
                    }
                }
            }
        }

        &__left_image {
            display: none;

            @include desktop {
                display: block;
            }

            img {
                @include desktop {
                    width: auto !important;
                    max-width: 100%;
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        &-SectionPlaceholder {
            background-image: var(--placeholder-image);
            background-size: var(--placeholder-size);
            animation: var(--placeholder-animation);
            margin: 0 0 10px;
            padding-bottom: 30%;

            @include mobile {
                padding-bottom: 60%;
            }

            &:first-child {
                padding-bottom: 40%;

                @include mobile {
                    padding-bottom: 100%;
                }
            }
        }
        &-HeadingPlaceholder {
            background-image: var(--placeholder-image);
            background-size: 1200px 100%;
            animation: var(--placeholder-animation);
            margin: 0 0 10px;
            padding-bottom: 10%;
            padding-left: 20% ;
            padding-right: 20% ;

            @include mobile {
                padding-bottom: 60%;
            }

            &:first-child {
                padding-bottom: 40%;

                @include mobile {
                    padding-bottom: 100%;
                }
            }
        }

        p {
            margin-bottom: 0;
        }

        &__main {
            &_image {
                margin: 0 -14px 36px;

                @include desktop {
                    margin: 0 0 15px;
                }
            }

            &_title {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.003em;
                margin: 0 16px 12px;

                @include desktop {
                    margin: 0 0 20px;
                    padding: 0 10px;
                }

                &_layout1 {
                    background: $black;
                    color: $white;
                    font-family: $font-BlackerProDisplay;
                    text-align: center;
                    font-size: 31px;
                    line-height: 1;
                    padding: 7px;
                    margin: 0 -14px 14px;

                    @include desktop {
                        margin: 0 0 22px;
                    }

                    img {
                        filter: brightness(0) invert(1);
                        max-width: 85px;

                        @include desktop {
                            max-width: 104px;
                        }
                    }
                }

                &_layout2 {
                    color: $white;
                    font-family: $font-BlackerProDisplay;
                    text-align: center;
                    font-size: 31px;
                    line-height: 1;
                    padding: 7px;
                    margin-bottom: 20px;
                    overflow: hidden;

                    @include desktop {
                        margin-bottom: 0;
                        padding: 0;
                    }

                    img {
                        width: auto !important;
                        margin: -34px 0 -40px;

                        @include desktop {
                            margin: 0;
                            vertical-align: top;
                        }
                    }
                }
            }

            &_description {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
                margin: 0 16px 26px;

                @include desktop {
                    margin: 0 0 26px;
                    padding: 0 10px;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        &__bottom {
            &_title {
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.2px;
                text-align: center;
                margin-bottom: 20px;

                &_layout1 {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: -0.2px;
                    margin-bottom: 14px;
                    text-align: center;

                    @include desktop {
                        font-size: 24px;
                        line-height: 32px;
                        letter-spacing: -0.003em;
                        margin-bottom: 23px;
                    }
                }

                img {
                    width: 62% !important;

                    @include desktop {
                        width: auto !important;
                        vertical-align: top;
                    }
                }
            }

            &_text {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.2px;
                margin-bottom: 30px;

                @include desktop {
                    text-align: center;
                }
            }
        }
    }

    .dior-expertise-new {
        .HeroBrandPage {
            &__main {
                &_title {
                    &_layout1 {
                        margin-bottom: 50px;
                    }
                }

                &_image {
                    margin-bottom: 30px;

                    @include desktop {
                        margin-bottom: 20px;
                    }
                }
            }

            &__bottom {
                &__description {
                    font-size: 20px;
                    line-height: 30px;
                    max-width: 990px;
                    margin: 0 auto 70px;
                    text-align: center;

                    @include mobile {
                        padding: 0 20px;
                    }
                }
            }

            &-TwoCol {
                @include desktop {
                    display: flex;
                    align-items: center;
                    margin-bottom: 80px;
                }

                > div {
                    @include desktop {
                        flex: 50%;
                    }
                }

                &_content {
                    padding: 30px 20px 60px;

                    @include mobile {
                        text-align: center;
                    }

                    @include desktop {
                        padding: 0 40px;
                    }

                    h2 {
                        font-weight: 400;
                        font-size: 27px;
                        line-height: normal;
                        display: inline-block;
                        margin: 0 0 20px;

                        @include desktop {
                            max-width: 498px;
                        }
                    }

                    p {
                        font-size: 20px;
                        line-height: 30px;

                        @include desktop {
                            max-width: 510px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                &_reverse {
                    @include desktop {
                        flex-direction: row-reverse;
                        text-align: right;
                    }

                    .HeroBrandPage-TwoCol_content {
                        p {
                            @include desktop {
                                margin-left: auto;
                            }
                        }
                    }
                }

                &_video {
                    padding-bottom: 100%;
                    position: relative;

                    @include desktop {
                        padding-bottom: 50%;
                    }

                    .RenderWhenVisible {
                        position: static;
                    }

                    .SliderWidget {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: 0;
                    }

                    .Slider-Arrows {
                        width: 100%;

                        @include mobile {
                            bottom: auto;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .Slider-Arrow {
                        background: none;
                        overflow: hidden;

                        @include mobile {
                            width: 14px;
                            height: 48px;
                            bottom: -24px;
                        }

                        img {
                            display: none;
                        }

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            left: -8px;
                            top: 16px;
                            border-style: solid;
                            border-width: 2px;
                            border-color: transparent $black $black transparent;
                            transform: rotate(-45deg);
                            border-radius: 3px;
                            width: 14px;
                            height: 14px;

                            @include desktop {
                                border-width: 3px;
                                width: 22px;
                                height: 22px;
                            }
                        }

                        &_isPrev {
                            &:before {
                                transform: rotate(135deg);
                                left: 9px;

                                @include mobile {
                                    left: 4px;
                                }
                            }
                        }

                        &_isDisabled {
                            display: none;
                        }
                    }
                }
            }

            &-ThreeCol {
                @include desktop {
                    margin-top: 150px;
                    padding: 0 36px;
                }

                @include mobile {
                    margin: 0 16px;
                    overflow-y: auto;

                    &::-webkit-scrollbar-track {
                        border-radius: 5px;
                        background-color: none;
                    }

                    &::-webkit-scrollbar {
                        height: 0;
                        background-color: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background-color: none;
                    }
                }

                &_wrapper {
                    display: flex;

                    @include desktop {
                        flex-wrap: wrap;
                        justify-content: center;
                    }
                }

                &_col {
                    padding: 0 16px;

                    @include desktop {
                        width: 33.3333%;
                    }

                    @include mobile {
                        width: 80%;
                        flex-shrink: 0;
                    }

                    &:first-child:last-child {
                        @include mobile {
                            width: 100%;
                            padding: 0;
                        }

                        .HeroBrandPage-ThreeCol_content {
                            @include mobile {
                                left: 0;
                                right: 0;
                            }
                        }
                    }
                }

                &_content {
                    position: absolute;
                    left: 16px;
                    right: 16px;
                    bottom: 0;
                    text-align: center;

                    h2 {
                        margin: 0;
                        font-size: 24px;
                        line-height: 1.1;
                        padding: 15px 10px;
                        background: rgba(255,255,255,0.4);

                        @include wide-desktop {
                            font-size: 42px;
                            padding: 25px 10px;
                        }
                    }

                    a {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        img {
            vertical-align: top;
        }
    }
}
