/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CurrencySwitcher {
    --selector-width: 56px;

    border-block-end: 1px solid var(--primary-divider-color);
    @include desktop {
        margin-inline-start: 12px;
    }

    @include mobile {
        margin: 0 16px;
    }

    .Field {
        margin-block-start: 0;

        &Select {
            @include desktop {
                width: var(--selector-width);
                padding-inline-start: 12px;
            }

            .ChevronIcon {
                inset-inline-end: 0;

                .Header & {
                    fill: var(--header-fc);
                }
            }

            &-Select {
                border: none;
                font-size: 14px;
                font-weight: 400;
                margin: 0;
                opacity: 1;
                padding-inline-start: 0;
                line-height: 23px;

                .Header & {
                    background: var(--header-bg);
                    color: var(--header-fc);
                }

                @include desktop {
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            &::after {
                height: 13px;
                inset-inline-end: 0;
                width: 13px;
            }
        }
    }

    @include desktop {
        border-block-end: none;

        .FieldSelect {
            &-Select {
                border: none;
                font-size: 12px;
                padding: 0;
            }

            &-Options {
                overflow-y: auto;
                width: min-content;
                min-width: var(--selector-width);

                &_isExpanded {
                    border-block-start: 1px solid $black;

                    .Header & {
                        background: var(--header-bg);
                        border-color: var(--header-fc);
                    }
                }
            }

            &-Option {
                line-height: 14px;
                padding: 6px 12px;
                font-size: 12px;
                min-height: 0;

                .Header & {
                    background: var(--header-bg);
                    color: var(--header-fc);
                }

                &_isSelceted {
                    &:after {
                        border-color: transparent transparent var(--header-fc) var(--header-fc);
                        margin-top: -3px;
                    }
                }
            }
        }
    }
}
