/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --load-more-button-background: #{$white};
    --load-more-button-color: #{$default-primary-base-color};
    --load-more-button-border: 2px solid #{$default-secondary-base-color};
}

.PostsListing {
    overflow: hidden;

    &-Wrapper {
        @include mobile {
            padding-top: 5px;
        }
    }

    &-ColumnWrapper {
        display: flex;

        @include mobile {
            flex-wrap: wrap;
        }

        @include desktop {
            gap: 31px;
        }

        @include ultra-narrow-desktop {
            gap: 15px;
        }
    }

    &-Sidebar {
        @include mobile {
            order: 3;
            width: 100%;
        }

        @include desktop {
            flex-shrink: 0;
        }

        @include ultra-narrow-desktop {
            width: 250px;
        }
    }

    &-Grid {
        display: grid;
        grid-gap: 11px 10px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fill, 1fr);
        flex-wrap: wrap;

        @include mobile {
            order: 1;
        }

        //@include after-mobile {
        //    padding: 0 20px;
        //    grid-template-columns: repeat(2, 1fr);
        //    flex-basis: 70%;
        //}

        @include desktop {
            grid-gap: 15px 20px;
            width: 100%;
        }

        p {
            margin-bottom: 0;
        }
    }

    &-Title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.2px;
        padding-top: 21px;
        margin-bottom: 12px;

        @include desktop {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
            padding-top: 10px;
        }
    }

    &-Description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin-bottom: 17px;

        @include desktop {
            max-width: 958px;
            margin: 0 auto;
            width: 100%;
            text-align: center;
            margin-bottom: 42px;
        }
    }

    &-Search {
        @include mobile {
            display: none;
        }
    }

    &-LoadMoreWrapper {
        padding-top: 40px;
        text-align: center;
        @include mobile {
            order: 2;
            width: 100%;
        }
        flex-basis: 100%;
    }

    & &-LoadMoreButton {
        margin: auto;
        color: var(--load-more-button-color);
        background-color: var(--load-more-button-background);
        border: var(--load-more-button-border);
        border-radius: 3px;
    }
}
