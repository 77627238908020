/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.StoreItems {
    &-Item {
        font-size: 12px;
        padding: 6px 12px;
        white-space: nowrap;
        width: 100%;

        .Header & {
            color: var(--header-fc);
        }

        @include desktop {
            text-align: left;
            display: flex;
            align-items: center;
            gap: 16px;
            justify-content: space-between;
            cursor: pointer;
        }

        @include mobile {
            color: $white;
            font-size: 14px;
            padding: 0;

            &:not(:last-child) {
                border-right: 1px solid $white;
                padding: 0 20px 0 0;
                margin: 0 20px 0 0;
            }

            &.active {
                font-weight: 700;
            }
        }

        &.active {
            cursor: default;

            &:after {
                content: "";
                width: 8px;
                height: 4px;
                border-color: transparent transparent $black $black;
                border-style: solid;
                border-width: 1px;
                display: block;
                transform: rotate(-45deg);
                margin-top: -3px;

                .Header & {
                    border-color: transparent transparent var(--header-fc) var(--header-fc);
                }
            }
        }
    }
}
