/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
.ResetAttributes {

    &-AttributeValue {
        border: 1px solid $grey;
        padding: 8px 10px;
        margin-right: 9px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        @include desktop {
            margin-bottom: 7px;
        }
    }

    &-AttributeText{
        font-size: 12px;
        margin-right: 10px;

        @include desktop {
            font-size: 14px;
        }
    }

    &-CloseIcon {
        height: 16px;
        width: 16px;

        .CloseIcon {
            height: 16px;
            width: 16px;
        }
    }

    &-Title {
        @include mobile {
            display: none;
        }

        @include desktop {
            margin-block-start: 0;
        }
    }

    &-MobileTitle {
        @include mobile {
            padding-inline-start: 16px;
            font-weight: 700;
            padding-block-start: 20px;
        }

        @include desktop {
            display: none;
        }
    }
}
