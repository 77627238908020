/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.ProductActions {
  &-Top {
    display: flex;
    justify-content: space-between;
  }
  &-Modiface {
    img {
      width: 90px;
    }
  }
    &-AddToCartWrapper {
      align-items: self-end;
      .ProductActions-Section_type_alerts {
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .ProductAlerts-InStock,
      .ProductAlerts-InStock.Button{
        margin-block-end: 0;
      }
    }
}
