/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --video-play-background: #000;
    --video-play-icon-fill: #{$white};
    --video-play-icon-transform: scale(1);
}

.VideoThumbnail {
    //display: flex;
    //justify-content: center;
    //align-items: center;

    &-Button {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-position: 50% 50%;
        object-fit: contain;

        &:active,
        &:hover {
            --video-play-icon-transform: scale(1.2);
        }
    }

    &-Thumbnail {
        position: static;
    }

    &-PlayIcon {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-block-start: 30px solid transparent;
        border-inline-start: 60px solid var(--video-play-icon-fill);
        border-block-end: 30px solid transparent;
        transform: var(--video-play-icon-transform);
        transition: transform 150ms ease-in;
        overflow: hidden;
    }
}
