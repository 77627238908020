/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --menu-item-height: 20px;
  --menu-total-height: 59px;
}

@mixin subcategory-visible {
  pointer-events: all;
  z-index: 10;
  opacity: 1;
  transform: translateX(0);
}

.Menu {
  &-CompareLink {
    color: var(--color-black);
    font-size: 14px;
    font-weight: 400;

    display: flex;
    align-items: center;

    .CompareIcon {
      margin-inline-end: 12px;

      &:hover {
        fill: var(--color-black);
      }
    }

    &Wrapper {
      border-block-end: 1px solid var(--primary-divider-color);
      padding-block-start: 17px;
      padding-block-end: 16px;
      padding-inline: 0;
      margin: 0 16px;

      @include mobile {
        padding: 13px 0;
      }
    }
  }

  &-ExpandedState {
    --plus-minus-icon-size: 18px;

    display: inline-block;
    float: inline-end;

    &:dir(rtl) {
      float: inline-start;
    }

    position: absolute;
    inset-inline-end: 0;

    @include desktop {
      display: none;
    }
  }

  &-Item {
    padding-inline-start: 0;

    @include desktop {
      height: 46px;
      margin: 0;
    }

    &::before {
      content: none;
    }

    &Caption {

      @include mobile {
        margin-inline: 16px;
      }

      @include mobile {
        padding-block: 16px;
      }

      &_isSecondLevel {
        font-weight: 700;
      }

      &_type {
        &_main {
          letter-spacing: 0.04em;

          @include desktop {
            padding: 13px 12px;
          }

          @include ultra-narrow-desktop {
            padding: 13px 6px;
            font-size: 12px;
          }
        }

        &_subcategory {
          @include desktop {
            display: inline-block;
          }
        }
      }
    }

    &List {
      @include desktop {
        display: flex;

        .Menu-SubMenu & {
          display: block;
        }
      }

      &_type_main {
        @include desktop {
          height: 46px;
        }
      }

      &_type_subcategory {
        @include mobile {
          padding-block: 8px;
        }
      }
    }
  }

  &-ItemCaption_Bold {
    font-weight: 700;
  }

  &-ItemCaption_Pink {
    color: $foux;
  }

  &-ItemCaption_Gold {
    color: $gold;
  }

  &-ItemCaption_Green {
    color: $green;
  }

  &-ItemCaption_Green2 {
    color: $green2;
  }

  &-ItemCaption_KoreanPink {
    color: #ffd7d7;
  }

  &-Link {
    display: contents;
    font-weight: 400;

    .Menu-MainCategories & {
      @include desktop {
        color: var(--header-fc);
      }
    }

    @include desktop {
      display: block;

      &:hover {
        text-decoration: none;
      }
    }

    &_isHovered {
      background: $black;
      color: $white !important;

      .Menu-MainCategories & {
        @include desktop {
          background: var(--header-fc);
          color: var(--header-bg) !important;
        }
      }
    }
  }

  &-MainCategories {
    margin: 0 auto;

    @include desktop{
      width: fit-content;
    }
  }

  &-MenuWrapper {
    .Header > & {
      @include desktop {
        max-width: 100%;
      }
    }
  }

  &-SubCategories {
    @include desktop {
      padding-inline: 32px;
      padding-block-start: 29px;
      padding-block-end: 70px;
      display: flex;
      justify-content: space-between;
    }

    &-Links {
      @include desktop {
        margin-top: 42px;
        margin-bottom: 6px;
      }
    }

    &-Link {
      @include desktop {
        background: $greyF0F0F0;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        padding: 4px 12px;
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-block;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &Wrapper {
      @include desktop{
        background: $white;
        border-top: 1px solid var(--header-fc);
        border-bottom: 1px solid $black;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
      }
        display: none;

        &_isVisible{
            display: block;
        }
    }

    &WrapperInner {
      @include desktop{
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
      }
    }

    .RenderWhenVisible {
      @include desktop {
        margin-left: auto;
      }
    }

    &-promos {
      @include desktop {
        display: flex;
        gap: 21px;
        margin-bottom: 16px;
      }

      @include ultra-narrow-desktop {
        width: calc(100% - 420px);
        flex-shrink: 1;
      }
    }

    &-promo {
      @include desktop {
        position: relative;
      }
    }

    &-promo_content {
      @include desktop {
        background: $white;
        color: $black;
        text-align: center;
        padding: 8px;
      }

      h3 {
        @include desktop {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: -0.0008em;
          margin: 0;
        }

        @include ultra-narrow-desktop {
          font-size: 12px;
          line-height: 16px;
        }
      }

      a {
        @include desktop {
          font-size: 13px;
          line-height: 20px;
          text-decoration: underline;
        }
      }

      &-wrapper {
        @include desktop {
          padding: 0 15px;
          margin-top: -30px;
        }
      }
    }

    &-promo_image {
      img {
        vertical-align: top;

        @include ultra-narrow-desktop {
          width: auto !important;
          height: auto !important;
          max-width: 100%;
        }
      }
    }
  }

  &-Sub {
    &ItemWrapper {
      break-inside: avoid;
      overflow: hidden;

      @include desktop {
        width: 233px;
      }

      @include ultra-narrow-desktop {
        width: 150px;
      }
    }

    &Menu {
      display: none;

      &_isVisible {
        background: var(--color-gray);
        display: block;
        padding-inline: 16px;
      }

      @include desktop {
        background: none;
        display: block;
        padding: 0;
      }

      .Menu-SubMenu & {
        padding-block-end: 0;
      }

      .Menu-Link {
        @include desktop {
          line-height: 22px;
          margin-bottom: 16px;
        }

        @include ultra-narrow-desktop {
          font-size: 12px;
          margin-bottom: 8px;
        }
      }

      >.Menu-ItemList {
        @include desktop {
          columns: 2;

          >.Menu-SubItemWrapper {
            break-inside: avoid;
            overflow: hidden;
            width: unset;
          }

          &_height-small {
            .Menu-Link {
              line-height: 1.12;
              margin-bottom: 4px;
            }

            >.Menu-SubItemWrapper {
              break-inside: avoid;
              overflow: hidden;
              width: unset;
            }

            columns: 2;
          }

          &_height-normal {
            .Menu-Link {
              line-height: 1.25;
              margin-bottom: 8px;
            }

            >.Menu-SubItemWrapper {
              break-inside: avoid;
              overflow: hidden;
              width: unset;
            }

            columns: 2;
          }

          &_multi_view_menu {
            >.Menu-SubItemWrapper {
              break-inside: avoid;
              overflow: hidden;
              width: unset;
            }

            &_height-small {

              .Menu-Link {
                line-height: 1.12;
                margin-bottom: 4px;
              }

              >.Menu-SubItemWrapper {
                break-inside: avoid;
                overflow: hidden;
                width: unset;
              }

              columns: 2;
            }

            &_height-normal {
              .Menu-Link {
                line-height: 1.25;
                margin-bottom: 8px;
              }

              >.Menu-SubItemWrapper {
                break-inside: avoid;
                overflow: hidden;
                width: unset;
              }

              columns: 2;
            }

            &_cols-3 {
              columns: 3;

              >.Menu-SubItemWrapper {
                break-inside: avoid;
                overflow: hidden;
                width: unset;
              }

              &_height-small {
                .Menu-Link {
                  line-height: 1.12;
                  margin-bottom: 4px;
                }

                >.Menu-SubItemWrapper {
                  break-inside: avoid;
                  overflow: hidden;
                  width: unset;
                }

                columns: 3;
              }

              &_height-normal {
                .Menu-Link {
                  line-height: 1.25;
                  margin-bottom: 8px;
                }

                >.Menu-SubItemWrapper {
                  break-inside: avoid;
                  overflow: hidden;
                  width: unset;
                }

                columns: 3;
              }
            }

            &_cols-4 {
              columns: 4;

              >.Menu-SubItemWrapper {
                break-inside: avoid;
                overflow: hidden;
                width: unset;
              }

              &_height-small {
                .Menu-Link {
                  line-height: 1.12;
                  margin-bottom: 4px;
                }

                >.Menu-SubItemWrapper {
                  break-inside: avoid;
                  overflow: hidden;
                  width: unset;
                }

                columns: 4;
              }

              &_height-normal {
                .Menu-Link {
                  line-height: 1.25;
                  margin-bottom: 8px;
                }

                >.Menu-SubItemWrapper {
                  break-inside: avoid;
                  overflow: hidden;
                  width: unset;
                }

                columns: 4;
              }
            }

            &_cols-5-no-banner {
              columns: 5;
              max-width: calc(100vw - 60px);

              >.Menu-SubItemWrapper {
                break-inside: avoid;
                overflow: hidden;
                width: unset;
              }

              &_height-small {
                .Menu-Link {
                  line-height: 1.12;
                  margin-bottom: 4px;
                }

                >.Menu-SubItemWrapper {
                  break-inside: avoid;
                  overflow: hidden;
                  width: unset;
                }

                columns: 5;
                max-width: calc(100vw - 60px);
              }

              &_height-normal {
                .Menu-Link {
                  line-height: 1.25;
                  margin-bottom: 8px;
                }

                >.Menu-SubItemWrapper {
                  break-inside: avoid;
                  overflow: hidden;
                  width: unset;
                }

                columns: 5;
                max-width: calc(100vw - 60px);
              }
            }

            &_cols-6-no-banner {
              columns: 6;
              max-width: calc(100vw - 60px);

              >.Menu-SubItemWrapper {
                break-inside: avoid;
                overflow: hidden;
                width: unset;
              }

              &_height-small {
                columns: 6;
                max-width: calc(100vw - 60px);

                >.Menu-SubItemWrapper {
                  break-inside: avoid;
                  overflow: hidden;
                  width: unset;
                }

                .Menu-Link {
                  line-height: 1.12;
                  margin-bottom: 4px;
                }

              }

              &_height-normal {
                columns: 6;
                max-width: calc(100vw - 60px);

                >.Menu-SubItemWrapper {
                  break-inside: avoid;
                  overflow: hidden;
                  width: unset;
                }

                .Menu-Link {
                  line-height: 1.25;
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }

      &_Title {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0 0 16px;
      }
    }
  }
}
