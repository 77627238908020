/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.FieldMiultiSelectDropDown {
    --grey-background: #f5f5f5;
    --grey-border: #ddd;
    --grey-text: #898989;
    --grey-dark: #444;
    --main-light: #d9f2fb;
    --main: #00a9e0;
    --main-dark: #007da6;
    --success: #7fd81e;
    --error: #ff5100;
    --warning: #f6c223;
    --body: #081f2c;
    position: relative;
    display: inline-block;
  
    &-label {
      display: block;
      margin-bottom: 6px;
      font-weight: 600;
    }
  
    &-selection {
      position: relative;
      cursor: pointer;
      padding: 12px 5px;
      border: 1px solid $black;
      background: #fff;
      min-height: 48px;
      width: 320px;
      max-width: 100%;

      &.items {
        padding-top: 7px;
      }
    }

    .FieldSelect {
        &-Search {
          opacity: 0;
          position: absolute;
          top: -1px;
          left: -1px;
          z-index: 99999;
          width: calc(100% + 2px);

          &_isOpen {
            opacity: 1;
          }
        }
    }
  
    &-select {
      &:focus {
        outline: 0;
  
        & .selection {
          box-shadow: 0 0 1px 1px var(--main);
        }
      }
    }

    &-List {
      margin-top: 15px;

      &:empty {
        display: none;
      }

      .value {
          padding: 8px 15px;
      }
    } 

    .value {
        &:hover {
            svg {
            opacity: 1;
            }
        }
      position: relative;
      display: inline-block;
      padding: 10px;
      padding-right: 30px;
    }
  
    &-multiple {
      margin-right: 5px;
      margin-top: 5px;
      border: 1px solid $black;
      font-size: 12px;
      line-height: 14px;
      padding: 10px;
    }
  
    &-delete {
      position: absolute;
      top: 1px;
      right: 10px;
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 10px;
      cursor: pointer;
    }
  
    &-placeholder {
      padding: 5px 10px;
      color: var(--grey-text);
      position: static;
    }
  
    &-arrow {
      position: absolute;
      top: 10px;
      right: 5px;
      display: block;
      padding: 10px;
      font-size: 10px;
      color: var(--grey-text);
    }

  &-selectedItems {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.0008em;
    margin-top: 24px;

    strong {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0;
      margin-right: 8px;
    }
  }
  
    &-options {
      position: absolute;
      top: 48px;
      z-index: 5;
      left: 0;
      right: 0;
      border: solid $black;
      border-width: 0 1px 1px;
      background: #fff;
      max-height: 267px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 0;
      opacity: 0;
      width: 320px;
      max-width: 100%;

      &_isOpen {
        height: auto;
        opacity: 1;
      }
    }
  
    &-option {
      padding: 10px 15px;
      cursor: pointer;
      font-size: 14px;
  
      &.focused {
        background: var(--grey-background);
      }
    }
  
    &-checkbox {
      content: "";
      vertical-align: top;
      display: inline-block;
      width: 16px;
      height: 16px;
      padding: 2px;
      border: 1px solid var(--grey-border);
      border-radius: 2px;
      margin: 2px 12px 0 0;
      color: #fff;
      font-size: 10px;
  
      .selected & {
        border: 4px solid $black;
      }

      svg {
        .FieldMiultiSelectDropDown & {
          display: none;
        }
      }
    }
    svg {
      display: block;
      width: 1em;
      height: 1em;
      fill: currentColor;
    }
  }
  