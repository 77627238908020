/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --brand-details-background:  #{$white};
}

.BrandDetails {
    display: grid;
    grid-template-columns: auto minmax(100px, 160px);
    grid-gap: 12px;
    background-color: var(--brand-details-background);
    margin-block-end: 12px;

    @include mobile {
        grid-template-columns: auto minmax(100px, 210px);
        grid-gap: 14px;
        margin-block-end: 14px;
        display: none;
    }

    &-Picture {
        mix-blend-mode: multiply;
        height: 72px;
        background: none;
    }

    &-Heading {
        margin-block-end: 6px;
        margin-block-start: 0;

        @include mobile {
            margin-block-end: 7px;
        }
    }

    &-Description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;

        @include mobile {
            padding: 28px;
        }
    }
}
