/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Breadcrumb {
    $crumb-padding: 0;

    display: inline-block;
    flex-shrink: 0;
    padding-inline-end: $crumb-padding;
    margin-bottom: 0;

    @include mobile {
        font-size: 14px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:not(:last-child) {
        &:after {
            content: "";
            width: 4px;
            height: 4px;
            border-style: solid;
            border-width: 1px;
            border-color: $white $black $black $white;
            display: inline-block;
            transform: rotate(-45deg);
            margin: 0 14px 0 10px;
            vertical-align: middle;
        }

        .Breadcrumb-Link {
            color: $color-grey-A1;
        }
    }

    .ChevronIcon {
        filter: invert(57%) sepia(0%) saturate(1%) hue-rotate(105deg) brightness(93%) contrast(90%);
        display: inline-block;
        margin-inline-start: $crumb-padding;
        width: 12px;
        height: 12px;
        inset-block-end: -2px;
    }

    &:last-child {
        .ChevronIcon {
            display: none;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        color: $black;
        display: inline-block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.0008em;
        vertical-align: middle;
    }
}
