/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, .44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &_isVisible {
        .SearchOverlay {
            &-Results {
                display: block;
                padding: 16px;
            }
        }
    }

    &-Results {
        background: var(--color-white);
        display: none;
        z-index: 110;
        width: 100%;
        position: absolute;

        @include desktop {
            width: 100%;
            box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
            right: 0;
        }

        @include mobile {
            height: var(--window-height-except-header);
            overflow-y: auto;
        }

        .SearchField_isActive & {
            padding: 30px 14px !important;

            @include desktop {
                padding: 30px !important;
            }
        }

        @include after-tablet {
            width: 850px;
        }

        li {
            &:before {
                display: none;
            }
        }

        #autocomplete-container {
            @include desktop {
                display: grid;
                grid-template-columns: 2fr 3fr;
                grid-column-gap: 30px;
            }

            .autocomplete-attributes {
                flex-direction: column;

                @include desktop {
                    display: block;
                    grid-row: 1/6;
                }

                h4 {
                    font-weight: 700;
                    margin-bottom: 8px;
                }

                li {
                    margin: 0;
                    padding: 0;
                }

                > li {
                    margin: 0 0 30px;
                    padding: 0;

                    > ul {
                        max-height: 190px;
                        overflow-y: auto;

                        @include desktop {
                            max-height: 210px;
                        }

                        > li {
                            a {
                                display: block;
                                padding: 4px 0;
                            }
                        }
                    }
                }
            }

            li {
                &:last-child {
                    @include desktop {
                        margin-bottom: 0;
                    }
                }
            }

            .products-title {
                font-weight: 700;
                margin-bottom: 8px;
            }
        }

        #autocomplete-products {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 8px;

            li {
                padding: 0;
                min-width: 0;
                max-width: 100%;

                &:hover {
                    background: none;
                }
            }

            .search-item {
                align-items: flex-start;
                justify-content: flex-start;
                margin: 0;
            }

            .search-image {
                padding: 0;
            }

            .search-name {
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                letter-spacing: -0.0008em;
                margin-block-end: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 32px;
                width: 100%;
            }

            .search-brand {
                font-size: 12px;
                margin-bottom: 2px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
            }

            .price_container {
                line-height: 22px;
                min-height: 23px;

                > .price {
                    padding: 0;
                }

                span {
                    &:empty {
                        display: none;
                    }

                    &.old_price {
                        color: grey;
                        font-size: 12px;
                        margin-right: 10px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
