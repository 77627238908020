/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.cms_scandi_tabs{
    &_buttons {
        display: flex;
        justify-content: space-between;
    }

    &_button {
        border-bottom: 3px solid transparent;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
        padding: 12px 6px 9px;
        cursor: pointer;
        width: 100%;
        text-align: center;

        @include desktop {
            padding-left: 24px;
            padding-right: 24px;
        }

        @include ultra-narrow-desktop {
            padding-inline: 0;
            font-size: 12px;
        }

        @include narrow-desktop {
            padding-inline: 0;
            font-size: 12px;
        }

        @media (min-width: 1280px)
        and (max-width: 1450px) {
            padding-inline: 0;
        }

        &.active {
            font-weight: bold;
            border-bottom-color: $black;
        }
    }

    &_contents {
        position: relative;
        padding: 39px 0;

        @include desktop {
            padding-left: 16px;
            padding-right: 16px;
        }

        &:before {
            content: "";
            background: $icegrey;
            position: absolute;
            width: 10000px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &_content {
        display: none;
        width: 100%;

        &.active {
            display: block;
        }
    }
}
