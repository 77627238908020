/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-categories-border: 1px solid lightgray;
    --blog-category-chewron-border: 1px solid gray;
    --blog-category-link-font-size: 14px;
    --blog-category-chewron-size: 10px;
}

.BlogCategories {
    &-Category {
        display: block;

        &:first-child {
            @include desktop {
                padding-top: 0;
            }

            @include wide-desktop {
                padding-top: 24px;
            }
        }

        &:last-child {
            padding-bottom: 6px;

            @include desktop {
                padding-bottom: 0;
            }
        }

    }

    &-Wrapper {
        @include desktop {
            height: 100%;
        }

        .ExpandableContent {
            &.BlogCategories-Content {
                background: $greyF8F8F8;
                border: 0;

                @include desktop {
                    height: 100%;
                }
            }

            &-Button {
                padding: 16px;

                @include desktop {
                    border-bottom: 1px solid $grey;
                }

                .AddIcon,
                .MinusIcon,
                .ChevronIcon,
                svg {
                    @include desktop {
                        display: none;
                    }
                }
            }

            &-Heading {
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;

                @include desktop {
                    display: block;
                    text-align: center;
                    width: 100%;
                    font-size: 14px;
                    line-height: 16px;
                }

                @include wide-desktop {
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            &-Content {

            }
        }
    }

    &-CategoryTitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.2px;
        padding: 13px 16px;
        display: block;

        &:hover {
            background: $grey;
            text-decoration: none;
        }

        @include desktop {
            padding: 8px 24px 8px 32px;
            font-size: 14px;
            line-height: 16px;
        }

        @include wide-desktop {
            font-size: 14px;
            line-height: 16px;
            font-size: 18px;
            line-height: 24px;
        }
    }
}
