/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

 :root {
    --header-logo-width: 336px;
    --header-logo-height: 44px;
    --demo-notice-height: 0px;

    --header-height: 56px;
    --header-nav-height: 56px;
    --header-top-menu-height: 38px;

    @include desktop {
        --header-height: 87px;
        --header-nav-height: 87px;
        --header-website-link-height: 46px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--header-website-link-height) + var(--menu-total-height));
    }

     --header-total-height:
         calc(
             var(--header-height)
             + var(--offline-notice-height)
             + var(--demo-notice-height)
             + env(safe-area-inset-top)
         );
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: 0;
    height: 0;
}

@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 26px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin visible-on-mobile {
    @include button-visible;

    @include desktop {
        @include button-invisible;
    }
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.Header {
    align-items: center;
    background: var(--header-bg);
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-block-start: env(safe-area-inset-top, 0);
    width: 100%;
    position: fixed;
    z-index: 100;
    inset-block-start: var(--demo-notice-height);
    transition-duration: 200ms;
    transition-property: margin-top;

    > * {
        max-width: var(--content-wrapper-width);
        width: 100%;
    }

    &_menu,
    &_menu_subcategory {
        .Header-Button_type {
            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_menu {
                @include desktop {
                    @include button-invisible;
                }
            }
        }
    }

    &_name {
        &_menu,
        &_search,
        &_menu_subcategory {
            border-block-end: none;
        }

        &_category {
            position: fixed;

            @include mobile {
                border-block-end: none;
            }
        }

        &_cart_overlay {
            @include desktop {
                z-index: 101;
            }

            &::before {
                @include desktop {
                    content: "";
                    background: $black;
                    position: fixed;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0.4;
                    z-index: 1;
                }
            }
        }
    }

    &-Button {
        @include button-invisible;

        &_isVisible {
            @include mobile {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_menu,
            &_wishlist,
            &_minicart {
                @include button-visible;

                @include desktop {
                    height: 24px;
                    margin-inline-start: 24px;
                }
            }

            &_account {
                @include button-visible;
            }

            &_home {
                @include visible-on-mobile;
            }

            &_wishlist,
            &_minicart {
                @include desktop {
                    margin-inline-start: 24px;
                    flex-shrink: 0;
                }

                @include ultra-narrow-desktop {
                    margin-inline-start: 18px;
                }
            }

            &_back {
                .ChevronIcon {
                    width: 20px;
                    height: 20px;
                }
            }

            &_close {
                .CloseIcon {
                    width: 27px;
                    height: 27px;
                }
            }

            &_share {
                @include button-visible;
            }

            &_compare {
                @include button-visible;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-MinicartButtonWrapper {
        cursor: pointer;
    }

    &-CompareButtonWrapper {
        margin-inline-start: 35px;
        height: 24px;
    }

    &-LogoWrapper {
        inset-block-end: 0;
        height: 20px;
        inset-inline-start: 0;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        inset-inline-end: 0;
        inset-block-start: 0;
        width: 146px;

        @include mobile {
            margin: auto;
            width: calc(100% - 140px);
            @include logo-visible;
        }

        @include desktop {
            @include logo-visible;
            margin-left: auto;
            margin-right: auto;
            height: var(--header-logo-height);
            width: var(--header-logo-width);
        }

        @include wide-desktop {
            position: absolute;
            top: 20px;
        }

        .Header_isCheckout & {
            height: 26px;
            width: 195px;

            @include desktop {
                height: 38px;
                width: 285px;
                top: 30px;
                position: absolute;
            }
        }
    }

    &-Minicart {
        &ButtonWrapper {
            height: 100%;
            width: 100%;
        }

        &ItemCount {
            background: var(--header-fc);
            border-radius: 50%;
            color: var(--header-bg);
            height: 16px;
            padding: 2px;
            position: absolute;
            min-width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            line-height: 16px;
            font-weight: 500;
            inset-block-end: 0;
            inset-inline-end: -2px;
        }
    }

    &-Nav {
        align-items: center;
        display: flex;
        height: var(--header-nav-height);
        padding-inline: 14px;

        @include mobile {
            height: 112px;
            padding-bottom: 56px;
        }

        @include desktop {
            justify-content: unset;
            padding-inline: 32px;
        }

        @include wide-desktop {
            padding-inline: 72px;
            justify-content: flex-end;
        }

        .Header_isCheckout & {
            height: 90px;
            border-bottom: 1px solid $grey;
            padding-bottom: 0;

            @include desktop {
                height: 100px;
            }
        }
    }

    &-MyAccount {
        @include desktop {
            height: 24px;
        }

        img {
            vertical-align: middle;
        }
    }

    &-News {
        width: 100%;
        text-align: center;
        font-size: 12px;
        min-height: var(--header-top-menu-height);
        padding-block-start: 4px;
        padding-block-end: 4px;
        display: flex !important;
        align-items: center;
        justify-content: center;

        &Button {
            font-size: 12px;
            margin-inline-start: 5px;
        }

        .ExclamationMarkIcon {
            inset-block-end: 1px;
            height: 16px;
            margin-inline-end: 10px;
            width: 16px;
        }

        a {
            color: $white;
            font-size: 12px;
        }
    }

    &-Title {
        color: var(--header-color);
        margin: auto;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 0;
        text-align: center;
        text-overflow: ellipsis;
        inset-block-start: -1px;
        inset-inline-start: 0;
        inset-block-end: 0;
        transition-duration: 200ms;
        transition-property: opacity;
        white-space: nowrap;
        width: 100%;
        line-height: 30px;
        height: 30px;

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 80px);
            }
        }
    }

    &-Welcome {
        display: none;
        font-size: 14px;
        margin-inline-end: 16px;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        align-self: center;
        line-height: 16px;

        @include wide-desktop {
            display: block;
        }
    }

    &-Nav {
        display: flex;
        margin: auto;
        max-width: 100%;
    }

    &-TopMenu {
        display: initial;
        background: $black;
        color: $white;
        min-height: var(--header-top-menu-height);
        align-items: center;
        justify-content: space-between;
        padding-inline: 32px;
        max-width: 100%;
        max-height: 38px;
        overflow: hidden;
        opacity: 0;

        @include desktop {
            display: initial;
            padding-inline: 56px;
        }

        .slick-slider {
            height: 0;
            transition-duration: 2000ms;
            transition-property: height;

            &.slick-initialized {
                height: auto;
            }

            .slick-track {
                display: flex;
                flex-wrap: wrap;
            }

            .slick-slide {
                align-self: center;
                float: none;
                width: 100%;

                &[style="outline: none;width: 0px;"] {
                    width: 100% !important;
                }
            }

            .slick-arrow {
                display: block !important;
                width: 32px;
                height: 100%;

                @include desktop {
                    width: 56px;
                }
            }

            .slick-prev {
                left: -32px;

                @include desktop {
                    left: -56px;
                }

                &:before {
                    width: 1px;
                    background: $white;
                    transform: rotate(43deg);
                    height: 8px;
                    top: calc(50% - 8px);
                    left: 17px;

                    @include desktop {
                        left: 20px;
                    }
                }

                &:after {
                    width: 1px;
                    background: $white;
                    transform: rotate(-43deg);
                    height: 8px;
                    top: calc(50% - 2px);
                    left: 17px;

                    @include desktop {
                        left: 20px;
                    }
                }
            }

            .slick-next {
                right: -32px;

                @include desktop {
                    right: -56px;
                }

                &:before {
                    width: 1px;
                    background: $white;
                    transform: rotate(-43deg);
                    height: 8px;
                    top: calc(50% - 8px);
                    left: auto;
                    right: 17px;

                    @include desktop {
                        right: 20px;
                    }
                }

                &:after {
                    width: 1px;
                    background: $white;
                    transform: rotate(43deg);
                    height: 8px;
                    top: calc(50% - 2px);
                    left: auto;
                    right: 17px;

                    @include desktop {
                        right: 20px;
                    }
                }
            }

            .slick-dots {
                position: static;
                padding-bottom: 5px;

                li {
                    button {
                        &:before {
                            background: $white;
                        }
                    }

                    &.slick-active {
                        button {
                            border-color: $white;
                        }
                    }
                }
            }
        }
    }

    &-WebsiteSwitch {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-WebsiteSwitchColumn {
        width: 50%;
        max-width: 50%;
    }

    &-WebsiteSwitchLink {
        background: $xryso;
        font-size: 18px;
        line-height: 26px;
        height: var(--header-website-link-height);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-decoration: none;

        @include mobile {
            &.active {
                font-weight: 600;
                background-color: #fff;
            }
            height: 36px;
            font-size: 12px;
            line-height: 22px;
        }

        &:hover {
            text-decoration: none;
        }

        &_isActive {
            background: $white;
            pointer-events: none;
            
            span {
                border-bottom: 1px solid $black;

                @include mobile {
                    font-weight: 500;
                }
            }
        }
    }

    &-LangSwitchLink {
        color: var(--header-fc);
        display: flex;

        @include mobile {
            position: absolute;
            left: 58px;
            top: 17px;
        }

        &:hover {
            color: var(--header-fc);
        }

        img {
            width: 20px;
            height: 24px;
            margin-right: 17px;

            @include desktop {
                margin-right: 12px;
                margin-top: -4px;
            }
        }
    }

    &-Switcher {
        align-items: center;
        display: flex;

        @include desktop {
            margin-right: 35px;
        }

        @include ultra-narrow-desktop {
            margin-right: 24px;
        }

        @include wide-desktop {
            margin-right: auto;
        }
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
            }

            &-Button_type_close,
            &-Button_type_back {
                @include desktop {
                    display: none;
                }
            }

            &-MyAccount {
                @include desktop {
                    margin-inline-start: auto;
                    height: 24px;
                }

                @include mobile {
                    position: absolute;
                    inset-inline-end: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include mobile {
                inset-block-start: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;

        @include desktop {
            margin-block-end: var(--header-total-height);
        }

        &_name_search {
            z-index: 101;

            ~ .NavigationTabs {
                z-index: 101;
            }
        }

        &_isPrerendered {
            @include mobile {
                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    & > * {
                        display: none;
                    }

                    .Header {
                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    &_name_popup {
        z-index: 400;
    }

    &-MyAccountContainer {
        display: flex;
        margin-inline-start: 42px;

        @include desktop {
            flex-shrink: 0;
        }

        @include ultra-narrow-desktop {
            margin-inline-start: 18px;
        }
    }
}
