/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

:root {
    --brand-content-wrapper-width: 1118px;
}

.HeroBrandPage{
    overflow: hidden;

    &.isChanel {
        font-family: $font-arial;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    &-FullWidthTop {
        margin: 0px auto 33px auto;

        @include desktop {
            max-width: var(--content-wrapper-width);
            padding-left: 48px;
            padding-right: 48px;
        }

        @include small-view-desktop {
            width: 100%;
            padding-left: 48px;
            padding-right: 48px;
        }

        @include mobile {
            width: 100%;
            padding: 0;
        }
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            padding-block-start: 24px;
            grid-column-gap: 48px;
        }

        grid-template-columns: 236px calc(100% - 284px);

        @include wide-desktop {
            grid-column-gap: 53px;
            grid-template-columns: 317px calc(100% - 370px);
        }

        @include mobile {
            padding: 0;
        }

        &_hideProducts {
            display: block;

            .CategoryPage-CMS {
                @include mobile {
                    margin-block-start: 0;
                }
            }
        }
    }

    &-MainCol {
        @include desktop {
            padding: 0 48px;
            margin: 0 -48px;
        }

        @include wide-desktop {
            padding: 0 53px;
            margin: 0 -53px;
        }
    }

    &__left_image {
        background: $white;
    }

    &-LeftCol {
        background: $greyF8F8F8;
        margin: 0 16px 30px;

        @include desktop {
            position: relative;
            grid-row: 1/6;
            width: 100%;
            opacity: 1;
            pointer-events: all;
            display: flex;
            flex-direction: column;
            height: auto;
            overflow: visible;
            margin: 0;
            background: none;
        }

        .nav-1 {
            > li {
                > .title { display: block;
                    &.category { display: none; }
                }
            }
        }
        .nav-2 {
            > li {
                > a { display: inline-block;
                    .text { display: inline-block; }

                    &.active { font-weight: 500 }
                }
                &.selected {
                    > a { font-weight: 500 }
                    > .nav-3 { display: block; }
                }
                &.accountMenuItem-cookie-privacy-policy { display: none; }
            }
        }
        .nav-3 { display: none;
            &.opened { display: block; padding-left: 20px; }
            > li {
                > a { display: inline-block;

                    .text { border-bottom: 1px solid transparent; }
                }
                &.selected {
                    > a {
                        font-weight: 500;
                    }
                }
                &.selected.hasChildren {
                    > a::before { display: none; }
                }
            }
        }
        .nav-4 { padding-left: 20px;
            > li {
                > a { display: inline-block;

                    .text { border-bottom: 1px solid transparent; }
                }
                &.selected {
                    > a {
                        font-weight: 500;
                    }
                }
                &.last { }
            }
        }


        .ExpandableContent.scandi_desktop_accordian-Content {
            border-bottom: 0 none;

            @include desktop {
                background: $greyF8F8F8;
            }

            .isChanel & {
                @include desktop {
                    background: $white;
                }
            }
        }

        .ExpandableContent-Content_isContentExpanded.scandi_desktop_accordian-ExpandableContentContent {
            border-bottom: 0 none;
        }

        .scandi_desktop_accordian-Content {
            .ExpandableContent-Button {
                padding-left: 20px;
                padding-right: 20px;

                @include desktop {
                    border-bottom: 1px solid $color-grey-E5;
                    justify-content: center;
                }

                .AddIcon,
                .MinusIcon,
                .ChevronIcon,
                svg {
                    @include desktop {
                        display: none;
                    }
                }
            }

            .ExpandableContent-Content {
                @include desktop {
                    max-height: 100%;
                    opacity: 1;
                }
            }

            .ExpandableContent-Heading {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;

                @include desktop {
                    font-weight: 500;
                }
            }
        }

        .CmsPage-Faq_accordian_content {
            padding: 13px 20px 10px;

            @include desktop {
                padding: 26px 20px;
            }

            li {
                margin: 0;

                &:before {
                    display: none;
                }
            }

            a {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.2px;
                margin-bottom: 27px;
                display: block;

                @include desktop {
                    margin-bottom: 14px;
                }
            }
        }

        .chanel_accordian_content {
            li {
                margin: 0;

                &:before {
                    display: none;
                }
            }

            a {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.2px;
                margin-bottom: 27px;
                display: block;

                @include desktop {
                    margin-bottom: 14px;
                }
            }
        }

        &3 {
            background: none;

            .ExpandableContent {
                @include mobile {
                    border-top: 0 none;
                }

                &:last-of-type {
                    @include mobile {
                        border-bottom: 0;
                    }
                }

                &-Button {
                    @include mobile {
                        border: 1px solid $black;
                        padding: 12px 20px;
                    }

                    .AddIcon,
                    .MinusIcon,
                    .ChevronIcon,
                    svg {
                        display: none;
                    }

                    &:after {
                        @include mobile {
                            content: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6H21' stroke='black' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M3 12H12' stroke='black' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M3 18H16' stroke='black' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                &-Heading {
                    @include mobile {
                        font-weight: 500;
                    }
                }
            }

            .chanel_accordian_content {
                a {
                    @include mobile {
                        font-size: 14px;
                        line-height: 22px;
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }

    &__left_image {
        display: none;

        @include desktop {
            display: block;
        }

        img {
            @include desktop {
                width: auto !important;
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0 0 10px;
        padding-bottom: 30%;

        @include mobile {
            padding-bottom: 60%;
        }

        &:first-child {
            padding-bottom: 40%;

            @include mobile {
                padding-bottom: 100%;
            }
        }
    }
    &-HeadingPlaceholder {
        background-image: var(--placeholder-image);
        background-size: 1200px 100%;
        animation: var(--placeholder-animation);
        margin: 0 0 10px;
        padding-bottom: 10%;
        padding-left: 20% ;
        padding-right: 20% ;

        @include mobile {
            padding-bottom: 60%;
        }

        &:first-child {
            padding-bottom: 40%;

            @include mobile {
                padding-bottom: 100%;
            }
        }
    }

    p {
        margin-bottom: 0;
    }

    &__main {
        &_image {
            margin-bottom: 13px;

            @include desktop {
                margin-bottom: 15px;
            }

            &_3 {
                @include desktop {
                    margin-bottom: 40px;
                }
            }
        }

        &_title {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
            margin: 0 16px 12px;

            @include desktop {
                margin: 0 0 20px;
                padding: 0 10px;
            }

            &_layout1,
            &_layout5 {
                background: $black;
                color: $white;
                font-family: $font-BlackerProDisplay;
                text-align: center;
                font-size: 31px;
                line-height: 1;
                margin-bottom: 7px;

                @include desktop {
                    margin-bottom: 33px;
                }

                a {
                    display: block;
                    padding: 7px;
                }

                img {
                    filter: brightness(0) invert(1);
                    max-width: 85px;

                    @include desktop {
                        max-width: 104px;
                    }
                }
            }

            &_layout2 {
                color: $white;
                font-family: $font-BlackerProDisplay;
                text-align: center;
                font-size: 31px;
                line-height: 1;
                margin-bottom: 20px;
                overflow: hidden;

                @include desktop {
                    margin-bottom: 0;
                }

                a {
                    display: block;
                    padding: 7px;

                    @include desktop {
                        padding: 0;
                    }
                }

                img {
                    width: auto;
                    margin: -34px 0 -40px;

                    @include desktop {
                        margin: 0;
                        vertical-align: top;
                    }
                }
            }

            &_layout3 {
                font-family: $font-BlackerProDisplay;
                text-align: center;
                font-size: 31px;
                line-height: 1;
                border-top: 6px solid #000;
                padding: 5px 0 21px;

                @include desktop {
                    padding: 26px 0 18px;
                    border-top-width: 8px;
                }

                a {
                    display: inline-block;
                    vertical-align: top;
                    padding: 7px;
                }

                img {
                    width: auto;
                    vertical-align: top;

                    @include mobile {
                        height: 20px;
                    }
                }

                &.HeroBrandPage__main_title_layout_footer {
                    padding-bottom: 30px;
                    padding-top: 30px;

                    @include mobile {
                        padding-bottom: 0;
                        padding-top: 20px;
                    }

                    img {
                        @include mobile {
                            height: auto;
                            max-height: 110px;
                        }
                    }
                }
            }

            &_layout4 {
                background: $black;
                font-family: $font-BlackerProDisplay;
                text-align: center;
                font-size: 31px;
                line-height: 1;

                a {
                    display: inline-block;
                    vertical-align: top;
                    padding: 7px;
                }

                img {
                    filter: brightness(0) invert(1);
                    height: 60px;
                    width: auto;
                    vertical-align: top;

                    @include mobile {
                        height: 40px;
                    }
                }

                &.HeroBrandPage__main_title_layout_footer {
                    padding-bottom: 30px;
                    padding-top: 30px;

                    @include mobile {
                        padding-bottom: 0;
                        padding-top: 20px;
                    }

                    img {
                        @include mobile {
                            height: auto;
                            max-height: 110px;
                        }
                    }
                }
            }

            &_layout5 {
                background: $kylie_pink;
            }
        }

        &_description {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin: 0 16px 26px;

            @include desktop {
                margin: 0 0 26px;
                padding: 0 10px;
            }

            p {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    &__chanel {
        .ProductListWidget {
            h2 {
                &:before {
                    display: none;
                }
            }
        }

        &_product_slider{
            &.slick-slider {
                margin: 0 8px 57px;

                @include desktop {
                    margin: 0 -12px 28px;
                }

                .slick-prev {
                    @include desktop {
                        left: -16px;
                    }
                }

                .slick-next {
                    @include desktop {
                        right: -16px;
                    }
                }

                .slick-dots {
                    position: static;
                    margin-top: 15px;

                    @include desktop {
                        margin-top: 38px;
                    }
                }

                .ProductCard {
                    padding: 0 8px;

                    @include desktop {
                        padding: 0 12px;
                    }
                }
            }

            .ProductCard {
                &-Name {
                    margin-bottom: 4px;
                }

                &-BrandAttributeValue {
                    font-size: 14px;
                    line-height: 16px;
                }

                &-TagsBottom {
                    bottom: -28px;

                    @include desktop {
                        bottom: -20px;
                    }
                }

                .AddToCart.Button {
                    background: $black;
                    border-color: $black;
                    color: $white;

                    &:not([disabled]):hover {
                        background: $white;
                        color: $black;
                    }
                }
            }
        }
    }

    &__dior_reseller {
        max-width: 320px;
        margin-inline: auto;
    }
}

.origin {
    display: block;
    color: white;
    background: black;
    position: relative;
    overflow: hidden;

    //@include rule(padding, (xl: 27px 0, lg: 20px 0));

    $b: &;

    &__items {
        //@include rule(display, (xl: flex, lg: block));
        //@include rule(padding-right, (lg: 85px));

        justify-content: space-between;
        align-items: center;
    }

    &__item {
        //@include rule(display, (xl: flex, lg: block));

        align-items: center;

        &:not(:first-child) {
            //@include rule(margin-left, (lg: 320px), min);
            //@include rule(margin-top, (lg: 36px));
        }
    }

    &__title {
        font-weight: 500;
        flex-shrink: 0;

        //@include rule(font-size, (xl: 30px, lg: 24px));
        //@include rule(line-height, (xl: 36px, lg: 29px));
        //@include rule(margin-right, (lg: 19px), min);
    }

    &__text {
        //@include rule(font-size, (xl: 18px, lg: 13px));
        //@include rule(line-height, (xl: 22px, lg: 16px));
        //@include rule(margin-top, (lg: 5px));
    }

    &__bee {
        position: absolute;
        margin: auto;
        padding-bottom: 0;
        height: 144px;

        //@include rule(top, (xl: 2px, lg: 14px));
        //@include rule(right, (xl: 0, lg: -56px));
        //@include rule(left, (xl: 0, lg: auto));
        //@include rule(width, (lg: 202px));
        //@include rule(transform, (lg: rotate(45deg)));
    }
}

.benefits {
    display: block;
    position: relative;

    $b: &;

    &__amp {
        width: 192px;
        padding-bottom: 0;
        height: auto;

        //@include center-image;
    }

    .swiper-wrapper {

        //@include screen(lg, min) {
        //    justify-content: space-between;
        //}
    }

    &__item {
        //@include screen(lg, min) {
        //    flex: 1;
        //
        //    &:not(:first-child) {
        //        margin-left: 368px;
        //    }
        //}
    }

    &__text {
        text-align: center;
        font-weight: 300;

        //@include rule(font-size, (xl: 23px, lg: 16px));
        //@include rule(line-height, (xl: 43px, lg: 23px));

        b {
            font-weight: 600;
        }
    }

    &__number {
        //@include rule(font-size, (xl: 40px, lg: 23px));
        //@include rule(font-weight, (xl: 400, lg: 600));

        vertical-align: bottom;
    }

    &__image {
        //@include rule(height, (xl: 230px, lg: 200px));
        //@include rule(margin, (xl: 40px 0 -13px, lg: 18px 0 0));

        position: relative;
        z-index: -1;

        img {
            height: 100%;

            //@include center-image;
        }

        .Image {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding-bottom: 0;
            height: 100%;
        }
    }

    &__content {
        //@include rule(margin-top, (xl: 27px, lg: 23px));
    }

    &__description {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
    }

    &__special {
        //@include rule(margin-top, (xl: 12px, lg: 5px));
        //@include rule(border, (xl: 14px solid, lg: 10px solid));
        //@include rule(padding, (xl: 14px, lg:10px));
        //@include rule(text-align, (lg: center), min);

        background: color(gray-1);
        border-color: color(gray-1);
        font-weight: 300;
        font-size: 12px;

        //@include rule(line-height, (xl: 24px, lg: 15px));
        //
        //@include rule(display, (lg: -webkit-box), min);
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        span {
            font-size: 24px;

            b {
                font-weight: 500;
            }
        }

        b {
            font-weight: 600;
        }
    }
}


