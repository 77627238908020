/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

.ChevronsIcon {
    stroke: var(--color-black);
    cursor: pointer;

    &-space {
        width: 16px;
        height: 20px;
        display: block;

        .ChevronsIcon {
            position: absolute;
            top: 0;
            left: -1px;

            &:last-child {
                left: 3px;
            }
        }

        &:hover,
        &:focus {
            .ChevronsIcon {
                fill: var(--primary-base-color);
            }
        }
    }

    &_direction {
        &_left {
            transform: rotate(180deg);

            [dir="rtl"] & {
                transform: none;
            }
        }

        &_right {
            [dir="rtl"] & {
                transform: rotate(180deg);
            }
        }

        &_bottom {
            transform: rotate(90deg);
        }

        &_top {
            transform: rotate(-90deg);
        }
    }
}
