.mothersday-dior-landing-page{
  .CmsPage-Wrapper_page_width_default & {
    @include mobile {
      margin: 0 -14px;
    }
  }

  .center-container {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }

  .dior-logo {
    img {
      display: block;
      max-width: 230px;
      max-height: 100%;
      margin: 0 auto;
    }
  }

  .title-container {
    display: block;
    text-align: center;
    margin: 0 auto;
    max-width: 770px;
    padding: 40px 20px 20px;

    @include mobile {
      padding: 20px 10px;
    }

    .title {
      display: block;
      font-size: 35px;
      line-height: 40px;
      font-weight: 500;
      color: #292a2e;
      letter-spacing: .04em;
      padding-bottom: 20px;
      margin: 0;

      @include mobile {
        font-size: 25px;
        line-height: 30px;
      }
    }

    .description {
      display: block;
      padding: 0 10px;
      font-size: 18px;
      line-height: 32px;
      font-weight: 500;
      color: #292a2e;
      letter-spacing: .04em;
      margin: 0;

      @include mobile {
        padding: 0;
        font-size: 15px;
        line-height: 20px;
      }

      a {
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 35px;
    }
  }

  img {
    vertical-align: top;
  }

  .video-section {
    margin: 60px 0 90px;

    @include mobile {
      margin: 30px 0;
    }

    .VideoWidget-content {
      display: none;
    }

    .VideoWidget-PlayIcon {
      display: none;
    }

    .VideoWidget-VideoContainer {
      @include mobile {
        height: var(--video-height) !important;
      }
    }

    .VideoWidget-Image {
      @include mobile {
        height: 100%;
      }
    }

    .VideoWidget-Thumbnail {
      @include mobile {
        position: static;
      }

      img {
        @include mobile {
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }
  }

  .productList-container {
    padding-bottom: 50px;

    @include mobile {
      padding-left: 14px;
      padding-right: 14px;
      padding-bottom: 20px;
    }
  }

  .ProductCard {
    padding: 0 6px;
    text-align: left;

    @include desktop {
      padding: 0 10px;
    }

    .ProductWishlistButton {
      @include desktop {
        right: 10px;
      }
    }
  }

  .slick-slider {
    margin-left: -6px;
    margin-right: -6px;

    .slick-dots {
      position: static;
      margin-top: 15px;
    }
  }
}