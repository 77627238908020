/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.SearchFields {
    display: grid;
    &-Filters {
        display: flex;
        justify-content: center;
        margin: 45px 0 0px 0;
        flex-wrap: wrap;
        .search {
            margin: 5px;
        }
    }
    &-Action{ 
        margin: 15px;
        button {
            float: right;
        }
        &_isDisable {
            cursor: none;
        }
    }
    &-FilterList {
        display: flex;
        li {
            display: flex;
            padding: 5px;
            align-items: center;
            margin: 0;
            font-weight: 600;

        }
        label {
            font-size: 14px;
            margin: 0 10px;
        }
        span {
            &::after {
                display: inline-block;
                cursor: pointer;
                position: absolute;
                content: "\00d7";
                font-size: 22px;
                bottom: 8px;
                right: -10px;
            }
        }
    }
}