/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';

.GiftCartCoupon {
  &-Message {
    display: inline-block;
  }

  &-Action  {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    gap: 10px;
  }

  .applied-gift-coupons {
    .remove {
      margin: 10px;
      padding: 10px;
    }
  }

  &-Input {
    width: 100%;

    input {
      width: 100%;
      height: auto;
    }

    @include mobile {
      width: 100%;
      margin-block-start: 0;
    }

    @include tablet-portrait {
      width: 100%;
      margin-block-start: 0;
    }
  }

  &-Button {
    &.Button {
      @include button;

      background: $black;
      color: $white;
      width: auto !important;

      &:not([disabled]):hover {
        background: $white;
        color: $black;
      }
    }
  }

  &Status-Button {
    margin-block-start: 4px;
  }

  &-Title {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    margin-block-end: 20px;
  }

  &-AppliedCoupons {
    .Item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .remove {
        color: red;
      }
    }
  }

  &-InfoGrid {
    border: 1px solid $greyE0E0E0;
    padding: 10px;
    margin-top: 10px;

    li {
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      display: flex;
      justify-content: space-between;
      padding: 2px 0;

      span {
        &:first-child {
          font-weight: 600;
          padding-right: 20px;
        }
      }

      &:last-child {
        line-height: normal;
        align-items: center;
        border-top: 1px solid $greyE0E0E0;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: 0;

        span {
          &:first-child {
            font-weight: 600;
          }

          &:last-child {
            font-size: 32px;
            font-weight: 700;
          }
        }
      }

      &:not(:last-child) {
        @include mobile {
          flex-direction: column;
        }
      }

      &:before {
        display: none;
      }
    }
  }
}
