/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../../src/style/abstract/variables';
@import '../../../../../../../../src/style/abstract/media';
@import '../../../../../../../../src/style/abstract/button';
@import '../../../../../../../../src/style/abstract/loader';
@import '../../../../../../../../src/style/abstract/icons';
@import '../../../../../../../../src/style/abstract/parts';

.SpecialOffersFilters {
  .ExpandableContentShowMore {
    > div:not(:first-child) {
      margin: 12px 0 0;
    }

    &-ExpandableChildren {
      > div:not(:first-child) {
        margin: 12px 0 0;
      }
    }
  }

  &-ExpandableContentContent {
    > div:not(:first-child) {
      @include mobile {
        margin: 12px 0 0;
      }
    }
  }
}