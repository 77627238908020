/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --popup-background: #0003;
    @include mobile {
        --popup-content-padding: 14px;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-content-padding: 12px;

    @include desktop {
        --popup-min-width: 490px;
        --popup-content-padding: 24px;
    }
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    inset-inline-start: 0;
    inset-block-start: var(--header-total-height);

    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        align-items: stretch;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;
        inset-block-start: 0;

        @include mobile {
            inset-block-start: 0;
            height: 100%;
        }
    }

    &-Heading {
        text-decoration: none;
        margin-block-start: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin-bottom: 2px;
        padding-top: 12px;

        @include desktop {
            font-size: 20px;
            line-height: 28px;
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 3px;

        .ImageZoomPopup & {
            padding: 0;
        }
    }

    &-Content {
        background-color: var(--popup-content-background);
        padding: var(--popup-content-padding);
        min-width: var(--popup-min-width);
        max-width: calc(var(--content-wrapper-width) * .8);
        // max-height: 90%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 0;

        @include mobile {
            max-height: 100%;
        }
    }

    & &-CloseBtn {
        flex-shrink: 0;

        .CloseIcon {
            height: 50px;
            width: 35px;
        }
    }

    &.ImageZoomPopup {
        .Popup-CloseBtn {
            position: absolute;
            inset-block-start: -4px;
            inset-inline-end: -6px;
            z-index: 5;

            @include mobile {
                inset-block-start: 25px;
            }
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }

    .NotificationList {
        width: 100vw;
        inset-block-start: 0;
        inset-inline-start: calc(0px - var(--popup-content-padding));
        position: relative;
    }
}

.scrollDisabled {
    position: fixed;
    margin-block-start: 0;
    width: 100%;
    overflow-y: auto;
}
