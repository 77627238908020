/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

ul,
ol {
    ul,
    ol {
        counter-reset: list;
    }
}

li {
    &::before {
        padding-inline-end: 1.1225em;

        ol > & {
            content: counter(list) '.';
        }

        &,
        ul > & {
            content: '\2022';
        }
    }
}
