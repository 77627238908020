/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.StoreLocator{
    .stores__header{
        display:block
    }
    .select.js-store-select.choices__input{
        display: block;
        width: 100%;
        border-color: #dadada;
        background-color: #fff;
        margin: 0;
        color: #000;
        height: 44px;
        padding: 0 10px;
        appearance: none;
        background-image: url("../../public/assets/images/global/arrow_select_store.svg");
        background-repeat: no-repeat;
        background-position: 96% 50%;
    }
    .list-checkbox {
        display: block;
        cursor: pointer;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        white-space: nowrap;

        @media (min-width: 1050px) and (max-width: 1400px) {
            display: inline-block;
            margin-left: 0;
            margin-bottom: 10px;
        }
    }
    .leaflet-container {
        width: 100%;
        height: 372px;

        //@include rule(height, (xl: 398px, lg: 242px));

        &.map {
            //@include rule(max-height, (xl: 100%, lg: 142px));
        }
    }

    &_Top {
        &_block {
            text-align: center;
        }

        &_title {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.2px;
            margin: 0 0 20px;

            @include desktop {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.003em;
                margin-bottom: 10px;
            }
        }

        &_info {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            max-width: 958px;
            margin: 0 auto 20px;
            text-align: left;

            @include desktop {
                text-align: center;
            }
        }

        &_image {
            margin: 0 -14px 20px;
            overflow: hidden;

            @include desktop {
                margin: 0 0 20px;
            }

            //@media (min-width: 360px) and (max-width: 810px) {
            //    .desktop {
            //        display: block !important;
            //        max-width: calc(100% + 50px);
            //        width: calc(100% + 50px) !important;
            //        margin: 0 -25px;
            //    }
            //
            //    .mobile {
            //        display: none !important;
            //    }
            //}
        }
    }

    &_Bottom {
        &_block {
            text-align: center;
            margin-bottom: 15px;
        }

        &_title {
            margin: 30px 0 9px !important;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
        }

        &_images {
            display: flex;
            gap: 16px;
            flex-direction: column;

            > * {
                width: 100%;
            }

            @media (min-width: 360px) and (max-width: 810px) {
                flex-direction: row;
                flex-wrap: wrap;

                > * {
                    width: calc(50% - 8px) !important;
                }
            }

            @include desktop {
                flex-direction: row;
            }

            > * {
                @include desktop {
                    width: calc(25% - 12px) !important;
                }
            }
        }
    }
}

.stores {
    display: block;

    //@include rule(padding-top, (xl: 25px, lg: 0));

    $b: &;

    &__header {
        display: block;
    }

    &__title {
        //@include rule(font-size, (xl: 60px, lg: 20px));

        //font-family: $font-second;
        line-height: 1;
        letter-spacing: .06em;

        //@include screen(lg) {
        //    text-align: center;
        //    padding: 18px 0;
        //}
    }

    .container {
        display: block;
    }

    // Left

    &__left {
        display: block;

        //@include rule(margin-top, (xl: 25px, lg: 0));
    }

    &__checkboxes {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;

        @include mobile {
            background: $white;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: transform 200ms ease-in-out;
            transform: translateY(100%);
            will-change: transform;
            opacity: 0;
            padding: 71px 14px 48px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        @include desktop {
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 38px;
            flex-wrap: wrap;
            gap: 16px;
        }

        &_button {
            display: none;

            @include mobile {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 12px;
            }
        }

        &_title,
        &_search,
        &_close {
            display: none;
        }

        &.active {
            @include mobile {
                transform: translateY(0%);
                z-index: 101;
                opacity: 1;
            }

            & ~ .stores__checkboxes_search {
                @include mobile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 101;
                }
            }

            & ~ .stores__checkboxes_title {
                @include mobile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -.2px;
                    border-bottom: 1px solid $grey;
                    height: 59px;
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 101;
                    background: $white;
                }
            }

            & ~ .stores__checkboxes_close {
                @include mobile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 58px;
                    height: 58px;
                    padding: 17px;
                    position: fixed;
                    top: 0;
                    right: 0;
                    z-index: 101;
                    background: $white;
                }

                img {
                    position: static;
                }
            }
        }
    }

    &__filter {
        //@include rule(max-width, (xl: 386px, lg: 100%));
        //@include rule(margin-top, (xl: 16px, lg: 22px));
    }

    &__list {
        //@include rule(margin-top, (xl: 43px, lg: 22px));
    }

    // Right

    &__map-wrapper {
        //@include rule(padding, (xl: 0 13px, lg: 0));

        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        max-height: 0;
        transition: max-height 1s;
        transition-duration : 0.5s;

        &.is-active {
            max-height: 372px;
            height: 372px;
        }
    }

    &__map-spacer {
        height: 100%;
    }

    &__map-container {
        //@include rule(height, (xl: 298px, lg: auto));
        //@include rule(border, (xl: 1px solid $color-grey-49, lg: 0));
        //@include rule(display, (xl: flex, lg: block));

        position: relative;
        height: 100%;

        //@include screen(lg) {
        //    background: $color-grey-41;
        //    padding: 0 10px 10px;
        //    box-sizing: border-box;
        //}

        > div {
            height: 100% !important;
        }

        > .stores__tooltip-close {
            height: auto !important;
        }
    }

    &__tooltip-close {
        font-size: 0;
        padding: 21px;
        flex-shrink: 0;
        cursor: pointer;
        display: none;

        &:hover {
            opacity: .5;
        }

        svg, img {
            width: 14px;
            height: 14px;
        }
    }

    .list-checkbox {
        display: block;
        cursor: pointer;
        flex-shrink: 0;
        white-space: nowrap;
        margin-bottom: 12px;

        @include desktop {
            margin-bottom: 0;
        }

        &__input {
            display: none;

            &:checked {
                + .list-checkbox__box {
                    background: $black;

                    .list-checkbox {
                        &__tick {
                            margin-right: 12px;
                            font-size: 0;
                            position: relative;
                            box-sizing: border-box;
                            border: 1px solid transparent;
                            background: transparent;
                            transition: background $ease-main;
                            width: 13px;
                            height: 13px;

                            &:after {
                                content: url("data:image/svg+xml,%0A%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8333 1L4.5 8.33333L1.16667 5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                transition: all 0.25s ease-out;
                            }
                        }

                        &__text {
                            color: $white;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        &__box {
            background: $greyF8F8F8;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            min-height: 40px;
            justify-content: center;
            padding: 9px;
        }

        &__text {
            color: $black000F0F;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin: 0 !important;
        }
    }
}
.store-tooltip {
    //@include rule(padding, (xl: 27px 33px 33px, lg: 0 14px 0));
    //@include rule(background, (xl: white, lg: transparent));
    //@include rule(width, (xl: 280px, lg: auto));
    //@include rule(font-size, (xl: 12px, lg: 11px));

    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;
    line-height: 25px;
    letter-spacing: 0.06em;
    display: none !important;

    $b: &;

    &__title {
        //@include rule(font-size, (xl: 12px, lg: 11px));

        font-weight: 600;
        line-height: 25px;
        letter-spacing: .06em;
    }

    &__schedule {
        //@include rule(font-size, (xl: 12px, lg: 11px));
        //@include rule(margin-top, (xl: 3px, lg: 5px));

        line-height: 25px;
        letter-spacing: .06em;

        li {
            display: flex;

            &::before {
                display: none;
            }
        }

        span {

            &:first-child {
                width: 80px;
                margin-right: 3px;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: .06em;
        text-decoration-line: underline;
        //transition: opacity $ease-main;
        margin-top: 26px;

        &:hover {
            opacity: .5;
        }

        svg, img {
            width: 25px;
            height: 10px;
            margin-left: 16px;
        }
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 14px;
        height: 14px;
        fill: black;
        cursor: pointer;
        //transition: opacity $ease-main;
        display: none !important;

        &:hover {
            opacity: .5;
        }
    }
}
.shop-list {
    display: block;

    @include desktop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 9.5%;
    }

    $b: &;

    &__list {
        max-height: 348px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 34px;
        margin-bottom: 24px;

        @include desktop {
            width: 50%;
            flex-shrink: 0;
            max-width: 490px;
            max-height: 372px;
            margin-bottom: 0;
        }
    }

    &__shop {
        //@include rule(width, (xl: (1/3)*100%, lg: 100%));
        //@include rule(padding, (xl: 36px 13px 0, lg: 0));

        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        display: flex;
        gap: 20px;
        margin-bottom: 20px;

        &:not(:first-child) {
            //@include rule(margin-top, (xl: 0, lg: 16px));
        }
        &::before {
            display: none;
        }
    }

    &__checkbox {
        width: 14px;
        height: 14px;
        border: 1px solid $black;
        opacity: 0.25;
        border-radius: 50%;
        background: $white;
        position: absolute;
        left: 5px;
        top: 5px;
    }

    &__wrapper {
        //background: $color-grey-41;
        //transition: border-color $ease-main;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        font-feature-settings: 'pnum' on, 'lnum' on;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding-left: 39px;
        position: relative;

        &.is-active {
            .shop-list__checkbox {
                border-width: 4px;
                opacity: 1;
            }
        }
    }

    &__count {
        font-style: normal;
        font-weight: 700;
        letter-spacing: .06em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        flex-shrink: 0;
        color: white;
        position: absolute;
        text-align: center;

        &_text {
            color: white;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;

        @include desktop {
            font-size: 18px;
            line-height: 26px;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.0008em;
        flex-grow: 1;

        @include desktop {
            line-height: 16px;
        }

        p {
            margin: 0;
        }
    }

    &__link {
        color: $color-grey-A1;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.0008em;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 4px;
        text-decoration: none !important;
    }

    &__photo {
        width: 100px;
        height: 100px;
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;

        img {
            //@include fill-img;
        }
    }

    &__button {
        //@include rule(margin-top, (xl: 22px, lg: 11px));
        //@include rule(padding, (xl: 0 17px, lg: 0 11px));

        height: 34px;
        line-height: 32px;
        //border-color: $color-grey-37;
        font-size: 12px;
        font-weight: 600;
        //transition: background-color $ease-main, border-color $ease-main, color $ease-main;
        opacity: 1 !important;

        #{$b}__shop:hover & {
            border-color: black;
        }

        #{$b}__shop.is-active & {
            background: black;
            border-color: black;
            color: white;
        }
        &:not([disabled]) {
            &:focus,
            &:hover {
                color: black;
            }
        }
    }
}
.map {
    display: block;
    height: 100% !important;
    position: relative;
    z-index: 1;
    width: 100%;
    flex-grow: 1;
    border-radius: 2px;
    overflow: hidden;

    @include mobile {
        height: 283px !important;
    }

    //@include screen(lg) {
    //    height: 142px;
    //    margin-top: 10px;
    //}
}

.leaflet-touch .leaflet-bar, .leaflet-touch .leaflet-control-layers {
    border: none;
    box-shadow: none !important;
}

.leaflet-bar {
    box-shadow: none;
    background: transparent;
}

.leaflet-bar a {
    border: 0 !important;
    //transition: opacity $ease-main;
    //color: $color-basic;
    width: 30px !important;
    height: 30px !important;
    border-radius: 2px !important;
    background: white !important;
    line-height: 30px !important;
    //font-family: $font-main;

    &:last-child {
        margin-top: 10px;
    }

    &:hover {
        opacity: .5;
    }
}

.leaflet-popup-content-wrapper {
    border-radius: 3px;
}

.leaflet-popup-content {
    margin: 0;
    padding: 13px 20px;
    font-size: 14px;
}

.leaflet-container .leaflet-control-attribution { display: none; }

.leaflet-left {
    left: auto;
    right: 0;

    .leaflet-control {
        margin-left: 0;
        margin-right: 10px;
    }
}

.leaflet-top {
    top: auto;
    bottom: 0;

    .leaflet-control {
        margin-top: 0;
        margin-bottom: 10px;
    }
}
.Stores-PlaceHolder {
    background-image: var(--placeholder-image);
    background-size: var(--placeholder-size);
    animation: var(--placeholder-animation);
}

.shop {
    &-search {
        &-controls {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: 18px;
            margin-bottom: 19px;

            @include desktop {
                flex-direction: row;
                align-items: center;
                margin-bottom: 46px;
                width: 50%;
                max-width: 490px;
            }

            input.search_store {
                height: 44px;
                padding: 11px 14px;
                width: 100%;
            }
        }

        &_input {
            display: flex;
        }
    }

    &_nearby {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        text-decoration: underline;
        display: flex;
        align-items: center;
        gap: 11px;
        flex-shrink: 0;
    }
}


