/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.BrandsPage {
    &-maintitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 22px;
        letter-spacing: -0.2px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @include desktop {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
            margin-bottom: 10px;
        }

        span {
            font-weight: 400;
        }
    }

    &-maindescription {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0 0 14px;
        text-align: center;

        @include desktop {
            margin-bottom: 49px;
        }
    }

    &-Wrapper {
        padding-top: 30px;
    }

    &-item {
        line-height: 22px;
        font-size: 14px;
        padding: 0;
        margin: 0 0 5px;
        letter-spacing: -0.0008em;

        @include desktop {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
            margin-bottom: 0;
        }

        &::before {
            display: none;
        }

        &:last-child {
            margin-bottom: 30px;
        }

        a:hover {
            text-decoration: none;
            font-weight: 500;
        }
    }

    &-items {
        @include mobile {
            display: grid;
        }
        display: block;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        column-count: 4;

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 20px;
        }
    }

    &-list {

        &chunk {
            display: block;
            break-inside: avoid;
            min-height: 223px;

            &:not(:last-child) {
                padding-bottom: 23px;
                margin-bottom: 25px;
                border-bottom: 1px solid $color-grey-E5;

                @include desktop {
                    margin-top : 49px;
                    padding-bottom: 43px;
                    margin-bottom: 54px;
                }
            }
        }
    }

    &-title {
        position: absolute;
        top: 0;
        right: 0;
        color: $greyD0D0D0;
        opacity: 0.51;
        font-family: $font-BlackerProDisplay;
        font-size: 200px;
        line-height: 1;
    }

    &-brand_attributes {
        background: $white;
        display: flex;
        align-items: center;
        gap: 44px;
        padding-top: 10px;
        padding-bottom: 10px;
        z-index: 1;

        @include desktop {
            padding-top: 28px;
            padding-bottom: 28px;
        }
    }

    &-brand_attribute {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        border-bottom: 1px solid transparent;
        cursor: pointer;

        &.active {
            font-weight: 500;
            border-color: $black;
            cursor: default;
        }
    }

    &-alphabets {
        color: $white;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        display: flex;
        min-width: 32px;
        min-height: 32px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-align: center;

        @include desktop {
            font-size: 12px;
            width: 44px;
            height: 44px;
            min-width: 0;
        }

        @include wide-desktop {
            font-size: 16px;
        }

        br {
            @include desktop {
                display: none;
            }
        }

        &.inactive {
            opacity: 0.5;
            cursor: default;
        }

        &_list {
            background: $black;

            @include desktop {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 1px;
            }

            &_container {
                @include mobile {
                    width: 32px;
                }
            }

            &_wrapper {
                @include mobile {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: transform 150ms ease-in;
                }
            }
        }
    }

    &-list {
        &_container {
            @include mobile {
                display: grid;
                grid-template-columns: 32px auto;
                grid-column-gap: 24px;
                margin-top: 24px;
            }
        }
    }

    &-filters {
        background: $white;
        position: sticky;
        z-index: 1;

        @include mobile {
            height: 47px;
        }

        &.active {
            //position: fixed;
            //left: 0;
            //right: 0;
            //margin: 0 auto;
            //z-index: 1;
        }
    }
}

.BrandWidget {
    &-Placeholder {
        display: flex;
        flex-flow: nowrap;
        figure {
            flex: auto;
            padding: 5px;
        }
    }

    &-Main {
        margin: 0 -14px 40px;
        padding: 0 37px 0 8px;

        @include mobile {
            overflow: hidden;
        }

        @include desktop {
            margin: 0 -8px 18px;
            padding: 0;
        }

        &_title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
            margin: 0 0 32px;
            text-align: center;

            @include desktop {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 39px;
            }
        }

        .ProductLinks-Slider {
            &.slick-slider {
                @include desktop {
                    margin-bottom: 18px;
                }
            }
        }

        .SliderWidget {
            &-Figure {
                padding: 0 8px;
                vertical-align: top;

                > a {
                    background: $white;
                    border: 1px solid $greyF0F0F0;
                    border-radius: 50%;
                    display: block;
                    overflow: hidden;
                    padding-bottom: calc(100% - 2px);

                    &:hover {
                        @include desktop {
                            border-color: $grey2D2D2D;
                            border-width: 2px;
                            padding-bottom: calc(100% - 4px);
                        }
                    }
                }
            }

            &-FigureImage {
                position: static;
                display: block;
                height: 100%;

                img {
                    position: absolute;
                    object-position: 50% 50%;
                    object-fit: contain;
                    width: auto !important;
                    height: auto !important;
                    max-width: 90%;
                    max-height: 90%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &-Figcaption {
                display: none !important;
            }
        }

        .slick {
            &-list {
                @include mobile {
                    overflow: visible;
                }
            }

            &-current {
                .SliderWidget-Figure > a {
                    @include mobile {
                        border-color: $grey2D2D2D;
                        border-width: 2px;
                        padding-bottom: calc(100% - 4px);
                    }
                }
            }
        }
    }
}
