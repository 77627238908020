/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// using the default "react-input-range" package css names

:root {
    --range-slider-color: #000;
    --range-slider-active-color: var(--primary-base-color);
}

.RangeSelector {
    .CategoryFilterOverlay .filters__section_type_price .ExpandableContent-Content & {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
    }

    .CategoryFilterOverlay .filters__section_type_price .ExpandableContent-Content_isContentExpanded & {
        max-height: 100%;
        opacity: 1;
    }

    &-Selected {
        color: $color-grey-A1;
        font-weight: 400;
        font-size: 10px;
        line-height: 1.1;
        position: absolute;
        top: -23px;
        z-index: 1;
    }

    &_isChanged {
        --range-slider-color: var(--range-slider-active-color);
    }
    
    &-Fields {
        display: flex;
        gap: 17px;
    }
    
    &-Field {
        position: relative;
        padding-top: 8px;

        .RangeSelector-Currency,
        input {
            background: none;
            width: 79px;
            padding: 19px 9px 3px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
            border: 1px solid rgba(0, 0, 0, 0.25);
            z-index: 1;
        }

        .RangeSelector-Currency {
            position: absolute;
            z-index: 0;
            left: 0;
            bottom: 0;

            span {
                opacity: 0;
            }
        }
    }

    &-Label {
        position: absolute;
        left: 8px;
        top: 0;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        z-index: 2;
        background: $white;
        padding: 0 10px;

        span {
            opacity: 0.25;
        }
    }
}

.input-range {
    margin: 30px 6px 21px;
    width: calc(100% - 22px);

    .CategoryFilterOverlay .ExpandableContent-Content_isContentExpanded & {
        margin-bottom: 4px;
    }

    &__slider,
    &__track--active {
        background-color: var(--range-slider-color);
        border-color: var(--range-slider-color);
    }

    &__slider {
        background-color: $black;
        margin-inline-start: 0px;
        margin-block-start: -7px;
        width: 12px;
        height: 12px;
    }

    &__track {
        height: 2px;
    }

    &__label {
        &--value {
            inset-block-start: -22px;
            display: none;
        }

        &--min,
        &--max {
            inset-block-end: -16px;
            display: none;
        }
    }
}
