/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.AdminCustomerGridList {
    background: $white;
    box-shadow: 0 0 10px 0 $grey;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99999;
    overflow-x: auto;

    &-Wrapper {
        table {
            width: 100%;

            td {
                &:last-child{
                    text-align: center;
                }
            }
        }
    }

    &_collapsed {
        height: auto;
        max-height: 50vh;
        background: white;
    }

    &-CustomerData {
        padding: 10px 0;
        //position: fixed;
        display: flex;
        justify-content: center;
        //top: 0;
        //z-index: 1;
        label {
            font-weight: 600;
            margin: 0 10px;
        }
    }

    &-Collapsable  {
        //position: fixed;
        //top: 0;
        &.Button {
            background-color: #fff !important;
            cursor: pointer;
            display: flex;
            margin: 0 auto;
            width: auto;
            //right: 50%;
        }

        &::before {
            content: "";
            width: 17px;
            border-bottom: 1px solid #000;
            right: 18px;
            top: 16px;
            transform: rotate(90deg);
        }
        &::after {
            content: "";
            width: 7px;
            height: 7px;
            border-style: solid;
            border-width: 1px;
            border-color: transparent #000 #000 transparent;
            transform: rotate(45deg);
            position: absolute;
            right: 24px;
            top: 23px;
        }
        &_collapsed {
            &::after {
                transform: rotate(225deg);
                top: 15px;
            }
            // background-color: #fff;
        }
    }

    &-Content { 
        display: none;
        &_collapsed {
            background-color: #fff;
            min-height: 10vh;
            display: block;
        }
    }

    &-TabList {
        display: flex;
        justify-content: center;
        margin-block-start: 10px;

        &-Item {
            margin: 10px 15px;
            cursor: pointer;
            padding: 10px;
            &_active {
                background-color: #000;
                color: #fff;
            }
        }
        li {
            list-style-type: none;
            margin-bottom: 0;
            font-weight: 600;

            &::before {
                display: none;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .CustomerAdmin-Missing,
    .OrderGridList-Missing { 
        display: flex;
        flex-flow: column;
        margin-bottom: 50px;
        text-align: center;
    }

    .SearchFields-Action {
        margin-left: 0;
        margin-right: 0;
    }
}