/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.AdventCalendar {
  background-image: var(--advent-bg);
  background-size: var(--bg-size);
  padding: 0 3rem 3rem;
  position: relative;

  &-Title {
    text-align: center;
    color: white;
    margin: 0;
    padding: 30px 0;
  }

  &-Container {
    margin: 0 auto;
    max-width: 1000px;
  }

  &-Items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    @include desktop {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-Item {
    perspective: 1280px;
    position: relative;
    cursor: pointer;

    &:hover {
      .AdventCalendar-Doorframe {
        transform: rotateY(-42deg);
      }
    }
  }

  &-Holder {
    padding-bottom: 100%;
  }

  &-Preview {
    background-position: 50%;
    background-size: cover;
    border: 1px solid #FFFFFF00;
    border-radius: 20px;
    box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    &:before {
      background-color: none;
      content: "";
      height: 100%;
      left: 0;
      opacity: .8;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  &-Doorframe {
    align-items: center;
    background-size: var(--bg-size);
    border: 1px solid #FFFFFF00;
    border-radius: 20px;
    box-shadow: 10px 3px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: rotateY(-20deg);
    transform-origin: 0 0;
    transition: transform .5s,box-shadow .5s;
    width: 100%;
  }

  &-Coloroverlay {
    background-color: rgba(198, 211, 214, 0.13);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 1.5s ease-in-out;
    transition-delay: .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: $font-BlackerProDisplay;

    @include desktop {
      font-size: 24px;
    }
  }

  &-Dimension {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    z-index: -1;
  }

  //&-Effect {
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 100%;
  //  opacity: 0;
  //  z-index: -1;
  //}

  //&-Layout1,
  //&-Layout2 {
  //  background-image: var(--advent-bg);
  //  background-size: var(--bg-size);
  //  padding: 0 3rem 3rem;
  //  position: relative;
  //
  //  .AdventCalendar {
  //    &-Title {
  //      text-align: center;
  //      color: white;
  //      margin: 0;
  //      padding: 30px 0;
  //    }
  //
  //    &-Container {
  //      margin: 0 auto;
  //      max-width: 1000px;
  //    }
  //
  //    &-Items {
  //      display: grid;
  //      grid-template-columns: repeat(3, 1fr);
  //      grid-gap: 10px;
  //
  //      @include desktop {
  //        grid-template-columns: repeat(4, 1fr);
  //      }
  //    }
  //
  //    &-Item {
  //      perspective: 1280px;
  //      position: relative;
  //      cursor: pointer;
  //
  //      &:hover {
  //        .AdventCalendar-Doorframe {
  //          transform: rotateY(-42deg);
  //        }
  //      }
  //    }
  //
  //    &-Holder {
  //      padding-bottom: 100%;
  //    }
  //
  //    &-Preview {
  //      background-position: 50%;
  //      background-size: cover;
  //      border: 1px solid #FFFFFF00;
  //      border-radius: 20px;
  //      box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.5);
  //      height: 100%;
  //      left: 0;
  //      overflow: hidden;
  //      position: absolute;
  //      top: 0;
  //      width: 100%;
  //
  //      &:before {
  //        background-color: none;
  //        content: "";
  //        height: 100%;
  //        left: 0;
  //        opacity: .8;
  //        position: absolute;
  //        top: 0;
  //        width: 100%;
  //        z-index: -1;
  //      }
  //    }
  //
  //    &-Doorframe {
  //      align-items: center;
  //      background-size: var(--bg-size);
  //      border: 1px solid #FFFFFF00;
  //      border-radius: 20px;
  //      box-shadow: 10px 3px 10px rgba(0, 0, 0, 0.5);
  //      display: flex;
  //      height: 100%;
  //      justify-content: center;
  //      left: 0;
  //      overflow: hidden;
  //      pointer-events: none;
  //      position: absolute;
  //      top: 0;
  //      transform: rotateY(-20deg);
  //      transform-origin: 0 0;
  //      transition: transform .5s,box-shadow .5s;
  //      width: 100%;
  //    }
  //
  //    &-Coloroverlay {
  //      background-color: rgba(198, 211, 214, 0.13);
  //      bottom: 0;
  //      left: 0;
  //      position: absolute;
  //      right: 0;
  //      top: 0;
  //      transition: all 1.5s ease-in-out;
  //      transition-delay: .5s;
  //      display: flex;
  //      align-items: center;
  //      justify-content: center;
  //      color: white;
  //      font-family: $font-BlackerProDisplay;
  //
  //      @include desktop {
  //        font-size: 24px;
  //      }
  //    }
  //  }
  //}

  //&-Layout1 {
  //  .AdventCalendar {
  //    &-Doorframe {
  //      background-image: var(--advent-bg);
  //    }
  //  }
  //}

  &-Popup {
    background: rgba(0,0,0,0.2);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 101;
    overflow-y: auto;

    &Container {
      background: $white;
      margin: 40px auto;
      max-width: 800px;
      padding: 20px;
      width: 96%;

      @include wide-desktop {
        max-width: 1200px;
      }

      p,
      div {
        @include desktop {
          font-size: 16px;
        }
      }
    }

    &Title {
      font-family: $font-BlackerProDisplay;
      margin: 0 0 24px;

      @include desktop {
        font-size: 24px;
      }
    }
  }

  &-bkg {
    width: 100%;
  }

  &-Close {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 40px auto 0;
    max-width: 800px;
    width: 96%;
    z-index: 2;

    @include wide-desktop {
      max-width: 1200px;
    }

    img {
      background: $black;
      position: absolute;
      top: 0;
      right: -30px;
      width: 30px;
      height: 30px;
      padding: 4px;
      filter: invert(1);

      @include mobile {
        top: -31px;
        right: 2px;
      }
    }
  }

  &-Layout2 {
    + .AdventCalendar-Popup {
      .AdventCalendar {
        &-PopupContainer {
          background: $black;

          * {
            color: $white;
          }

          .beauty-cms_block-2_content * {
            color: $black;
          }

          .beauty-cms_block-18_label {
            &.light {
              color: $black;
            }
          }

          .CategoryPage-PromoListing__title,
          .CategoryPage-PromoListing__text {
            color: initial;

            a {
              color: initial;
            }
          }

          .CategoryPage-PromoListing__link a {
            &:hover {
              color: $black;
            }
          }

          .CmsNewsletter{
            &.vertical {
              .CmsNewsletter-title {
                span {
                  background: $black;
                }

                &:before {
                  border-bottom-color: $white;
                }
              }
            }

            .Button:hover {
              color: $black;
            }

            .FieldForm-Fieldset {
              .checkradio__box {
                border-color: $white;
              }
            }
          }

          .ProductCard {
            * {
              color: initial;
            }

            .ProductPrice_foucsiaPrice,
            .ProductPrice_offerDiscount {
              span{
                color: $foux;
              }
            }

            .AddToCart.Button:not([disabled]):hover {
              color: $white;
            }
          }
        }

        &-Close {
          img {
            filter: invert(0);
          }
        }
      }
    }
  }
}

.snow {
  animation: fall ease-in infinite, sway ease-in-out infinite, animName linear infinite;
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
}

.pyro {
  &-before,
  &-after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
    -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  }

  &-after {
    -moz-animation-delay: 1.25s, 1.25s, 1.25s;
    -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
    -o-animation-delay: 1.25s, 1.25s, 1.25s;
    -ms-animation-delay: 1.25s, 1.25s, 1.25s;
    animation-delay: 1.25s, 1.25s, 1.25s;
    -moz-animation-duration: 1.25s, 1.25s, 6.25s;
    -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
    -o-animation-duration: 1.25s, 1.25s, 6.25s;
    -ms-animation-duration: 1.25s, 1.25s, 6.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
  }
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; }
}
@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; }
}
@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; }
}
@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; }
}
@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; }
}
@keyframes fall {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 100vh;
    opacity: 1;
  }
}
@keyframes sway {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 50px;
  }
  50% {
    margin-left: -50px;
  }
  75% {
    margin-left: 50px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes animName {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
