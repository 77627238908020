/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --expandable-content-color: var(--color-karaka);
  --expandable-content-divider-color: var(--primary-divider-color);
  --toggle-button-size: 18px;
}

@mixin expanded-content {
  opacity: 1;
  max-height: 100%;
}

.ExpandableHorizontal {
  @include desktop {
    border-block-start: 1px solid var(--expandable-content-divider-color);

    &:last-of-type {
      border-block-end: 1px solid var(--expandable-content-divider-color);
    }

    .AddIcon,
    .MinusIcon,
    .ChevronIcon {
      width: var(--toggle-button-size);
      height: var(--toggle-button-size);
    }

    flex: 1;
    transition: flex 1s cubic-bezier(.29,0,.2,1);
  }

  //&:not(.ExpandableHorizontal_isContentExpanded):first-child {
  //  @include desktop {
  //    flex: 5;
  //  }
  //}

  &_isContentExpanded {
    @include desktop {
      flex: 5;
    }
  }

  &-Button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include desktop {
      padding-block: 14px;
    }
  }

  &-Heading,
  &-SubHeading {
    display: block;
    color: var(--expandable-content-color);
  }

  &-Heading {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    word-break: break-all;

    @include desktop {
      font-size: 14px;
    }

    @include mobile {
      cursor: auto;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &-SubHeading {
    font-weight: 400;
    font-size: 10px;
    line-height: 1.1;

    @include desktop {
      font-size: 12px;
    }
  }

  &-Content {
    // Disabled as it prevents z-index use inside
    // will-change: max-height, padding, opacity;
    transition-property: opacity;
    transition-duration: 200ms;
    height: 100%;

    @include mobile {
      margin-block-start: 12px;
    }

    &_isContentExpanded {
      @include desktop {
        flex: 5;
      }
    }
  }

  @include desktop {
    &_isWithoutBorder {
      border: 0;

      &:last-of-type {
        border: 0;
      }
    }
  }

  &_wrapper {
    @include desktop {
      display: flex;
    }
  }
}
