/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';

:root {
  --blog-post-card-title-weight: 600;
  --blog-post-card-title-size: 18px;
  --blog-post-card-description-height: 100px;
  --blog-post-card-border: 1px solid lightgray;
}

.BlogRelatedPostCard {
  @include desktop {
    flex-basis: 50%;
  }

  &-ImageWrapper {
    display: block;

    @include mobile {
      margin: 0 -14px;
    }

    .Image {
      height: auto;
      padding-bottom: 0;

      img {
        position: static;
      }
    }
  }

  &-Image {
    &.Image {
      @include mobile {
        padding-bottom: 0;
        height: auto;
        vertical-align: top;

        img {
          position: static;
          vertical-align: top;
        }
      }
    }
  }

  &-Details {
    padding: 12px 24px;
    text-align: center;
    margin-top: -71px;
    background: $white;

    @include desktop {
      margin: -72px 45px 0;
      padding: 22px 11% 40px;
    }

    @include ultra-narrow-desktop {
      padding: 22px 5% 40px;
    }
  }

  &-Title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    padding: 0 0 4px;

    @include desktop {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.003em;
      padding-bottom: 8px;
    }

    a {
      font-size: 16px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &-DescriptionWrapper {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;

    @include desktop {
      margin-bottom: 7px;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  &-Link {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
  }
}