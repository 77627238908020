/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FabIcons {
  position: fixed;
  right: 20px;
  bottom: 187px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 100;

  @include desktop {
    right: 64px;
    bottom: 139px;
  }

  &-PopupButton {
    border-radius: 50%;
    overflow: hidden;
    width: 48px;
    height: 48px;
    border: 1px solid $black;
    box-sizing: border-box;
    background: $black;
    box-shadow: 0 0 10px 0 $grey;
    text-align: center;
    z-index: 100;
    cursor: pointer;
    transition: opacity $ease-main, transform $ease-main;
    display: block;

    &:hover {
      @include desktop {
        opacity: .5;
      }
    }

    svg, img {
      width: 24px;
      height: 24px;
      @include center-image;
      fill: $white;
    }

    &Chat {
      border-radius: 999rem;
      overflow: hidden;
      border: 1px solid $black;
      box-sizing: border-box;
      background: $black;
      box-shadow: 0 0 10px 0 $grey;
      text-align: center;
      z-index: 100;
      cursor: pointer;
      transition: opacity $ease-main, transform $ease-main;
      display: block;
      padding: 1.3rem;
      position: fixed;
      bottom: 71px;
      right: 20px;
      cursor: pointer;

      @include desktop {
        bottom: 13px;
        padding: 1.2rem 2.1rem;
      }

      button {
        cursor: pointer;
        color: $lightpink;
        display: flex;
        letter-spacing: 0.6px;
        font-size: 15px;
        font-weight: 600;
        font-family: system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Arial,sans-serif;

        span {
          @include mobile {
            display: none;
          }
        }


        .Icon--chat {
          padding-right: 0.799rem;
          flex-shrink: 0;

          @include mobile {
            display: block;
            padding-right: 0;
          }

          svg {
            color: $lightpink;
            fill: $lightpink;
            display: block;
            min-width: 2rem;
            min-height: 2rem;
            height: 2rem;
            width: 2rem;
          }
        }
      }
    }
  }

  &-Item {
    margin-bottom: 8px;
    display: flex;

    &:last-child {
      margin-bottom: 16px;
    }
  }

  &-Link {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.0008em;
    color: $white !important;
    background: $black;
    display: block;
    padding: 12px 16px;
    text-decoration: none !important;
    width: max-content;
    display: flex;
    align-items: center;

    + .FabIcons-Link {
      margin-left: 10px;
    }

    svg {
      margin-right: 12px;
      display: inline-block;
      vertical-align: middle;
    }

    strong {
      font-weight: bold;

      + span {
        margin-top: 4px;
        display: block;
      }
    }

    span {
      font-size: 12px;
      line-height: 12px;
    }

    > span {
      display: block;
    }
  }

  &-PopupContent {
    max-height: 0;
    transition: max-height $ease-main;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 0;

    &.active {
      max-height: 1000px;
      max-width: 100%;
    }
  }
}