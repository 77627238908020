/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.TrackingForm {
    max-width: 482px;
    margin: 0 auto;
    textarea {
        width: 100%;
    }
    form {
        @include mobile {
            text-align: center;
            label {
                text-align: left;
            }
        }
    }

    .Field {
        margin-top: 16px;
    }

    .FieldForm {
        &-Fields {
            margin-bottom: 20px;
        }

        .Button {
            width: 100%;
        }
    }
}
