/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ForgotPassword {
    .ContentWrapper {
        padding-block-start: 20px;
        padding-block-end: 50px;
        padding-inline: 20px;

        @include desktop() {
            min-height: calc(100vh - var(--header-total-height));
        }
    }

    &-InnerWrapper {
        @include desktop() {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            grid-template-columns: 1fr 1fr;
            grid-gap: 100px;
        };

        .MyAccountOverlay {
            &-Additional {
                &:not(.ForgotPassword-Additionalmobile) {
                    display: none;
                }
            }
        }
    }

    &-SignInWrapper {
        margin-block-start: 100px;
    }

    .Loader {
        margin: 0;
    }

    input {
        width: 100%;

        &[type='checkbox'] {
            width: auto;
        }
    }

    .MyAccountOverlay-Info {
        margin-top: 0;
    }
}
