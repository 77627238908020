/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --category-page-miscellaneous-height: 0px;
}

.BrandPage {
    --category-page-miscellaneous-height: 49px;

    &.isChanel {
        font-family: $font-arial;
    }

    &_noResults {
        --category-page-miscellaneous-height: 0;
    }

    &-ContentSelectors {
        display: flex;
        margin-bottom: 24px;
        align-items: flex-start;
        gap: 32px;
        flex-wrap: wrap;

        @include desktop {
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        @include small-view-desktop {
            // flex-direction: column;
            gap: unset;
        }
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            padding-block-start: 24px;
            grid-column-gap: 48px;
        }

        grid-template-columns: 236px auto;

        @include wide-desktop {
            grid-column-gap: 53px;
            grid-template-columns: 317px auto;
        }

        @include mobile {
            padding: 0;
        }

        &_hideProducts {
            display: block;

            .BrandPage-CMS {
                @include mobile {
                    margin-block-start: 0;
                }
            }
        }
    }

    &__slider_image {
        overflow: hidden;

        @include desktop {
            background: $white;
            margin-bottom: 12px;
        }

        img {
            width: auto;
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
    }

    &__main_image {
        margin-bottom: 13px;

        @include desktop {
            margin-bottom: 26px;
        }

        img {
            vertical-align: top;
        }
    }

    &__main_description {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0 16px 26px;

        @include desktop {
            margin: 0 0 26px;
            padding: 0 10px;
        }

        p {
            font-size: 14px;
            line-height: 22px;
        }
    }

    &-LeftCol {
        @include desktop {
            position: relative;
            grid-row: 1/6;
            width: 100%;
            opacity: 1;
            pointer-events: all;
            display: flex;
            flex-direction: column;
            height: auto;
            overflow: visible;
            margin-top: 20px;
        }
    }

    &-LayoutSlider {
        width: 400px;
        margin: 0;

        @include mobile {
            width: 50% !important;
        }

        &Button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 4px;
            font-size: 12px;
            height: var(--category-page-miscellaneous-height);
            border-right: 1px solid var(--primary-divider-color);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &_label {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
            margin-bottom: 9px;
        }
    }

    &-LayoutContentWrapper {
        .FieldSelect-OptionsWrapper {
            .BrandPage-LayoutSliderWrapper {
                width: 100%;
                inset-block-start: 100%;
                inset-inline-start: 0;
                z-index: 40;
                border-width: 0 0 1px;
                border-style: solid;
                border-color: transparent;
                transition: 200ms max-height;
                will-change: max-height;
                max-height: 0;
                -webkit-overflow-scrolling: touch;
                min-width: 100%;
                overflow: hidden;
                opacity: 0;
            }

            &.active {
                background: rgba(0, 0, 0, .4);
                position: fixed;
                top: 0;
                bottom: 48px;
                left: 0;
                right: 0;
                z-index: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .FieldSelect-FieldLabelMobile {
                    background-color: #fff;
                    max-height: 100%;
                    padding: 16px 16px 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:after {
                        content: "";
                        position: absolute;
                        left: 16px;
                        right: 16px;
                        height: 1px;
                        bottom: 12px;
                        border-bottom: 1px solid #d9d9d9;
                        display: block;
                    }
                }

                .BrandPage-LayoutSliderWrapper {
                    z-index: 5;
                    max-height: 74px;
                    border-color: #0a0a0a;
                    border-color: var(--input-border-color);
                    background-color: #{$white};
                    top: auto;
                    bottom: 0;
                    padding: 13px 16px 37px;
                    opacity: 1;
                }
            }
        }
    }

    &-ProductListWrapper {
        @include mobile {
            padding-block-start: 16px;
        }

        &_isPrerendered {
            @include mobile {
                padding-block-start: 120px;
            }
        }
    }

    &-ItemsCount {
        padding: 0;

        @include mobile {
            order: 1;
            padding-block: 4px 12px;
            text-align: center;
            grid-column: span 2;
            padding-inline: 14px;
        }

        @include desktop {
            margin-block-end: 0;
            white-space: nowrap;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        text-align: center;
        margin: 0;

        @include desktop {
            background: $white;
            margin-bottom: 32px;
        }

        .BrandPage__slider_image img+& {
            opacity: 0;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
        }
    }

    &-Filter {
        text-align: end;
        grid-column: 2;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 4px;
        width: 33.3333%;
        height: var(--category-page-miscellaneous-height);
        border-left: 1px solid var(--primary-divider-color);

        @include desktop {
            display: none;

            &Placeholder {
                position: relative;
                grid-row: 1/6;
                width: 100%;
                opacity: 1;
                pointer-events: all;
                display: flex;
                flex-direction: column;
                height: auto;
                overflow: visible;
            }
        }
    }

    &-Subheading {
        font-weight: 600;
        font-size: 14px;
        white-space: pre;
    }

    &-Miscellaneous {
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

        @include desktop {
            padding-top: 14px;
            padding-block-end: 16px;
            padding-inline: 0;
            margin-left: auto;
            flex: 0 1 35%;
        }

        @include small-view-desktop {
            width: 100%;
        }

        @include mobile {
            position: fixed;
            inset-block-end: 0;
            inset-inline-start: 0;
            width: 100%;
            z-index: 100;
            background: #fff;
            border: 1px solid var(--primary-divider-color);
            grid-template-columns: repeat(2, 1fr);
            height: var(--category-page-miscellaneous-height);

            &_noResults {
                height: auto;
            }
        }


        @include tablet {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }

        @include tablet-portrait {
            grid-template-rows: 1fr 1fr;
        }

        .CategorySort {
            @include mobile {
                width: 50%;
                height: var(--category-page-miscellaneous-height);

                .Field-LabelContainer {
                    display: none;
                }

                .FieldSelect-Clickable {
                    height: var(--category-page-miscellaneous-height);
                    flex-direction: column;
                    justify-content: center;
                    flex-direction: column;
                    grid-gap: 4px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                }

                .FieldSelect-OptionsWrapper_isExpanded {
                    bottom: 49px;
                }
            }
        }
    }

    &-CMS {
        @include mobile {
            margin-block-start: calc(var(--header-total-height) + var(--category-page-miscellaneous-height));
            padding-inline: 10px;
            padding-block-start: 20px;
            overflow-x: auto;
        }

        @include desktop {
            margin-block-end: 10px;
        }
    }

    &-LayoutWrapper {
        align-items: center;
        display: flex;
        width: 100%;
        gap: 32px;

        @include desktop {
            justify-content: flex-end;
        }

        @include small-view-desktop {
            justify-content: space-between;
        }

        @include mobile {
            width: 66.6667%;
            gap: 0;
        }

        p {
            margin-block-end: 0;
        }

        &_isPrerendered {
            @include mobile {
                display: flex;
                position: absolute;
                inset-block-end: -50px;
                inset-inline-start: 0;
                inset-inline-end: 0;
            }
        }
    }

    &-LayoutContentWrapper {
        @include mobile {
            width: 50%;
            height: var(--category-page-miscellaneous-height);
        }
    }

    &-LayoutButtons {
        display: flex;
        align-items: center;

        @include mobile {
            display: none;
        }

        button {
            color: transparent;
            margin-inline-end: 10px;
        }
    }
}