/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-post-card-title-weight: 700;
    --blog-post-card-title-size: 16px;
    --blog-post-card-description-height: 100px;
    --blog-post-card-border: 1px solid lightgray;
}

.BlogPostCard {
    font-size: 12px;
    text-align: left;

    &_isLoading {
        padding: 80% 0 0;
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        box-shadow: var(--attraction-shadow);
    }

    &_isCompactOnMobile {
        @include mobile {
            display: flex;
            padding: 10px 0;
            border-top: var(--blog-post-card-border);

            &:last-child {
                border-bottom: var(--blog-post-card-border);
            }
        }

        &-Title {
            @include mobile {
                padding-top: 0;
            }
        }

        &-DescriptionWrapper {
            @include mobile {
                display: none;
            }
        }

        &-Date {
            @include mobile {
                position: absolute;
                bottom: 0;
                display: block;
            }
        }

        &-ContentWrapper {
            @include mobile {
                display: flex;
                width: 100%;
            }
        }

        &-Details {
            @include mobile {
                display: block;
                flex-basis: 60%;
                padding-left: 10px;
            }
        }
    }

    &-Title {
        font-size: var(--blog-post-card-title-size);
        font-weight: var(--blog-post-card-title-weight);
        padding: 20px 0;
    }

    &-DescriptionWrapper {
        overflow: hidden;
        max-height: var(--blog-post-card-description-height);
        text-overflow: ellipsis;
    }

    &-Date {
        display: none;
    }

    &-Image {
        height: 100%;
        padding-bottom: 0;

        > img {
            position: relative;
            object-fit: cover;
        }

        .CategoryListing-Grid & {
            padding-bottom: 100%;
            height: 0;

            > img {
                position: absolute;
            }
        }
    }

    &-ImageWrapper {
        flex-basis: 40%;
    }
}
