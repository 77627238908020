/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$select-width: 160px !default;
$select-height: 45px !default;
$select-option-height: 40px !default;
$select-arrow-height: 14px !default;
$select-arrow-width: 14px !default;

.FieldSelect {
    cursor: pointer;

    &-Clickable {
        display: flex;
        align-items: center;
    }

    .ChevronIcon {
        display: inline-block;
        width: $select-arrow-width;
        height: $select-arrow-height;
        pointer-events: none;

        position: absolute;
        inset-inline-end: 12px;
    }

    &_isExpanded::after {
        transform: rotate(-90deg);
    }

    &-Select {
        min-width: 100%;
        padding-inline-end: 40px;
        width: 100%;
        pointer-events: none;

        &_isExpanded {
            border-color: var(--input-border-color-focus);
            opacity: 1;
        }

        .productColor .FieldSelect-SelectStatic + & {
            color: $white;
        }

        &Static {
            display: flex;
            align-items: center;
            gap: 8px;
            position: absolute;
            left: 16px;
            top: 14px;
            z-index: 1;

            > img,
            > div {
                width: 16px;
                height: 16px;
                flex-shrink: 0;
            }
        }
    }

    &-Options {
        position: absolute;
        width: 100%;
        inset-block-start: 100%;
        inset-inline-start: 0;
        z-index: 40;
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: transparent;

        overflow-y: auto;
        transition: 200ms max-height;
        will-change: max-height;
        max-height: 0;
        -webkit-overflow-scrolling: touch;
        min-width: 100%;

        // Firefox support
        //scrollbar-width: none !important;
        //scrollbar-color: none !important;

        //&::-webkit-scrollbar {
        //    display: none;
        //}
        //
        //&::-webkit-scrollbar-thumb {
        //    display: none;
        //}

        @include mobile {
            position: static;
            border-width: 0;
        }

        &Wrapper {
            position: static;

            &_isExpanded {
                @include mobile {
                    background: rgba(0,0,0,0.4);
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 102;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }
            }
        }

        &_isExpanded {
            z-index: 5;
            max-height: 185px;
            border-color: #0a0a0a;
            border-color: var(--input-border-color);
            overflow-y: auto;
            background-color: #{$white};

            @include mobile {
                max-height: 200px;
                top: auto;
                bottom: 0;
            }
        }
    }

    &-Option {
        padding: 0 16px;
        margin: 0;
        z-index: 1;
        transition: 100ms max-height ease-in;
        will-change: max-height;
        //max-height: $select-option-height;
        min-height: $select-option-height;
        background: var(--color-white);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        &::before {
            content: none;
        }

        &:last-of-type {
            margin: 0;
        }

        &:hover,
        &:focus {
            background: var(--secondary-base-color);
        }

        &:after {
            .ProductConfigurableAttributes & {
                content: "";
                width: 8px;
                height: 4px;
                border-style: solid;
                border-width: 1px;
                border-color: transparent transparent transparent transparent;
                display: block;
                transform: rotate(-45deg);
            }
        }

        &_isSelceted {
            &:after {
                content: "";
                width: 8px;
                height: 4px;
                border-style: solid;
                border-width: 1px;
                border-color: transparent transparent $black $black;
                display: block;
                transform: rotate(-45deg);

                .ProductConfigurableAttributes & {
                    border-color: transparent transparent $black $black;
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            gap: 8px;

            &:first-child {
                .ProductConfigurableAttributes & {
                    margin-right: auto;
                }
            }

            > img,
            > div {
                width: 16px;
                height: 16px;
                flex-shrink: 0;
            }
        }

        .ProductConfigurableAttributes & {
            justify-content: flex-end;

            &.FieldSelect-Option_isDisabled {
                opacity: 0.3;
            }
        }
    }

    &-FieldLabel {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
    }

    &-FieldLabelMobile {
        max-height: 0;
        overflow: hidden;

        @include desktop {
            display: none !important;
        }

        &_isExpanded {
            background-color: #{$white};
            max-height: 100%;
            padding: 16px 16px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:after {
                content: "";
                position: absolute;
                left: 16px;
                right: 16px;
                height: 1px;
                bottom: 12px;
                border-bottom: 1px solid $grey;
                display: block;
            }
        }
    }
}
