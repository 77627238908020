/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.PromoPopup {
    &-cols {
        @include mobile {
            position: static;
        }

        @include desktop {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    &-col {
        @include mobile {
            position: static;
        }

        @include desktop {
            flex: 1;
        }
    }

    &_title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin-bottom: 16px;
    }

    &_subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin-bottom: 26px;
    }

    &_description {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
    }

    &-content {
        padding: 161px 16px 28px;

        @include desktop {
            padding: 46px 72px;
        }
    }

    &_Images {
        height: 100%;

        @include mobile {
            position: static;
        }

        img {
            vertical-align: top;
            object-fit: cover;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            font-family: 'object-fit: cover;';
            width: 0;
            min-width: 100%;
            min-height: 100%;

        }
    }

    .CmsBlock-Wrapper,
    .RenderWhenVisible {
        height: 100%;

        @include mobile {
            position: static;
        }
    }

    .Field {
        margin-top: 16px;
    }

    .FieldForm-Fields {
        margin-bottom: 16px;
    }

    .Button {
        background-color: $black;
        color: $white;
        width: 100%;

        &:not([disabled]):hover {
            background-color: $white;
            color: $black;
        }
    }

    .Popup {
        &-Content {
            width: auto;
            max-width: 982px;
            padding: 0;
            border-radius: 0;

            @include mobile {
                width: 100%;
                min-width: 0;
                max-width: 360px;
                max-height: 100%;
                overflow-y: auto;
            }
        }

        &-Heading {
            display: none;
        }

        &-Header {
            position: static;
            display: block !important;
            padding: 0;
        }

        &-CloseBtn {
            position: absolute;
            z-index: 5;

            @include mobile {
                top: 10px !important;
                right: 10px !important;
            }

            @include desktop {
                top: 18px !important;
                right: 18px !important;
            }

            .CloseIcon {
                width: 24px !important;
                height: 24px !important;
                vertical-align: top;

                @include desktop {
                    margin-inline-end: 7px;
                    margin-block-start: 5px;
                    fill: $white;
                }
            }
        }
    }

    &.Popup_isVisible {
        @include mobile {
            top: 0;
            height: 100vh;
            align-items: center;
        }
    }

    .contact-form {
        padding: 0;

        .inputbox-row-top {
            @include desktop {
                flex-direction: column;
                gap: 0;

                >.inputbox {
                    width: 100%;
                }
            }
        }

        .inputbox-row-bottom {
            @include desktop {
                flex-direction: column;
                gap: 0;

                >.inputbox-row {
                    width: 100%;
                }

                >.inputbox {
                    width: 100%;
                }
            }
        }

        .inputbox .Field {
            margin-top: 16px;
        }

        .inputbox-row-right {
            display: block;
        }
    }
}